import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Document } from '../models/document.model';
import { Folder } from '../models/folder.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private _apiRoutePath: string = "/documents";
  private _selectionSubject: Subject<boolean> = new Subject();
  private _selection: any[] = [];

  constructor(private apiService: ApiService) { }

  getSelectionElements(): any[] {
    return this._selection;
  }

  hasSelection(): boolean {
    return this._selection.length > 0;
  }

  hasSelectionObservable(): Observable<boolean> {
    return this._selectionSubject.asObservable();
  }

  addSelectionElement(element: any): void {
    if (this._selection.findIndex(item => item.constructor == element.constructor && item.getId() == element.getId()) < 0) {
      this._selection.push(element);
    }

    this._selectionSubject.next(this.hasSelection());
  }

  removeSelectionElement(element: any): void {
    let index = this._selection.findIndex(item => item.constructor == element.constructor && item.getId() == element.getId());

    if (index > -1) {
      this._selection.splice(index, 1);
    }

    this._selectionSubject.next(this.hasSelection());
  }

  isInSelection(element: any): boolean {
    return this._selection.findIndex(item => item.constructor == element.constructor && item.getId() == element.getId()) > -1;
  }

  emptySelection(): void {
    this._selection = [];
    this._selectionSubject.next(false);
  }

  getNbSelection(): number {
    return this._selection.length;
  }

  getAllDocuments(page: number = 1, params: any = {}): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Accept': 'application/ld+json'
      }),
      params: {
        page: page
      }
    };

    options.params = Object.assign(options.params, params);

    return this.apiService.get(this._apiRoutePath, options).pipe(
      map(res => {
        return {
          totalItems: res['hydra:totalItems'],
          items: res['hydra:member'].map(item => new Document().deserialize(item))
        };
      })
    );
  }

  createDocument(data: any): Observable<Document> {   
    return this.apiService.post(this._apiRoutePath, data).pipe(
      map(res => new Document().deserialize(res))
    );
  }

  updateDocument(id: string, data: any): Observable<Document> {
    return this.apiService.put(this._apiRoutePath + '/' + id, data).pipe(
      map(res => new Document().deserialize(res))
    );
  }

  getDocument(id: string): Observable<Document> {
    return this.apiService.get(this._apiRoutePath + '/' + id).pipe(
      map(res => new Document().deserialize(res))
    );
  }

  deleteDocument(id: string): Observable<any> {
    return this.apiService.delete(this._apiRoutePath + '/' + id);
  }

  downloadDocument(id: string, params: any = {}): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Accept': '*/*'
      }),
      responseType: 'blob',
      params: {}
    };

    options.params = Object.assign(options.params, params);

    return this.apiService.get(this._apiRoutePath + '/' + id + '/download', options);
  }

  shareDocuments(data: any): Observable<any> {   
    return this.apiService.post(this._apiRoutePath + '/share', data);
  }
}
