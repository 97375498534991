import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecretAnswer } from '../models/secret-answer.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SecretAnswerService {

  private _apiRoutePath: string = "/secret-answers";

  constructor(private apiService: ApiService) { }

  getAllSecretAnswer(): Observable<SecretAnswer[]> {
    return this.apiService.get(this._apiRoutePath).pipe(
      map(res => {
        return res.map(item => new SecretAnswer().deserialize(item))
      })
    );
  }

  updateSecretAnswer(id: string, data: any): Observable<SecretAnswer> {
    return this.apiService.put(this._apiRoutePath + '/' + id, data).pipe(
      map(res => new SecretAnswer().deserialize(res))
    );
  }
}
