import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private _loading: boolean = false;

  constructor() { }

  isLoading(): boolean {
    return this._loading;
  }

  showLoading(): void {
    this._loading = true;
  }

  dismissLoading(): void {
    this._loading = false;
  }
}
