<div class="container">
    <div class="row">
       <div class="col-12">
            <app-header-logo></app-header-logo>
       </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h1 class="mb-5">Inscription</h1>
            <ng-container [ngSwitch]="currentStep">                
                <app-step2 [userFormHandler]="userFormHandler" *ngSwitchCase="2" (submittedStepForm)="onSubmittedStepForm($event)" (previousStep)="currentStep = currentStep - 1"></app-step2>
                <app-step1 [userFormHandler]="userFormHandler" *ngSwitchDefault (submittedStepForm)="onSubmittedStepForm($event)"></app-step1>
            </ng-container>                                  
        </div>
    </div>
    <div class="row mt-12">
        <div class="col-12 text-center">
            <app-footer-legal-notice></app-footer-legal-notice>
        </div>
    </div>
</div>