import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotesSummaryRoutingModule } from './notes-summary-routing.module';
import { MyNotesSummaryComponent } from './my-notes-summary/my-notes-summary.component';
import { SharedModule } from '../shared/shared.module';
import { SharingModalComponent } from './sharing-modal/sharing-modal.component';


@NgModule({
  declarations: [MyNotesSummaryComponent, SharingModalComponent],
  imports: [
    CommonModule,
    NotesSummaryRoutingModule,
    SharedModule
  ]
})
export class NotesSummaryModule { }
