import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-legal-notice',
  templateUrl: './footer-legal-notice.component.html',
  styleUrls: ['./footer-legal-notice.component.scss']
})
export class FooterLegalNoticeComponent implements OnInit {

  @Input() public config: any = {
    itemClass: ''
  };

  constructor() { }

  ngOnInit(): void {
  }

}
