import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { Document } from 'src/app/core/models/document.model';

@Component({
  selector: 'app-document-sharing-thumbnail',
  templateUrl: './document-sharing-thumbnail.component.html',
  styleUrls: ['./document-sharing-thumbnail.component.scss']
})
@AutoUnsub()
export class DocumentSharingThumbnailComponent implements OnInit {

  @Input() public document: Document;
  @Output() public onDeleteDocument: EventEmitter<any> = new EventEmitter();
  private _observers: Subscription = new Subscription();

  constructor() { }

  ngOnInit(): void {
  }

}
