import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Partner } from 'src/app/core/models/partner.model';

@Component({
  selector: 'app-partner-informations-modal',
  templateUrl: './partner-informations-modal.component.html',
  styleUrls: ['./partner-informations-modal.component.scss']
})
export class PartnerInformationsModalComponent implements OnInit {

  public partner: Partner = new Partner();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }



}
