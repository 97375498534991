import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MakingAppointmentRoutingModule } from './making-appointment-routing.module';
import { MakingAppointmentProcessComponent } from './making-appointment-process/making-appointment-process.component';
import { PartnersListingComponent } from './partners-listing/partners-listing.component';
import { AgenciesListingComponent } from './agencies-listing/agencies-listing.component';
import { SharedModule } from '../shared/shared.module';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { AvailabilitiesListingComponent } from './availabilities-listing/availabilities-listing.component';
import { AppointmentSlotsListingComponent } from './appointment-slots-listing/appointment-slots-listing.component';
import { MakingAppointmentConfirmationComponent } from './making-appointment-confirmation/making-appointment-confirmation.component';


@NgModule({
  declarations: [MakingAppointmentProcessComponent, PartnersListingComponent, AgenciesListingComponent, SearchModalComponent, AvailabilitiesListingComponent, AppointmentSlotsListingComponent, MakingAppointmentConfirmationComponent],
  imports: [
    CommonModule,
    MakingAppointmentRoutingModule,
    SharedModule
  ],
  exports: [
    SearchModalComponent
  ]
})
export class MakingAppointmentModule { }
