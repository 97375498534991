import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDiff'
})
export class MomentDiffPipe implements PipeTransform {

  transform(value: Date, valueDiff: Date, unit: string = 'days'): number {
    if (valueDiff == null) {
      valueDiff = new Date();
    }

    const duration = moment.duration(moment(value).diff(moment(valueDiff)));

    let res = duration.days();
    switch (unit) {
      case 'years':
        res = duration.years();
        break;
      case 'minutes':
        res = duration.minutes();
        break;
      case 'months':
        res = duration.months();
        break;
      case 'hours':
        res = duration.hours();
        break;
      default:
        break;
    }

    return res;
  }

}
