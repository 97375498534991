import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent implements OnInit {

  public focusSearchElement: boolean = false;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
