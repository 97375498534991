<div class="document-sharing-thumbnail">
    <div class="d-flex align-items-center py-2">        
        <ng-template [ngTemplateOutlet]="icon"></ng-template>  
        <h4 class="px-3 mb-0 text-left w-100 overflow-hidden flex-fill">
            <span *ngIf="document.title.length > 18; else documentTitle">{{ document.title|slice:0:18 }}...</span>
            <ng-template #documentTitle><span>{{ document.title }}</span></ng-template>
        </h4>  
        <span class="fs-1 flex-shrink-0">({{ document.file.size/1000000| number:'1.2-2':'fr' }} MO)</span>          
    </div>
</div>

<ng-template #icon>
    <div class="document-sharing-thumbnail__icon d-flex align-items-center justify-content-center flex-shrink-0" [ngClass]="{ 'bg-transparent': document.file.getType() == 'image' }">
        <ng-container [ngSwitch]="document.file.getType()">
            <img *ngSwitchCase="'image'" [ngClass]="{ 'cover-portrait': document.file.dimensions[0] > document.file.dimensions[1], 'cover-landscape': document.file.dimensions[0] <= document.file.dimensions[1] }" [src]="document.getAbsoluteFilePath()" />
            <i *ngSwitchCase="'spreadsheet'" class="mlp-font mlp-file-spreadsheet fs-6"></i>
            <i *ngSwitchCase="'wordProcessor'" class="mlp-font mlp-file-text fs-6"></i>
            <i *ngSwitchCase="'presentationFile'" class="mlp-font mlp-file-presentation fs-6"></i>
            <i *ngSwitchCase="'video'" class="mlp-font mlp-file-video fs-6"></i>
            <i *ngSwitchCase="'audio'" class="mlp-font mlp-file-audio fs-6"></i>
            <i *ngSwitchCase="'archive'" class="mlp-font mlp-file-archive fs-6"></i>
            <i *ngSwitchDefault class="far fa-file-alt fs-4"></i>
        </ng-container>
    </div>      
</ng-template>