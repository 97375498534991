import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { AutoUnsub } from '../decorators/auto-unsub.decorator';
import { Page } from '../models/page.model';
import { LoadingService } from '../services/loading.service';
import { PageService } from '../services/page.service';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss']
})
@AutoUnsub()
export class StaticPageComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  public page: Page = new Page();

  constructor(private pageService: PageService, private loadingService: LoadingService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.setObservers();
  }

  setObservers(): void {
    this._observers.add(this.getRouteParams());
  }

  getRouteParams(): Subscription {
    return this.route.paramMap.subscribe(paramMap => {
      if (paramMap.has('id')) {
        this.getStaticPage(paramMap.get('id'));  
      }
    });
  }

  getStaticPage(pageId: string): void {
    this.loadingService.showLoading();
    const getPage = this.pageService.getPage(pageId).pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(res => {
      this.page = res;
    });

    this._observers.add(getPage);
  }

}
