import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private _menuClosure: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  openMenu() {
    this._menuClosure.next(true);
  }

  closeMenu() {
    this._menuClosure.next(false);
  }

  menuIsOpened(): Observable<boolean> {
    return this._menuClosure.asObservable();
  }
}
