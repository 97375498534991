import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxScrollEvent } from 'ngx-scroll-event';
import { of, Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { AppointmentType } from 'src/app/core/enums/appointment-type.enum';
import { Gender } from 'src/app/core/enums/gender.enum';
import { Appointment } from 'src/app/core/models/appointment.model';
import { Note } from 'src/app/core/models/note.model';
import { Sharing } from 'src/app/core/models/sharing.model';
import { WorkStation } from 'src/app/core/models/work-station.model';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NoteService } from 'src/app/core/services/note.service';
import { IRIUtil } from 'src/app/shared/utils/iri.util';
import { CancellationModalComponent } from '../cancellation-modal/cancellation-modal.component';
import { SharingModalComponent } from '../sharing-modal/sharing-modal.component';
import * as moment from 'moment';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
@AutoUnsub()
export class AppointmentComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  private _sharingsPage: number = 1;
  public appointment: Appointment;
  public note: Note;
  public topBarLeftAction: any = {};
  public topBarRightAction: any = {};
  public modalRef: BsModalRef;
  public gender = Gender;
  public appointmentType = AppointmentType;
  public appointmentObject = Appointment;
  public sharingsLoading: boolean = false;
  public sharings: Sharing[] = [];
  public workStations: WorkStation[] = [];

  constructor(private route: ActivatedRoute, private appointmentService: AppointmentService,
    private loadingService: LoadingService, private router: Router,
    private modalService: BsModalService, private noteService: NoteService,
    private iriUtil: IRIUtil) { }

  ngOnInit(): void {
    this.getAppointment();
  }

  getAppointment(): void {
    this.loadingService.showLoading();
    const appointmentId = this.route.snapshot.params.id;
    const getAppointment = this.appointmentService.getAppointment(appointmentId).pipe(
      switchMap(res => {
        this.appointment = res;    
        return this.appointment.note !== null ? this.noteService.getNote(this.iriUtil.getId(this.appointment.note)) : of(null);
      }),
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(res => {
      this.note = res;
      this.setTopBarLeftAction();
      this.setTopBarRightAction();
      this.loadSharings();
    });

    this._observers.add(getAppointment);
  }

  loadSharings(): void {
    if (this._sharingsPage && !this.sharingsLoading) {
      this.sharingsLoading = true;
      const getSharings: Subscription = this.appointmentService.getAllAppointmentSharings(this._sharingsPage, this.appointment.getId()).pipe(
        finalize(() => {
          this.sharingsLoading = false;
        })
      ).subscribe(res => {
          this.sharings = this.sharings.concat(res.items);
          this._sharingsPage = (res.totalItems > this.sharings.length) ? (this._sharingsPage + 1) : 0; 
          this.sharings.forEach(sharing => {
            this.workStations = this.workStations.concat(sharing.workStations.filter(workStation => !this.workStations.find(w => w.getId() == workStation.getId())));  
          });  
      });

      this._observers.add(getSharings);
    }
  }

  lazyLoadSharings(event: NgxScrollEvent): void {
    if (event.isReachingBottom) {
      this.loadSharings();
    }
  }

  setTopBarLeftAction(): void {
    this.topBarLeftAction = {
      id: 'return',
      data: '/rendez-vous'
    }
  }

  setTopBarRightAction(): void {
    this.topBarRightAction = {
      id: this.appointment.canBeCanceled() ? 'cancel' : 'none',
    };
  }

  openCancellationModal() {
    this.modalRef = this.modalService.show(CancellationModalComponent);
    this.modalRef.content.appointment = this.appointment;
    
    const modalOnHidden = this.modalRef.onHidden.subscribe((reason) => {
      this.setTopBarRightAction();
    });

    this._observers.add(modalOnHidden);
  }

  openSharingModal() {
    this.modalRef = this.modalService.show(SharingModalComponent);
    this.modalRef.content.canSelectAppointment = false;
    this.modalRef.content.setSharingAppointment(this.appointment);

    const sharedAppointmentWorkStations = this.modalRef.content.sharedAppointmentWorkStations.subscribe(res => {
      this.workStations = this.workStations.concat(res.filter(workStation => !this.workStations.find(w => w.getId() == workStation.getId())));  
    });

    this._observers.add(sharedAppointmentWorkStations);
  }

}
