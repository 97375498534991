<div class="appointment-slots-listing">
    <h1 class="mb-4">
        Choisissez votre rendez-vous
    </h1>
    <div class="appointment-slots-listing__filters-wrapper mb-6">
        <div id="filter-calendar" class="daterangepicker-wrapper filter-calendar font-weight-medium position-relative">
            <div class="row">
                <div class="col-3 font-weight-bold">
                    Période
                </div>
                <div class="col-9 text-right">
                    <button type="button" class="link" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen">
                        <i class="fas fa-pencil-alt mr-2"></i>
                        <span>Modifier la période</span>
                    </button>
                </div>
            </div>
            <input bsDaterangepicker type="hidden" #drp="bsDaterangepicker" [bsValue]="calendarDateRangeValue"
                [minDate]="minDate"
                [bsConfig]="{ adaptivePosition: true, isAnimated: true,  containerClass: 'theme-default' }"
                [container]="'#filter-calendar'" (bsValueChange)="changeCalendarDateRangeValue($event)">
            <div class="row fs-1">
                <div class="col-6">
                    Du
                </div>
                <div class="col-6">
                    Au
                </div>
            </div>
            <div class="row fs-2 no-gutters align-items-stretch">
                <div class="col-6 bg-grey-90 p-3 filter-calendar__start-date">
                    <div class="d-flex align-items-center">
                        <i class="far fa-calendar-alt fa-2x mr-3"></i>
                        <span>{{ calendarStartDate|date:'EEEE d MMMM' }}</span>
                    </div>
                </div>
                <div class="col-6 bg-grey-90 p-3 filter-calendar__end-date">
                    <div class="d-flex align-items-center">
                        <i class="far fa-calendar-alt fa-2x mr-3"></i>
                        <span>{{ calendarEndDate|date:'EEEE d MMMM' }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="filter-work-stations" class="mt-5 font-weight-medium position-relative">
            <div class="row">
                <div class="col-12 font-weight-bold">
                    Conseiller(e)
                </div>
                <div class="col-12">
                    <ng-select [searchable]="false" [clearable]="false" [items]="workStations"
                        placeholder="Conseiller(e)s" [loading]="workStationsLoading" loadingText="Chargement..."
                        notFoundText="Aucun résultat trouvé" (scrollToEnd)="loadWorkStations()"
                        (change)="changeSelectedWorkStations($event)"
                        class="ng-select--primary ng-select--center text-left">
                        <ng-template ng-label-tmp let-item="item">
                            <span>{{ item.user.getFullName() }}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <span>{{ item.user.getFullName() }}</span>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
    <div class="appointment-slots-listing__thumbnails-wrapper" libScrollEvent
        (onscroll)="lazyLoadAppointmentSlots($event)" [bottomOffset]="50">
        <ng-container *ngIf="appointmentSlots.length > 0; else noAppointmentSlots">
            <div class="row mb-2" *ngFor="let appointmentSlot of appointmentSlots">
                <div
                    class="appointment-slots-listing__thumbnails-wrapper__thumbnail w-100 border-bottom border-grey-90">
                    <app-appointment-slot-thumbnail [appointmentSlot]="appointmentSlot"
                        (selected)="confirmAppointmentSlot($event)"></app-appointment-slot-thumbnail>
                </div>
            </div>
        </ng-container>
        <div class="text-center py-8" *ngIf="appointmentSlotsLoading">
            <div class="spinner-border text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <ng-template #noAppointmentSlots>
            <p *ngIf="!appointmentSlotsLoading">
                Aucun rendez-vous de disponible entre le <b>{{ calendarStartDate|date:'EEEE d MMMM' }}</b> et le <b>{{
                    calendarEndDate|date:'EEEE d MMMM' }}</b>.
            </p>
        </ng-template>
    </div>
</div>