<app-top-bar [title]="appointment.getFullDate()" [leftAction]="topBarLeftAction" [rightAction]="topBarRightAction"
    (clickedRightAction)="openCancellationModal()" *ngIf="appointment"></app-top-bar>
<div class="appointment pb-3" *ngIf="appointment">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 bg-danger" *ngIf="appointment.canceledAt">
                <div class="py-2 text-center text-white font-weight-medium">
                    <i class="fas fa-ban mr-2"></i>
                    Annulé par {{ appointment.canceledBy == appointmentObject.CANCELED_BY_USER ? 'vous' :
                    appointment.getWorkStationUser().getFullName() }}
                </div>
            </div>
            <div class="col-12 bg-secondary" *ngIf="appointment.isComingSoon() && !appointment.canceledAt">
                <div class="py-2 text-center text-white font-weight-medium"
                    *ngIf="appointment.diffWithNow() as duration">
                    <i class="far fa-clock mr-2"></i>
                    A lieu dans
                    <span [ngPlural]="duration.days" *ngIf="duration.days > 0">
                        {{ duration.days }}
                        <ng-template ngPluralCase="=0"></ng-template>
                        <ng-template ngPluralCase="=1">jour</ng-template>
                        <ng-template ngPluralCase="other">jours</ng-template>
                    </span>
                    <span *ngIf="duration.days > 0 && duration.hours > 0 && duration.minutes > 0">, </span>
                    <span [ngPlural]="duration.hours" *ngIf="duration.hours > 0">
                        {{ duration.hours }}
                        <ng-template ngPluralCase="=0"></ng-template>
                        <ng-template ngPluralCase="=1">heure</ng-template>
                        <ng-template ngPluralCase="other">heures</ng-template>
                    </span>
                    <span *ngIf="(duration.minutes > 0 || duration.days > 0) && duration.minutes"> et </span>
                    <span [ngPlural]="duration.minutes" *ngIf="duration.minutes > 0">
                        {{ duration.minutes }}
                        <ng-template ngPluralCase="=0"></ng-template>
                        <ng-template ngPluralCase="=1">minute</ng-template>
                        <ng-template ngPluralCase="other">minutes</ng-template>
                    </span>
                </div>
            </div>
            <div class="col-12" *ngIf="!appointment.canceledAt">
                <div class="text-right mt-3">
                    <button type="button" class="link" (click)="openSharingModal()">
                        <i class="fas fa-share-alt mr-2"></i> Partager
                    </button>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <ng-container
                    *ngIf="appointment.getWorkStationUser().gender == gender.MALE; then genderMale else genderFemale">
                </ng-container>
                <ng-template #genderMale>
                    <h2>Interlocuteur</h2>
                </ng-template>
                <ng-template #genderFemale>
                    <h2>Interlocutrice</h2>
                </ng-template>
                <div class="row">
                    <div class="col-12 py-3 bg-white border-bottom border-grey-90">
                        <p class="font-weight-medium fs-4">{{ appointment.getWorkStationUser().getFullName() }}</p>
                        <ul class="list-unstyled list-inline font-italic additional-informations m-0 fs-2">
                            <li class="list-item mb-1" *ngIf="appointment.getWorkStation().phone">
                                <a href="tel:{{ appointment.getWorkStation().phone }}" class="link">
                                    <i class="fas fa-phone-alt"></i>{{ appointment.getWorkStation().phone }}
                                </a>
                            </li>
                            <li class="list-item" *ngIf="appointment.getWorkStation().email">
                                <a href="mailto:{{ appointment.getWorkStation().email }}" class="link">
                                    <i class="far fa-envelope"></i>{{ appointment.getWorkStation().email }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>                
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2>Partenaire</h2>
                <div class="row">
                    <app-partner-thumbnail class="w-100 border-bottom border-grey-90" [partner]="appointment.getPartner()"
                        [configuration]="{ showDescription: false, canMakingAppointment: false }">
                    </app-partner-thumbnail>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2>Lieu du rendez-vous</h2>
                <div class="row" *ngIf="appointment.availability.type == appointmentType.FACE_TO_FACE">
                    <app-agency-thumbnail class="w-100 border-bottom border-grey-90" [agency]="appointment.getAgency()"
                        [configuration]="{ canSelect: false }"></app-agency-thumbnail>
                </div>
                <div class="d-flex align-items-center py-3 w-100 bg-white border-bottom border-grey-90"
                    *ngIf="appointment.availability.type == appointmentType.PHONE">
                    <div
                        class="map-marker rounded-circle p-2 bg-dark-grey d-flex align-items-center justify-content-center">
                        <i class="fas fa-phone text-white fa-2x"></i>
                    </div>
                    <h4 class="flex-fill m-0 px-3 text-left">Par téléphone</h4>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h2>Mes notes</h2>
                <div class="mt-3">
                    <app-note [note]="note" [appointment]="appointment"></app-note>
                </div>
            </div>
        </div>
        <div class="row mt-3" libScrollEvent (onscroll)="lazyLoadSharings($event)" [bottomOffset]="50">
            <div class="col-12">
                <h2>Partagé avec</h2>
                <div class="mt-3" *ngIf="sharings.length > 0; else noSharings">
                    <ul class="list-unstyled">
                        <li *ngFor="let workStation of workStations" class="font-weight-medium mb-1">
                            - {{ workStation.user.getFullName() }}
                        </li>
                    </ul>
                </div>
                <ng-template #noSharings>
                    <p *ngIf="!sharingsLoading">Aucun partage effectué.</p>
                </ng-template>
                <div class="text-center py-4" *ngIf="sharingsLoading">
                    <div class="spinner-border text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>