import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { OrderUtil } from 'src/app/shared/utils/order.util';
import { genderMapping } from '../../enums/gender.enum';
import { UserFormHandler } from '../../form-handlers/user.form-handler';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit {

  @Input() public userFormHandler: UserFormHandler;
  @Output() public submittedStepForm: EventEmitter<boolean> = new EventEmitter();
  public registrationStepForm: FormGroup;
  public submittedRegistrationStepForm: boolean = false;
  public genderMapping: any = genderMapping;

  constructor(private localeService: BsLocaleService, public orderUtil: OrderUtil) { }

  ngOnInit(): void {
    this.localeService.use('fr');
    this.registrationStepForm = this.userFormHandler.getFormGroup('step1');
  }

  submitRegistrationStepForm() {
    this.submittedRegistrationStepForm = true;
    this.submittedStepForm.emit(this.registrationStepForm.valid);
  }

}
