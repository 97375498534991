import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxScrollEvent } from 'ngx-scroll-event';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

import { AppointmentType } from 'src/app/core/enums/appointment-type.enum';
import { MakingAppointmentStep } from 'src/app/core/enums/making-appointment-step.enum';
import { Agency } from 'src/app/core/models/agency.model';
import { AppointmentSlot } from 'src/app/core/models/appointment-slot.model';
import { MakingAppointmentService } from 'src/app/core/services/making-appointment.service';
import { AppointmentSlotService } from 'src/app/core/services/appointment-slot.service';
import { WorkStation } from 'src/app/core/models/work-station.model';
import { WorkstationService } from 'src/app/core/services/workstation.service';

@Component({
  selector: 'app-appointment-slots-listing',
  templateUrl: './appointment-slots-listing.component.html',
  styleUrls: ['./appointment-slots-listing.component.scss'],
})
export class AppointmentSlotsListingComponent implements OnInit {
  @Input() public agency: Agency;
  @Input() public byPhone: boolean;
  private _observers: Subscription = new Subscription();
  private _appointmentSlotsPage: number = 1;
  private _workStationsPage: number = 1;
  public appointmentSlotsLoading: boolean = false;
  public appointmentSlots: AppointmentSlot[] = [];
  public totalAppointmentSlots: number = 0;
  public minDate: Date = new Date();
  public calendarStartDate = new Date();
  public calendarEndDate = new Date();
  public calendarDateRangeValue: Date[] = [];
  public showDaterangepicker: boolean = false;
  public workStations: WorkStation[] = [];
  public workStationsLoading: boolean = false;
  public totalWorkStations: number = 0;
  public selectedWorkStation: WorkStation = null;

  constructor(
    private localeService: BsLocaleService,
    private appointmentSlotService: AppointmentSlotService,
    private datePipe: DatePipe,
    private makingAppointmentService: MakingAppointmentService,
    private route: Router,
    private workStationService: WorkstationService
  ) {}

  ngOnInit(): void {
    this.localeService.use('fr');

    // Set date range filter
    this.calendarEndDate.setDate(this.calendarStartDate.getDate() + 7);
    this.calendarDateRangeValue = [
      this.calendarStartDate,
      this.calendarEndDate,
    ];

    this.loadAppointmentSlots();
    this.loadWorkStations();
  }

  loadAppointmentSlots(): void {
    if (this._appointmentSlotsPage && !this.appointmentSlotsLoading) {
      this.appointmentSlotsLoading = true;
      const getAppointmentSlots: Subscription = this.appointmentSlotService
        .getAllAppointmentSlots(
          this._appointmentSlotsPage,
          this.getLoadAppointmentSlotsParams()
        )
        .pipe(
          finalize(() => {
            this.appointmentSlotsLoading = false;
          })
        )
        .subscribe((res) => {
          this.totalAppointmentSlots = res.totalItems;
          this.appointmentSlots = this.appointmentSlots.concat(res.items);
          this._appointmentSlotsPage =
            res.totalItems > this.appointmentSlots.length
              ? this._appointmentSlotsPage + 1
              : 0;
        });

      this._observers.add(getAppointmentSlots);
    }
  }

  loadWorkStations(): void {
    if (this._workStationsPage && !this.workStationsLoading) {
      this.workStationsLoading = true;
      const getWorkStations: Subscription = this.workStationService
        .getAllWorkStations(
          this._workStationsPage,
          this.getLoadWorkStationsParams()
        )
        .pipe(
          finalize(() => {
            this.workStationsLoading = false;
          })
        )
        .subscribe((res) => {
          this.totalWorkStations = res.totalItems;
          this.workStations = this.workStations.concat(res.items);
          this._workStationsPage =
            res.totalItems > this.workStations.length
              ? this._workStationsPage + 1
              : 0;
        });

      this._observers.add(getWorkStations);
    }
  }

  lazyLoadAppointmentSlots(event: NgxScrollEvent): void {
    if (event.isReachingBottom) {
      this.loadAppointmentSlots();
    }
  }

  getLoadAppointmentSlotsParams(): any {
    let params = {
      'availability.type': this.byPhone
        ? AppointmentType.PHONE
        : AppointmentType.FACE_TO_FACE,
      beginAt: moment(this.calendarStartDate).startOf('day').utc().format(),
      endAt: moment(this.calendarEndDate).endOf('day').utc().format(),
    };

    if (this.agency) {
      params['availability.workStation.agency.uuid'] = this.agency.getId();
    } else {
      params['availability.workStation.agency.partner.slug'] =
        this.makingAppointmentService.getPartner().slug;
    }

    if (this.selectedWorkStation) {
      params['availability.workStation.uuid'] = this.selectedWorkStation.getId();
    }

    return params;
  }

  getLoadWorkStationsParams(): any {
    let params = {};

    if (this.agency) {
      params['agency.uuid'] = this.agency.getId();
    } else {
      params['agency.partner.slug'] =
        this.makingAppointmentService.getPartner().slug;
    }

    return params;
  }

  changeCalendarDateRangeValue(dateRangeValue: Date[]): void {
    if (
      dateRangeValue[0] != this.calendarStartDate ||
      dateRangeValue[1] != this.calendarEndDate
    ) {
      this.calendarStartDate = dateRangeValue[0];
      this.calendarEndDate = dateRangeValue[1];

      // Load appointment slots
      this.reloadAppointmentSlots();
    }
  }

  changeSelectedWorkStations(selectedWorkStation: WorkStation): void {
    this.selectedWorkStation = selectedWorkStation;
    this.reloadAppointmentSlots();
  }

  reloadAppointmentSlots(): void {
    this._appointmentSlotsPage = 1;
    this.appointmentSlots = [];
    this.loadAppointmentSlots();
  }

  confirmAppointmentSlot(appointmentSlot: AppointmentSlot): void {
    this.makingAppointmentService.setAvailability(appointmentSlot.availability);
    this.makingAppointmentService.setBeginAt(appointmentSlot.beginAt);
    this.route.navigate(['/prise-de-rendez-vous'], {
      queryParams: this.makingAppointmentService.getQueryParams(
        MakingAppointmentStep.CONFIRMATION
      ),
    });
  }
}
