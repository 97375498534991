import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { Note } from 'src/app/core/models/note.model';
import { User } from 'src/app/core/models/user.model';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MeService } from 'src/app/core/services/me.service';
import { NotesSummaryService } from 'src/app/core/services/notes-summary.service';
import { UserService } from 'src/app/core/services/user.service';
import * as moment from 'moment';
import { SharingModalComponent } from '../sharing-modal/sharing-modal.component';
import { NotesSummary } from 'src/app/core/models/notes-summary.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { NgxScrollEvent } from 'ngx-scroll-event';

@Component({
  selector: 'app-my-notes-summary',
  templateUrl: './my-notes-summary.component.html',
  styleUrls: ['./my-notes-summary.component.scss']
})
@AutoUnsub()
export class MyNotesSummaryComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  private _notesPage: number = 1;
  public title: string = "Récap' des notes";
  public notesSummary: NotesSummary;
  public me: User;
  public notesLoading: boolean = false;
  public notes: Note[] = [];
  public modalRef: BsModalRef;
  public topBarRightAction: any = {
    id: 'share'
  };

  constructor(private userService: UserService, private loadingService: LoadingService,
    private meService: MeService, private notesSummaryService: NotesSummaryService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.me = this.meService.getMe();
    this.loadNotesSummary();
  }

  loadNotesSummary(): void {
    this.notesLoading = true;
    const getNotesSummary: Subscription = this.userService.getNotesSummary(this.me.getId()).pipe(
      finalize(() => {
        this.notesLoading = false;
        this.loadNotes();
      })
    ).subscribe(res => {
      this.notesSummary = res;      
    });

    this._observers.add(getNotesSummary);
  }

  loadNotes(): void {
    if (this._notesPage && !this.notesLoading) {
      this.notesLoading = true;
      const getNotes: Subscription = this.notesSummaryService.getAllNotes(this.notesSummary.getId(), this._notesPage, {
        ['order[appointment.beginAt]']: 'desc'
      }).pipe(
        finalize(() => {
          this.notesLoading = false;
        })
      ).subscribe(res => {
        this.notes = this.notes.concat(res.items);
        this._notesPage = (res.totalItems > this.notes.length) ? (this._notesPage + 1) : 0;
      });

      this._observers.add(getNotes);
    }
  }

  lazyLoadNotes(event: NgxScrollEvent): void {
    if (event.isReachingBottom) {
      this.loadNotes();
    }
  }

  isOtherDay(index: number): boolean {
    if (index == 0) {
      return true;
    }

    const duration = moment.duration(moment(this.notes[index].appointment.beginAt).diff(moment(this.notes[index - 1].appointment.beginAt)));

    return duration.days() != 0;
  }

  openSharingNotesSummaryModal(): void {
    this.modalRef = this.modalService.show(SharingModalComponent);
    this.modalRef.content.setSharingNotesSummary(this.notesSummary);
  }

}
