import { Serializable } from '../interfaces/serializable';

export class Page implements Serializable {

    private _uuid: string = null;
    public title: string = "";
    public slug: string = "";
    public content: string = "";
    public createdAt: Date = null;
    public updatedAt: Date = null;

    public getId(): string {
        return this.slug;
    }

    public getIRI(): string {
        return '/api/pages/' + this.getId();
    }

    public update(data: any = {}): this {
        if (!data) {
            return null;
        }

        const properties = Object.getOwnPropertyNames(this);
        Object.keys(data).forEach((key) => {
            switch (key) {
                case '_uuid': break;                
                default:
                    if (properties.includes(key)) {
                        this[key] = data[key];
                    }
                    break;
            }
        });

        return this;
    }

    public deserialize(input: any = {}): this {
        if (!input) {
            return null;
        }

        const properties = Object.getOwnPropertyNames(this);
        Object.keys(input).forEach((key) => {
            if (input[key] !== null) {
                switch (key) {
                    case 'uuid':
                        this._uuid = input[key];
                        break;                    
                    case 'createdAt':
                    case 'updatedAt':
                        this[key] = new Date(input[key]);
                        break;                    
                    default:
                        if (properties.includes(key)) {
                            this[key] = input[key];
                        }
                        break;
                }
            }
        });

        return this;
    }

    public serialize(): any {
        var output = {};

        Object.getOwnPropertyNames(this).forEach(property => {
            switch (property) {
                case '_uuid':
                    if (this[property]) {
                        output['uuid'] = this[property];
                    }
                    break;                
                default:
                    output[property] = this[property];
                    break;
            }
        });

        return output;
    }
}
