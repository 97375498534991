import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecretQuestion } from '../models/secret-question.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SecretQuestionService {

  private _apiRoutePath: string = "/secret-questions";

  constructor(private apiService: ApiService) { }

  getAllSecretQuestion(): Observable<SecretQuestion[]> {
    return this.apiService.get(this._apiRoutePath).pipe(
      map(res => {
        return res.map(item => new SecretQuestion().deserialize(item))
      })
    );
  }

  getSecretQuestion(id: string): Observable<SecretQuestion> {
    return this.apiService.get(this._apiRoutePath + '/' + id).pipe(
      map(res => new SecretQuestion().deserialize(res))
    );
  }
}
