import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyDocumentsComponent } from './my-documents/my-documents.component';

const routes: Routes = [
  {
    path: "",
    component: MyDocumentsComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: ":id",
    component: MyDocumentsComponent,
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule { }
