<div class="agency-thumbnail bg-white">
    <div class="container-fluid">
        <div class="row no-gutters align-items-center flex-nowrap">
            <div class="flex-fill">
                <button type="button" (click)="select()" class="d-flex align-items-center py-3 w-100" *ngIf="agency.hasFaceToFaceAvailabilities && configuration.canSelect; else hasNotAvailabilities">
                    <div class="map-marker rounded-circle p-2 bg-dark-grey d-flex align-items-center justify-content-center flex-shrink-0">
                        <i class="fas fa-map-marker-alt text-white fa-2x"></i>
                    </div>
                    <h4 class="flex-fill m-0 px-3 text-left" [innerHTML]="agency.address.getFullName(true)"></h4>          
                </button> 
                <ng-template #hasNotAvailabilities>
                    <div class="d-flex align-items-center py-3">
                        <div class="map-marker rounded-circle p-2 d-flex align-items-center justify-content-center flex-shrink-0" [ngClass]="{ 'bg-grey-92': configuration.canSelect, 'bg-dark-grey': !configuration.canSelect }">
                            <i class="fas fa-map-marker-alt fa-2x" [ngClass]="{ 'text-grey-80': configuration.canSelect, 'text-white': !configuration.canSelect }"></i>
                        </div>
                        <h4 class="flex-fill m-0 px-3" [ngClass]="{ 'text-grey-80': canSelect }" [innerHTML]="agency.address.getFullName(true)"></h4>  
                    </div>
                </ng-template>        
            </div>            
            <div class="border-left border-grey-75 pl-3">
                <a [href]="agency.getGoogleMapPlaceUrl()" target="_blank" title="Trouver le lieu" *ngIf="agency.hasFaceToFaceAvailabilities || !configuration.canSelect; else noGoogleMapPlace">
                    <i class="fas fa-directions text-warning fa-3x"></i>                   
                </a>
                <ng-template #noGoogleMapPlace>
                    <i class="fas fa-directions text-grey-80 fa-3x"></i>  
                </ng-template>        
            </div>                       
        </div>
    </div>
</div>