import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { User } from 'src/app/core/models/user.model';
import { FolderService } from 'src/app/core/services/folder.service';
import { MeService } from 'src/app/core/services/me.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-size-progress',
  templateUrl: './size-progress.component.html',
  styleUrls: ['./size-progress.component.scss']
})
@AutoUnsub()
export class SizeProgressComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  private _me: User;
  public value: number = 0;
  public max: number = environment.publicServer.storage.size.max;

  constructor(private userService: UserService, private meService: MeService,
    private decimalPipe: DecimalPipe, private folderService: FolderService) { }

  ngOnInit(): void {
    this._me = this.meService.getMe();
    this.getRootFolder();
    this.setObservers();
  }

  setObservers(): void {
    this._observers.add(this.observeFolderContent());
  }

  observeFolderContent(): Subscription {
    return this.folderService.getFolderContent().subscribe(res => {
      switch (res.status) {
        case 'addDocuments':
          res.documents.forEach(document => {
            this.value += document.file.size;
          });          
          break;  
        case 'removeDocument':
          this.value -= res.document.file.size;
          break;  
        case 'removeFolder':
          this.value -= res.folder.size;
          break; 
        default: break; 
      }
    });
  }

  getRootFolder(): void {
    const getRootFolder = this.userService.getRootFolder(this._me.getId()).subscribe(res => {
      this.value = res.size;
    });

    this._observers.add(getRootFolder);
  }

  getTransformedValue(): number {
    return this.value/1000000;
  }

  getPercentage(): string {
    return this.decimalPipe.transform(this.getTransformedValue(), '1.2-2', 'fr');
  }

}
