<div class="appointment-thumbnail py-4 bg-white border-bottom border-grey-90">
    <div class="container-fluid">
       <div class="d-flex">
            <a [routerLink]="['/rendez-vous', appointment.getId()]" class="flex-fill" *ngIf="config.linkable">  
                <ng-template [ngTemplateOutlet]="appointmentContent"></ng-template>  
            </a>
            <ng-template [ngTemplateOutlet]="appointmentContent" *ngIf="!config.linkable"></ng-template>
            <div class="btn-group align-self-start" dropdown placement="bottom right" *ngIf="!appointment.canceledAt && config.menu">
                <button id="dropdown-btn-appointment-{{ appointment.getId() }}" dropdownToggle type="button" class="fs-4">
                    <i class="fas fa-ellipsis-v"></i>
                </button>
                <ul id="dropdown-menu-appointment-{{ appointment.getId() }}" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-alignment">
                    <li role="menuitem">
                        <button type="button" class="dropdown-item link" (click)="openSharingModal()">
                            <i class="fas fa-share-alt"></i> Partager
                        </button>
                    </li>
                    <li role="menuitem" *ngIf="appointment.canBeCanceled()">
                        <button type="button" class="dropdown-item text-danger link" (click)="openCancellationModal()">
                            <i class="fas fa-ban"></i> Annuler
                        </button>
                    </li>                
                </ul>
            </div>
        </div>
    </div>
</div>

<ng-template #appointmentContent>
    <div class="d-flex align-items-stretch position-relative w-100">
        <div class="marker flex-shrink-0">
            <div
                class="calendar-marker rounded-circle p-2 d-flex align-items-center justify-content-center" [ngClass]="{ 'bg-dark-grey': !comingSoon, 'bg-secondary': comingSoon }">
                <i class="fas fa-calendar-day text-white fs-6"></i>
            </div>
        </div>
        <div class="px-3 flex-fill text-left">
            <h4 class="mb-0 font-weight-bold">{{ appointment.getFullDate() }}</h4>
            <p class="mb-0 text-grey-35 font-weight-medium">
                <span
                    *ngIf="appointment.getWorkStationUser().gender == gender.MALE; then genderMale else genderFemale"></span>
                <ng-template #genderMale>Interlocuteur</ng-template>
                <ng-template #genderFemale>Interlocutrice</ng-template>
                : {{ appointment.getWorkStationUser().getFullName() }}
            </p> 
            <div class="appointment-thumbnail__partner d-flex align-items-center py-2">
                <div class="p-1 bg-white rounded overflow-hidden border border-92 mr-2 flex-shrink-0">
                    <div class="img-wrapper" [style]="{ 'background-image': 'url(' + appointment.getPartner().getLogoPath() + ')' }">
                        <img src="assets/img/placeholders/square-thumbnail.png" class="img-fluid" alt="partner.name" />            
                    </div>
                </div>
                <span class="font-weight-semi-bold">{{ appointment.getPartner().name }}</span>
            </div>
            <div class="appointment-thumbnail__agency d-flex py-2 align-items-center">
                <div class="map-marker rounded-circle mr-2 bg-dark-grey d-flex align-items-center justify-content-center flex-shrink-0">
                    <i class="fas text-white" [ngClass]="{'fa-map-marker-alt': appointment.availability.type == appointmentType.FACE_TO_FACE, 'fa-phone': appointment.availability.type == appointmentType.PHONE}"></i>
                </div>
                <span *ngIf="appointment.availability.type == appointmentType.FACE_TO_FACE; else byPhoneTemplate" [innerHTML]="appointment.getAddress().getFullName(true)" class="font-weight-semi-bold"></span> 
                <ng-template #byPhoneTemplate>
                    <span class="font-weight-semi-bold">Par téléphone</span>
                </ng-template>
            </div>                                                  
        </div>
        <div class="mask-cancellation" *ngIf="appointment.canceledAt">
            <div class="position-center text-danger font-weight-bold fs-4 w-100 text-center">
                Annulé par {{ appointment.canceledBy == appointmentObject.CANCELED_BY_USER ? 'vous' : appointment.getWorkStationUser().getFullName() }}
            </div>
        </div>
    </div>
</ng-template>