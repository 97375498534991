import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditMyProfileComponent } from './edit-my-profile/edit-my-profile.component';
import { MyProfileComponent } from './my-profile/my-profile.component';

const routes: Routes = [
  {
    path: "",
    component: MyProfileComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: "modifier",
    component: EditMyProfileComponent,
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
