import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxScrollEvent } from 'ngx-scroll-event';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppointmentType } from 'src/app/core/enums/appointment-type.enum';
import { MakingAppointmentStep } from 'src/app/core/enums/making-appointment-step.enum';
import { Agency } from 'src/app/core/models/agency.model';
import { Partner } from 'src/app/core/models/partner.model';
import { AppointmentSlotService } from 'src/app/core/services/appointment-slot.service';
import { MakingAppointmentService } from 'src/app/core/services/making-appointment.service';
import { PartnerService } from 'src/app/core/services/partner.service';

@Component({
  selector: 'app-agencies-listing',
  templateUrl: './agencies-listing.component.html',
  styleUrls: ['./agencies-listing.component.scss'],
})
export class AgenciesListingComponent implements OnInit {
  @Input() public partner: Partner;
  private _observers: Subscription = new Subscription();
  private _agenciesPage: number = 1;
  public agenciesLoading: boolean = false;
  public agencies: Agency[] = [];
  public totalAgencies: number = 0;
  public hasAvailabilitiesByPhone: boolean = false;

  constructor(
    private partnerService: PartnerService,
    private makingAppointmentService: MakingAppointmentService,
    private appointmentSlotService: AppointmentSlotService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.loadAgencies();
    this.checkAvailabilitiesByPhone();
  }

  loadAgencies(): void {
    if (this._agenciesPage && !this.agenciesLoading) {
      this.agenciesLoading = true;
      const getAgencies: Subscription = this.partnerService
        .getPartnerAgencies(this.partner.getId(), this._agenciesPage)
        .pipe(
          finalize(() => {
            this.agenciesLoading = false;
          })
        )
        .subscribe((res) => {
          this.totalAgencies = res.totalItems;
          this.agencies = this.agencies.concat(res.items);
          this._agenciesPage =
            res.totalItems > this.agencies.length ? this._agenciesPage + 1 : 0;
        });

      this._observers.add(getAgencies);
    }
  }

  lazyLoadAgencies(event: NgxScrollEvent): void {
    if (event.isReachingBottom) {
      this.loadAgencies();
    }
  }

  checkAvailabilitiesByPhone(): void {
    const getAvailabilitiesByPhone: Subscription = this.appointmentSlotService
      .getAllAppointmentSlots(1, {
        'availability.type': AppointmentType.PHONE,
        'availability.workStation.agency.partner.slug': this.partner.slug,
      })
      .subscribe((res) => {
        this.hasAvailabilitiesByPhone = Boolean(res.items.length);
      });

    this._observers.add(getAvailabilitiesByPhone);
  }

  selectAppointmentSlotsByPhone(): void {
    this.makingAppointmentService.setByPhone(true);
    this.route.navigate(['/prise-de-rendez-vous'], {
      queryParams: this.makingAppointmentService.getQueryParams(
        MakingAppointmentStep.AVAILABILITY
      ),
    });
  }

  showAppointmentSlots(agency: Agency): void {
    this.makingAppointmentService.setAgency(agency);
    this.route.navigate(['/prise-de-rendez-vous'], {
      queryParams: this.makingAppointmentService.getQueryParams(
        MakingAppointmentStep.AVAILABILITY
      ),
    });
  }
}
