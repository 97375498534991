import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-request-success',
  templateUrl: './password-request-success.component.html',
  styleUrls: ['./password-request-success.component.scss']
})
export class PasswordRequestSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
