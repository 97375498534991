import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Appointment } from 'src/app/core/models/appointment.model';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import * as moment from 'moment';
import { SearchModalComponent } from 'src/app/making-appointment/search-modal/search-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxScrollEvent } from 'ngx-scroll-event';
import { SharingModalComponent } from '../sharing-modal/sharing-modal.component';
import { MISSION_LOCALE_SLUG } from 'src/app/core/constants/partner.constants';

@Component({
  selector: 'app-my-appointments',
  templateUrl: './my-appointments.component.html',
  styleUrls: ['./my-appointments.component.scss']
})
export class MyAppointmentsComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  private _pastAppointmentsPage: number = 1;
  private _comingSoonAppointmentsPage: number = 1;
  public title: string = "Mes rendez-vous";
  public pastAppointments: Appointment[] = [];
  public pastAppointmentsTotal:  number = 0;
  public pastAppointmentsLoading: boolean = false;
  public comingSoonAppointments: Appointment[] = [];
  public comingSoonAppointmentsTotal:  number = 0;
  public comingSoonAppointmentsLoading: boolean = false;
  public bsModalRef: BsModalRef;
  public topBarRightAction: any = {
    id: 'share'
  };

  constructor(private appointmentService: AppointmentService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.loadPastAppointments();
    this.loadComingSoonAppointments();
  }

  loadPastAppointments(): void {
    if (this._pastAppointmentsPage && !this.pastAppointmentsLoading) {
      this.pastAppointmentsLoading = true;
      const getAppointments: Subscription = this.appointmentService.getAllAppointments(this._pastAppointmentsPage, this.getLoadAppointmentsParams(false)).pipe(
        finalize(() => {
          this.pastAppointmentsLoading = false;
        })
      ).subscribe(res => {
          this.pastAppointmentsTotal = res.totalItems;
          this.pastAppointments = this.pastAppointments.concat(res.items);
          this._pastAppointmentsPage = (res.totalItems > this.pastAppointments.length) ? (this._pastAppointmentsPage + 1) : 0;
      });

      this._observers.add(getAppointments);
    }
  }

  loadComingSoonAppointments(): void {
    if (this._comingSoonAppointmentsPage && !this.comingSoonAppointmentsLoading) {
      this.comingSoonAppointmentsLoading = true;
      const getAppointments: Subscription = this.appointmentService.getAllAppointments(this._comingSoonAppointmentsPage, this.getLoadAppointmentsParams()).pipe(
        finalize(() => {
          this.comingSoonAppointmentsLoading = false;
        })
      ).subscribe(res => {
          this.comingSoonAppointmentsTotal = res.totalItems;
          this.comingSoonAppointments = this.comingSoonAppointments.concat(res.items);
          this._comingSoonAppointmentsPage = (res.totalItems > this.comingSoonAppointments.length) ? (this._comingSoonAppointmentsPage + 1) : 0;
      });

      this._observers.add(getAppointments);
    }
  }

  getLoadAppointmentsParams(comingSoon: boolean = true): any {
    const now = new Date();    

    let params = {
      ['beginAt[' + (comingSoon ? 'strictly_after' : 'before') + ']']: moment.utc(now).format(),
      ['order[beginAt]']: !comingSoon ? 'desc' : 'asc'
    };

    return params;
  }

  openMakingAppointmentSearchModal(): void {    
    this.bsModalRef = this.modalService.show(SearchModalComponent);
    this.bsModalRef.content.focusSearchElement = true;
  }

  lazyLoadPastAppointments(event: NgxScrollEvent): void {
    if (event.isReachingBottom) {
      this.loadPastAppointments();
    }
  }

  lazyLoadComingSoonAppointments(event: NgxScrollEvent): void {
    if (event.isReachingBottom) {
      this.loadComingSoonAppointments();
    }
  }

  openSharingAppointmentModal(): void {
    this.bsModalRef = this.modalService.show(SharingModalComponent);
  }

  getMissionLocaleQueryParams(): any {
    return {
      partner: MISSION_LOCALE_SLUG,
    };
  }

}
