<div class="container">
    <div class="row">
       <div class="col-12">
            <app-header-logo></app-header-logo>
       </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h1 class="mb-5">Validation du numéro de téléphone pour retrouver les identifiants</h1>  
            <p class="mb-5">Afin de valider votre nouveau numéro de téléphone veuillez remplir ce formulaire, un conseiller va traiter votre demande, il se peut qu'il vous contacte pour s'assurer de votre identité.</p>          
            <form [formGroup]="phoneUpdatingRequestForm" (ngSubmit)="submitPhoneUpdatingRequestForm()">
                <div class="form-group">                    
                    <input appFloatLabel id="firstName" type="text" formControlName="firstName" class="form-control"/>
                    <label for="firstName">Prénom</label>
                    <ng-container
                        *ngIf="phoneUpdatingRequestForm.get('firstName').invalid && (phoneUpdatingRequestForm.get('firstName').dirty || phoneUpdatingRequestForm.get('firstName').touched || submittedPhoneUpdatingRequestForm)">
                        <small class="text-danger"
                            *ngIf="phoneUpdatingRequestForm.get('firstName').errors.required">
                            Le prénom est obligatoire
                        </small>
                    </ng-container>  
                </div> 
                <div class="form-group">                    
                    <input appFloatLabel id="lastName" type="text" formControlName="lastName" class="form-control"/>
                    <label for="lastName">Nom</label>
                    <ng-container
                        *ngIf="phoneUpdatingRequestForm.get('lastName').invalid && (phoneUpdatingRequestForm.get('lastName').dirty || phoneUpdatingRequestForm.get('lastName').touched || submittedPhoneUpdatingRequestForm)">
                        <small class="text-danger"
                            *ngIf="phoneUpdatingRequestForm.get('lastName').errors.required">
                            Le prénom est obligatoire
                        </small>
                    </ng-container>  
                </div> 
                <div class="form-group">                               
                    <ng-select appearance="outline"
                            [searchable]="false"
                            [clearable]="false"
                            [items]="genderMapping|keyvalue:orderUtil.originalOrder"
                            bindValue="value"
                            bindLabel="value"
                            formControlName="gender"
                            labelForId="gender"
                            class="ng-select--primary text-left {{ phoneUpdatingRequestForm.get('gender').value != '' ? 'float-label' : '' }}">
                        </ng-select>
                        <label for="gender">Civilité</label>  
                    <ng-container
                        *ngIf="phoneUpdatingRequestForm.get('gender').invalid && (phoneUpdatingRequestForm.get('gender').dirty || phoneUpdatingRequestForm.get('gender').touched || submittedPhoneUpdatingRequestForm)">
                        <small class="text-danger"
                            *ngIf="phoneUpdatingRequestForm.get('gender').errors.required">
                            La civilité est obligatoire
                        </small>
                    </ng-container>  
                </div>  
                <div class="form-group">                    
                    <input appFloatLabel
                        id="birthDate"
                        class="form-control"
                        #datepickerBirthDate="bsDatepicker"
                        bsDatepicker
                        formControlName="birthDate"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', isAnimated: true, returnFocusToInput: true, containerClass: 'theme-default' }">
                    <label for="birthDate">Date de naissance</label>
                    <ng-container
                        *ngIf="phoneUpdatingRequestForm.get('birthDate').invalid && (phoneUpdatingRequestForm.get('birthDate').dirty || phoneUpdatingRequestForm.get('birthDate').touched || submittedPhoneUpdatingRequestForm)">
                        <small class="text-danger"
                            *ngIf="phoneUpdatingRequestForm.get('birthDate').errors.required">
                            La date de naissance est obligatoire
                        </small>
                    </ng-container>  
                </div> 
                <ng-container formArrayName="secretAnswers" *ngIf="userInformationRequestFormHandler.getFormArray('secretAnswers').controls as secretAnswersControls">
                    <div class="form-group" *ngFor="let secretAnswerControl of secretAnswersControls; let i = index">                    
                        <input appFloatLabel id="secretQuestion{{i}}" type="text" [formControl]="secretAnswerControl" class="form-control"/>
                        <label for="secretQuestion{{i}}">{{ userInformationRequestFormHandler.secretQuestions[i].question }}</label>
                        <ng-container
                            *ngIf="secretAnswerControl.invalid && (secretAnswerControl.dirty || secretAnswerControl.touched || submittedPhoneUpdatingRequestForm)">
                            <small class="text-danger"
                                *ngIf="secretAnswerControl.errors.required">
                                La réponse est obligatoire
                            </small>
                        </ng-container>  
                    </div> 
                </ng-container>    
                <div class="form-group">                    
                    <input appFloatLabel id="phone" type="text" formControlName="phone" class="form-control"/>
                    <label for="phone">Numéro de téléphone</label>
                    <ng-container
                        *ngIf="phoneUpdatingRequestForm.get('phone').invalid && (phoneUpdatingRequestForm.get('phone').dirty || phoneUpdatingRequestForm.get('phone').touched || submittedPhoneUpdatingRequestForm)">
                        <small class="text-danger"
                            *ngIf="phoneUpdatingRequestForm.get('phone').errors.required">
                            Le numéro de téléphone est obligatoire
                        </small>
                    </ng-container>  
                </div>  
                <div class="form-group">                    
                    <input appFloatLabel id="username" type="text" formControlName="username" class="form-control"/>
                    <label for="username">Identifiant (optionnel)</label>                      
                </div>         
                <div class="mt-12 text-center">
                    <button type="submit" class="btn bg-primary text-white mx-auto mb-4">
                        Valider
                    </button>
                    <div class="mb-1">
                        <a [routerLink]="['/login']" class="d-inline-block mx-auto text-primary">
                            Revenir à la connexion
                        </a>
                    </div>
                </div>                                
            </form>          
        </div>
    </div>
    <div class="row mt-12">
        <div class="col-12 text-center">
            <app-footer-legal-notice></app-footer-legal-notice>
        </div>
    </div>
</div>