import { Serializable } from "../interfaces/serializable";
import { Appointment } from "./appointment.model";
import { Note } from "./note.model";
import { NotesSummary } from "./notes-summary.model";
import { WorkStation } from "./work-station.model";

export class Sharing implements Serializable {

    static readonly SHARING_TYPE_APPOINTMENT = 'sharing_type_appointment';
    static readonly SHARING_TYPE_NOTE = 'sharing_type_note';
    static readonly SHARING_TYPE_NOTES_SUMMARY = 'sharing_type_notes_summary';

    private _uuid: string = null;
    public appointment: Appointment = null;
    public note: Note = null;
    public notesSummary: NotesSummary = null;
    public workStations: WorkStation[] = [];
    public createdAt: Date = null;
    public updatedAt: Date = null;

    public getId(): string {
        return this._uuid;
    }

    public getIRI(): string {
        return '/api/sharings/' + this.getId();
    }

    public getType(): string {
        if (this.appointment) {
            return Sharing.SHARING_TYPE_APPOINTMENT;
        } else if (this.note) {
            return Sharing.SHARING_TYPE_NOTE;
        } else if (this.notesSummary) {
            return Sharing.SHARING_TYPE_NOTES_SUMMARY;
        }

        return;
    }

    public update(data: any = {}): this {
        if (!data) {
            return null;
        }

        const properties = Object.getOwnPropertyNames(this);
        Object.keys(data).forEach((key) => {
            switch (key) {
                case '_uuid': break;                
                default:
                    if (properties.includes(key)) {
                        this[key] = data[key];
                    }
                    break;
            }
        });

        return this;
    }

    public deserialize(input: any = {}): this {
        if (!input) {
            return null;
        }

        const properties = Object.getOwnPropertyNames(this);
        Object.keys(input).forEach((key) => {
            if (input[key] !== null) {
                switch (key) {
                    case 'uuid':
                        this._uuid = input[key];
                        break;
                    case 'appointment':
                        this[key] = new Appointment().deserialize(input[key]);
                        break;
                    case 'note':
                        this[key] = new Note().deserialize(input[key]);
                        break;
                    case 'notesSummary':
                        this[key] = new NotesSummary().deserialize(input[key]);
                        break;
                    case 'workStations':
                        input[key].forEach(item => {
                            this[key].push(new WorkStation().deserialize(item)); 
                        });                        
                        break;
                    case 'createdAt':
                    case 'updatedAt':
                        this[key] = new Date(input[key]);
                        break;                    
                    default:
                        if (properties.includes(key)) {
                            this[key] = input[key];
                        }
                        break;
                }
            }
        });

        return this;
    }

    public serialize(): any {
        var output = {};

        Object.getOwnPropertyNames(this).forEach(property => {
            switch (property) {
                case '_uuid':
                    if (this[property]) {
                        output['uuid'] = this[property];
                    }
                    break;                
                default:
                    output[property] = this[property];
                    break;
            }
        });

        return output;
    }
}
