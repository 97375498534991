<div class="container">
    <div class="row">
       <div class="col-12">
            <app-header-logo></app-header-logo>
       </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h1 class="mb-5">Demande nouveau lien de validation de compte</h1>
            <form [formGroup]="accountValidationRequestForm" (ngSubmit)="submitAccountValidationRequestForm()">
                <div class="form-group">                    
                    <input appFloatLabel id="username" type="text" formControlName="username" class="form-control"/>
                    <label for="username">Identifiant</label>
                    <ng-container
                        *ngIf="accountValidationRequestForm.get('username').invalid && (accountValidationRequestForm.get('username').dirty || accountValidationRequestForm.get('username').touched || submittedAccountValidationRequestForm)">
                        <small class="text-danger"
                            *ngIf="accountValidationRequestForm.get('username').errors.required">
                            L'identifiant est obligatoire
                        </small>
                    </ng-container>  
                </div>                 
                <div class="mt-12 text-center">
                    <button type="submit" class="btn bg-primary text-white mx-auto mb-4">
                        Valider
                    </button>
                    <div class="mb-1">
                        <a [routerLink]="['/login']" class="d-inline-block mx-auto text-primary">
                            Revenir à la connexion
                        </a>
                    </div>
                </div>                                
            </form>
        </div>
    </div>
    <div class="row mt-12">
        <div class="col-12 text-center">
            <app-footer-legal-notice></app-footer-legal-notice>
        </div>
    </div>
</div>