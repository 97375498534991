import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Document } from '../models/document.model';
import { Folder } from '../models/folder.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FolderService {

  private _apiRoutePath: string = "/folders";
  private _folderContentSubject: Subject<any> = new Subject();

  constructor(private apiService: ApiService) { }

  updateFolderContent(data: any): void {
    this._folderContentSubject.next(data);
  }

  getFolderContent(): Observable<any> {
    return this._folderContentSubject.asObservable();
  }

  getAllFolders(page: number = 1, params: any = {}): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Accept': 'application/ld+json'
      }),
      params: {
        page: page
      }
    };

    options.params = Object.assign(options.params, params);

    return this.apiService.get(this._apiRoutePath, options).pipe(
      map(res => {
        return {
          totalItems: res['hydra:totalItems'],
          items: res['hydra:member'].map(item => new Folder().deserialize(item))
        };
      })
    );
  }

  createFolder(data: any): Observable<Folder> {
    return this.apiService.post(this._apiRoutePath, data).pipe(
      map(res => new Folder().deserialize(res))
    );
  }

  updateFolder(id: string, data: any): Observable<Folder> {
    return this.apiService.put(this._apiRoutePath + '/' + id, data).pipe(
      map(res => new Folder().deserialize(res))
    );
  }

  getFolder(id: string): Observable<Folder> {
    return this.apiService.get(this._apiRoutePath + '/' + id).pipe(
      map(res => new Folder().deserialize(res))
    );
  }

  deleteFolder(id: string): Observable<any> {
    return this.apiService.delete(this._apiRoutePath + '/' + id);
  }

  getAllDocuments(id: string, page: number = 1, params: any = {}): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Accept': 'application/ld+json'
      }),
      params: {
        page: page
      }
    };

    options.params = Object.assign(options.params, params);

    return this.apiService.get(this._apiRoutePath + '/' + id + '/documents', options).pipe(
      map(res => {
        return {
          totalItems: res['hydra:totalItems'],
          items: res['hydra:member'].map(item => new Document().deserialize(item))
        };
      })
    );
  }
}
