<div class="modal-header">
    <h4>Annulation du rendez-vous</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="mb-0" *ngIf="appointment">Souhaitez-vous réellement annuler le rendez-vous avec <span *ngIf="appointment.getWorkStationUser().gender == gender.MALE; then genderMale else genderFemale" class="font-weight-bold"></span>
        <ng-template #genderMale>Mr</ng-template>
        <ng-template #genderFemale>Mme</ng-template>                
        <span class="font-weight-bold"> {{ appointment.getWorkStationUser().getFullName() }} le {{ appointment.beginAt|date:'d MMMM' }} à {{ appointment.beginAt|date:'HH' }}h{{ appointment.beginAt|date:'mm' }}</span>
        <span class="font-weight-bold">
            <ng-container *ngIf="appointment.availability.type == appointmentType; then appointmentByPhone else appointmentAgency"></ng-container>
            <ng-template #appointmentByPhone> par téléphone.</ng-template>
            <ng-template #appointmentAgency> au {{ appointment.getAddress().getFullName() }}.</ng-template>
        </span>        
    </p>
    <button type="button" *ngIf="appointment" class="btn btn-danger w-100 mt-6" (click)="cancelAppointment()">Annuler</button>
</div>