import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieStorage } from 'cookie-storage';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { MeService } from './me.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  private _cookieStorage = new CookieStorage({
    path: '/'
  });
  private _accessTokenKey: string = 'at';

  constructor(private authService: AuthService,
    private apiService: ApiService,
    private meService: MeService,
    private router: Router) { }

  initApp(): Promise<any> {
    return Promise.all([this.getAuthentication()]);
  }

  getAuthentication(): Promise<void> {
    const accessToken = this._cookieStorage.getItem(this._accessTokenKey);

    if (accessToken) {
      this.apiService.setAuthorization(accessToken);
      
      return new Promise((resolve, reject) => {
        this.authService.getAuthenticatedUser().pipe(
          catchError(error => {            
            this.meService.deleteMe();
            return this.authService.authenticateAnonymous();
          })
        ).subscribe(
          res => {
            if (res instanceof User) {
              this.meService.storeMe(res);
              this.authService.updateAuthentication(true);              
            } 
            resolve();         
          },
          err => resolve()
        );
      });
    } else {
      this.meService.deleteMe();

      return new Promise((resolve, reject) => {
        this.authService.authenticateAnonymous().subscribe(
          res => {},
          err => {},
          () => resolve()
        );
      });
    }
  }


}
