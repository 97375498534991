import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { Folder } from 'src/app/core/models/folder.model';
import { ErrorService } from 'src/app/core/services/error.service';
import { FolderService } from 'src/app/core/services/folder.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import * as _ from 'lodash';
import { DocumentService } from 'src/app/core/services/document.service';

@Component({
  selector: 'app-folder-thumbnail',
  templateUrl: './folder-thumbnail.component.html',
  styleUrls: ['./folder-thumbnail.component.scss']
})
@AutoUnsub()
export class FolderThumbnailComponent implements OnInit, AfterViewInit {

  @Input() public folder: Folder;
  @Output() public onDeleteFolder: EventEmitter<any> = new EventEmitter();
  @ViewChildren('renameFolder') renameFolderElement: QueryList<ElementRef>;
  private _observers: Subscription = new Subscription();
  public modalRef: BsModalRef;
  public renameFolderForm: FormGroup;
  public showRenameFolder: boolean = false;

  constructor(private router: Router, private modalService: BsModalService,
    private folderService: FolderService, private loadingService: LoadingService,
    private errorService: ErrorService, private toastrService: ToastrService,
    private formBuilder: FormBuilder, public documentService: DocumentService) { }

  ngOnInit(): void {
    this.prepareRenameFolderForm();
  }

  ngAfterViewInit() {
    this.renameFolderElement.changes.subscribe(_ => {
      if (this.showRenameFolder) {
        this.renameFolderElement.first.nativeElement.focus();
      }
    });
  }

  prepareRenameFolderForm(): void {
    this.renameFolderForm = this.formBuilder.group({
      title: [this.folder.title, Validators.required]
    });
  }

  openModal(template: TemplateRef<any>,) {
    this.modalRef = this.modalService.show(template);
  }

  select(): void {
    this.documentService.isInSelection(this.folder) ? this.documentService.removeSelectionElement(this.folder) : this.documentService.addSelectionElement(this.folder);
  }

  delete(): void {
    this.loadingService.showLoading();
    const deleteFolder = this.folderService.deleteFolder(this.folder.getId()).pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(res => {
      this.onDeleteFolder.emit(this.folder);
      this.folderService.updateFolderContent({
        status: 'removeFolder',
        folder: this.folder
      });

      this.toastrService.success("Dossier supprimé avec succès.", "Félicitations !");
      this.modalRef.hide();
    }, err => {
      this.errorService.addError(err);
      this.errorService.show();
    });

    this._observers.add(deleteFolder);
  }

  submitRenameFolderForm(): void {
    if (this.renameFolderForm.valid) {
      this.loadingService.showLoading();

      let data = _.cloneDeep(this.renameFolderForm.value);

      const renameFolder = this.folderService.updateFolder(this.folder.getId(), data).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        this.folder = res;
        this.showRenameFolder = false;
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(renameFolder);
    }
  }

  goToFolder(): void {
    this.router.navigate(['/mes-documents', this.folder.getId()]);
  }

}
