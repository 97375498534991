import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { genderMapping } from '../enums/gender.enum';
import { UserFormHandler } from '../form-handlers/user.form-handler';
import { ErrorService } from '../services/error.service';
import { LoadingService } from '../services/loading.service';
import { SecretQuestionService } from '../services/secret-question.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  public userFormHandler: UserFormHandler;
  public registrationForm: FormGroup;
  public submittedRegistrationForm: boolean = false;
  public genderMapping: any = genderMapping;
  public nbSteps: number = 2;
  public currentStep: number = 1;

  constructor(private secretQuestioService: SecretQuestionService, private loadingService: LoadingService,
    private userService: UserService, private router: Router,
    private errorService: ErrorService) { 
    this.userFormHandler = new UserFormHandler(this.secretQuestioService);
  }

  ngOnInit(): void {
    this.prepareRegistrationForm();
  }

  prepareRegistrationForm(): void {
    this.userFormHandler.init(UserFormHandler.USER_FORM_TYPE_REGISTRATION);
    this.registrationForm = this.userFormHandler.getForm();
  }

  onSubmittedStepForm(validatedStepForm: boolean): void {
    if (validatedStepForm) {
      (this.currentStep == this.nbSteps) ? this.registration() : this.currentStep++;      
    }
  }

  registration(): void {
    this.loadingService.showLoading();

    const registration = this.userService.createUser(this.userFormHandler.getUserValue()).pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(res => {
      this.router.navigateByUrl('/inscription-terminee');
    }, err => {
      this.errorService.addError(err);
      this.errorService.show();
    });

    this._observers.add(registration);
  }
}
