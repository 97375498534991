<form [formGroup]="registrationStepForm" (ngSubmit)="submitRegistrationStepForm()">
    <ng-container formArrayName="secretAnswers" *ngIf="userFormHandler.getFormArray('secretAnswers', registrationStepForm).controls as secretAnswersControls">
        <div class="form-group" *ngFor="let secretAnswerControl of secretAnswersControls; let i = index">                    
            <input appFloatLabel id="secretQuestion{{i}}" type="text" [formControl]="secretAnswerControl" class="form-control"/>
            <label for="secretQuestion{{i}}">{{ userFormHandler.secretQuestions[i].question }}</label>
            <ng-container
                *ngIf="secretAnswerControl.invalid && (secretAnswerControl.dirty || secretAnswerControl.touched || submittedRegistrationStepForm)">
                <small class="text-danger"
                    *ngIf="secretAnswerControl.errors.required">
                    La réponse est obligatoire
                </small>
            </ng-container>  
        </div> 
    </ng-container>
    <div class="form-group">                    
        <input appFloatLabel id="plainPassword" type="password" formControlName="plainPassword" class="form-control"/>
        <label for="plainPassword">Mot de passe</label>
        <ng-container
            *ngIf="registrationStepForm.get('plainPassword').invalid && (registrationStepForm.get('plainPassword').dirty || registrationStepForm.get('plainPassword').touched || submittedRegistrationStepForm)">
            <small class="text-danger"
                *ngIf="registrationStepForm.get('plainPassword').errors.required">
                Le mot de passe est obligatoire
            </small>
            <small class="text-danger"
                *ngIf="registrationStepForm.get('plainPassword').errors.minlength">
                Le mot de passe doit comporter 6 caractères minimum
            </small>
        </ng-container>  
    </div> 
    <div class="form-group">                    
        <input appFloatLabel id="confirmPlainPassword" type="password" formControlName="confirmPlainPassword" class="form-control"/>
        <label for="confirmPlainPassword">Confirmation du mot de passe</label>
        <ng-container
            *ngIf="registrationStepForm.get('confirmPlainPassword').invalid && (registrationStepForm.get('confirmPlainPassword').dirty || registrationStepForm.get('confirmPlainPassword').touched || submittedRegistrationStepForm)">
            <small class="text-danger"
                *ngIf="registrationStepForm.get('confirmPlainPassword').errors.required">
                La confirmation du mot de passe est obligatoire
            </small>
            <small class="text-danger" *ngIf="registrationStepForm.get('confirmPlainPassword').errors.matching">
                Les deux mots de passe doivent être identiques
            </small>
        </ng-container>  
    </div>
    <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" formControlName="acceptedTermsOfService" id="acceptedTermsOfService">
        <label class="form-check-label" for="acceptedTermsOfService">
            J'accepte les CGU
        </label>
        <ng-container
            *ngIf="registrationStepForm.get('acceptedTermsOfService').invalid && (registrationStepForm.get('acceptedTermsOfService').dirty || registrationStepForm.get('acceptedTermsOfService').touched || submittedRegistrationStepForm)">
            <small class="text-danger d-block"
                *ngIf="registrationStepForm.get('acceptedTermsOfService').errors.required">
                Vous devez accepter les CGU
            </small>
        </ng-container>
    </div>
    <p>Les questions secrètes vous permettront de retrouver vos identifiants ou de demander de changer des informations personnelles.</p>
    <div>
        <button type="button" class="link" (click)="previousStep.emit()">
            <i class="fas fa-chevron-left"></i>
            <span class="ml-2 text-underline">Précédent</span>
        </button>
    </div>
    <div class="mt-12 text-center">
        <button type="submit" class="btn bg-primary text-white mx-auto mb-4">
            Continuer
        </button>
        <div>
            <a [routerLink]="['/login']" class="d-inline-block mx-auto text-primary">
                Revenir à la connexion
            </a>
        </div>
    </div>                                
</form>