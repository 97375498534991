<div class="making-appointment-search">
    <form [formGroup]="makingAppointmentSearchForm" (ngSubmit)="submitMakingAppointmentSearchForm()">
        <h1 class="text-secondary mb-3">Quel est votre besoin ?</h1>
        <div class="input-group mb-3 btn-search">
            <input type="text" #search class="form-control" placeholder="études, logement, aide..." aria-label="études, logement, aide..." 
                aria-describedby="btn-search-addon" formControlName="search">
            <button class="btn btn-dark-grey fs-5" type="submit" id="btn-search-addon">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </form>
</div>