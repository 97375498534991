<div class="agencies-listing">
    <button class="btn bg-primary text-white mx-auto mb-5 w-100" type="button" (click)="selectAppointmentSlotsByPhone()" *ngIf="hasAvailabilitiesByPhone">
        Je choisis un rendez-vous par téléphone
    </button>
    <h1 class="mb-4">
        Je choisis un lieu de rendez-vous
    </h1> 
    <div class="agencies-listing__thumbnails-wrapper" libScrollEvent (onscroll)="lazyLoadAgencies($event)" [bottomOffset]="50">
        <ng-container *ngFor="let agency of agencies">  
            <div class="row mb-2">
                <div class="agencies-listing__thumbnails-wrapper__thumbnail w-100 border-bottom border-grey-90">
                    <app-agency-thumbnail [agency]="agency" (selected)="showAppointmentSlots($event)"></app-agency-thumbnail>
                </div>   
            </div>    
        </ng-container>
        <div class="text-center py-8" *ngIf="agenciesLoading">
            <div class="spinner-border text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>    
</div>

