import { Subscription } from 'rxjs';

export function AutoUnsub() {
    return function(target) {        
        const orig = target.prototype.ngOnDestroy;
        
        target.prototype.ngOnDestroy = function() {
            Object.getOwnPropertyNames(this).forEach((property) => {
                if (this[property] instanceof Subscription) {
                    this[property].unsubscribe();
                }
            });
            
            if (orig) {
                orig.apply(this);
            }            
        }
    }
}