import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from '../models/page.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  private _apiRoutePath: string = "/pages";

  constructor(private apiService: ApiService) { }

  getPage(id: string): Observable<Page> {
    return this.apiService.get(this._apiRoutePath + '/' + id).pipe(
      map(res => new Page().deserialize(res))
    );
  }
}
