<div class="note overflow-hidden" *ngIf="note || canBeEdited(); else noNote">
    <div class="p-3">
        <ng-container *ngIf="note; else noContent">
            <div [innerHTML]="note.content" (click)="openModal(editNoteModal, 'editNote')"></div>
        </ng-container>
        <ng-template #noContent>
            <p class="text-grey-60 mb-0" (click)="openModal(editNoteModal, 'editNote')">Ecrivez vos propres notes...</p>
        </ng-template>
        <div class="text-right" *ngIf="canBeEdited() || canBeShared()">
            <button type="button" title="Modifier mes notes" class="text-grey-60 d-inline-block align-middle"
                (click)="openModal(editNoteModal, 'editNote')" *ngIf="canBeEdited()">
                <i class="fas fa-pencil-alt"></i>
            </button>
            <button type="button" title="Partager mes notes" class="text-grey-60 d-inline-block align-middle ml-2"
                (click)="openModal(shareNoteModal, 'shareNote')" *ngIf="canBeShared()">
                <i class="fas fa-share-alt"></i>
            </button>
        </div>
    </div>
    <div class="note__comment" *ngIf="enableComment">  
        <ng-container *ngIf="note.noteComment; else noComment">
            <div class="bg-secondary p-3 d-flex align-items-start">
                <div class="flex-fill pr-3 text-white" [innerHTML]="note.noteComment.content" (click)="openModal(editNoteCommentModal, 'editNoteComment')"></div>
                <button type="button" title="Modifier mon commentaire" class="text-white"
                    (click)="openModal(editNoteCommentModal, 'editNoteComment')">
                    <i class="fas fa-pencil-alt"></i>
                </button>
            </div>
        </ng-container>
        <ng-template #noComment>
            <p class="text-secondary mb-0 p-3 font-weight-semi-bold" (click)="openModal(editNoteCommentModal, 'editNoteComment')">
                <i class="far fa-comment"></i>
                Ecrivez un commentaire...
            </p>
        </ng-template>
    </div>
</div>
<ng-template #noNote>
    <p>Vous ne pouvez plus ajouter de notes.</p>
</ng-template>

<ng-template #editNoteModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Modifier mes notes</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="editNoteForm" (ngSubmit)="onEditNoteFormSubmit()">
            <quill-editor placeholder="Ecrivez vos propres notes..." formControlName="content"
                [modules]="noteEditorConfig" class="bordered"></quill-editor>
            <button type="submit" class="btn btn-primary w-100 mt-6">Confirmer</button>
        </form>
    </div>
</ng-template>

<ng-template #shareNoteModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Partager mes notes</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="shareNoteForm" (ngSubmit)="onShareNoteFormSubmit()">
            <div class="py-3">
                <h5>Je sélectionne les destinataires</h5>
                <ng-select [searchable]="false" [clearable]="false" [items]="workStations" placeholder="Destinataires"
                    [loading]="sharingsLoading" [multiple]="true" formArrayName="workStations"
                    loadingText="Chargement..." notFoundText="Aucun résultat trouvé" (scrollToEnd)="loadSharings()" (add)="addSharingWorkStation($event)"
                    (remove)="removeSharingWorkStation($event)"
                    class="ng-select--primary ng-select--center text-left">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <div class="ng-value" *ngFor="let item of items | slice:0:3">
                            <span class="ng-value-label">{{ item.user.getFullName() }}</span>
                            <span class="ng-value-icon" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                        <div class="ng-value" *ngIf="items.length > 3">
                            <span class="ng-value-label">{{items.length - 3}} de plus...</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <span>{{ item.user.getFullName() }}</span>
                    </ng-template>
                </ng-select>
            </div>
            <button type="submit" class="btn btn-primary w-100 mt-6">Partager</button>
        </form>
    </div>
</ng-template>

<ng-template #editNoteCommentModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Mon commentaire</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="editNoteCommentForm" (ngSubmit)="onEditNoteCommentFormSubmit()">
            <quill-editor placeholder="Ecrivez un commentaire" formControlName="content"
                [modules]="noteEditorConfig" class="bordered"></quill-editor>
            <button type="submit" class="btn btn-primary w-100 mt-6">Confirmer</button>
        </form>
    </div>
</ng-template>