import { FormGroup, Validators } from '@angular/forms';
import { finalize, first, take } from 'rxjs/operators';
import { UserInformationRequestType } from '../enums/user-information-request-type.enum';
import { SecretQuestion } from '../models/secret-question.model';
import { SecretQuestionService } from '../services/secret-question.service';
import { BaseFormHandler } from './base.form-handler';

export class UserInformationRequestFormHandler extends BaseFormHandler {

    public secretQuestions: SecretQuestion[] = [];

    constructor(private secretQuestionService: SecretQuestionService) {
        super();
    }

    public init(type: UserInformationRequestType = UserInformationRequestType.USERNAME): void {
        this._form = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            gender: ['', Validators.required],
            birthDate: ['', Validators.required],            
            secretAnswers: this.formBuilder.array([]),
            phone: ['', Validators.required]
        });

        switch (type) {
            case UserInformationRequestType.PHONE_UPDATING:
                this._form.addControl('username', this.formBuilder.control(''));
                break;
            default: break;
        }

        this.getSecretQuestions();
    }

    private getSecretQuestions(): void {
        this.secretQuestionService.getAllSecretQuestion().pipe(
            take(1)
        ).subscribe(res => {
            this.secretQuestions = res;
            this.addSecretQuestions();
        });
    }

    addSecretQuestions(): void {
        const secretAnswersFormArray = this.getFormArray('secretAnswers');
        
        this.secretQuestions.forEach(secretQuestion => {
            secretAnswersFormArray.push(this.formBuilder.control('', Validators.required));
        });
    }

    getUserInformationValue(): any {
        let data = this._form.value;

        data.secretAnswers = this.getSecretAnswersValue();

        return data;
    }

    private getSecretAnswersValue(): any {  
        const secretAnswersFormArray = this.getFormArray('secretAnswers');
        let secretAnswers: any[] = [];
        
        this.secretQuestions.forEach((secretQuestion, index) => {
            secretAnswers.push({
                secretQuestion: secretQuestion.getIRI(),
                answer: secretAnswersFormArray.at(index).value
            })
        });

        return secretAnswers;
    }
}
