import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { genderMapping } from 'src/app/core/enums/gender.enum';
import { UserFormHandler } from 'src/app/core/form-handlers/user.form-handler';
import { SecretAnswer } from 'src/app/core/models/secret-answer.model';
import { User } from 'src/app/core/models/user.model';
import { ErrorService } from 'src/app/core/services/error.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MeService } from 'src/app/core/services/me.service';
import { SecretAnswerService } from 'src/app/core/services/secret-answer.service';
import { SecretQuestionService } from 'src/app/core/services/secret-question.service';
import { UserService } from 'src/app/core/services/user.service';
import { OrderUtil } from 'src/app/shared/utils/order.util';

@Component({
  selector: 'app-edit-my-profile',
  templateUrl: './edit-my-profile.component.html',
  styleUrls: ['./edit-my-profile.component.scss']
})
@AutoUnsub()
export class EditMyProfileComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  public title: string = "Modifier mon profil";
  public me: User;
  public secretAnswers: SecretAnswer[] = [];
  public userProfileFormHandler: UserFormHandler;
  public userPasswordFormHandler: UserFormHandler;
  public profileForm: FormGroup;
  public passwordForm: FormGroup;
  public genderMapping: any = genderMapping;
  public submittedProfileForm: boolean = false;
  public submittedPasswordForm: boolean = false;
  public topBarLeftAction: any = {
    id: 'return',
    data: '/mon-profil'
  };

  constructor(private secretQuestionService: SecretQuestionService, private meService: MeService,
    private localeService: BsLocaleService, public orderUtil: OrderUtil,
    private loadingService: LoadingService, private secretAnswerService:SecretAnswerService,
    private userService: UserService, private errorService: ErrorService,
    private toastrService: ToastrService, private router: Router) { 
    this.userProfileFormHandler = new UserFormHandler(this.secretQuestionService);
    this.userPasswordFormHandler = new UserFormHandler(this.secretQuestionService);
  }

  ngOnInit(): void {
    this.localeService.use('fr');    
    this.prepareProfileForm();
    this.preparePasswordForm();

    this.getUser();
  }

  getUser(): void {
    this.loadingService.showLoading();
    this.me = this.meService.getMe();

    const getUser = this.secretAnswerService.getAllSecretAnswer().pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(res => {
      this.me.secretAnswers = res;
      this.secretAnswers = res;
      this.userProfileFormHandler.update(this.me);
    });

    this._observers.add(getUser);
  }

  prepareProfileForm(): void {
    this.userProfileFormHandler.init();
    this.profileForm = this.userProfileFormHandler.getForm();
  }

  preparePasswordForm(): void {
    this.userPasswordFormHandler.init(UserFormHandler.USER_FORM_TYPE_PASSWORD);
    this.passwordForm = this.userPasswordFormHandler.getForm();
  }

  submitSecretAnswers(): Observable<any> {
    let observables = {};
    let secretAnswers = this.secretAnswers;

    secretAnswers.forEach((secretAnswer, i) => {
      observables[secretAnswer.getIRI()] = this.secretAnswerService.updateSecretAnswer(secretAnswer.getId(), { answer: this.userProfileFormHandler.getFormArray('secretAnswers').at(i).value });
    });

    return forkJoin(observables);
  }

  submitProfileForm(): void {
    this.submittedProfileForm = true;

    if (this.profileForm.valid) {
      this.loadingService.showLoading();

      const editProfile = this.userService.updateUser(this.me.getId(), this.userProfileFormHandler.getUserValue()).pipe(
        switchMap(res => {
          if (this.me.phone.replace(/\s+/g, '') != res.phone.replace(/\s+/g, '')) {
            this.toastrService.info('Un sms vient de vous être envoyé pour vérifier votre nouveau numéro de téléphone et valider votre compte.', 'Validation');
          }
  
          this.me = res;
          this.meService.storeMe(res);

          return this.submitSecretAnswers(); // TEMPORARY
        }),
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {  
        this.router.navigate(['/mon-profil']);

        this.toastrService.success('Profil modifié avec succès.', 'Félicitations !');
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });
  
      this._observers.add(editProfile);
    }
  }

  submitPasswordForm(): void {
    this.submittedPasswordForm = true;
    if (this.passwordForm.valid) {
      this.loadingService.showLoading();

      const editPassword = this.userService.updateUserPassword(this.me.getId(), this.userPasswordFormHandler.getUserValue()).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        this.router.navigate(['/mon-profil']);
        this.toastrService.success('Mot de passe modifié avec succès.', 'Félicitations !');
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });
  
      this._observers.add(editPassword);
    }
  }

}
