import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { appointmentSlotSeconds } from 'src/app/core/enums/appointment-slot.enum';
import { Gender } from 'src/app/core/enums/gender.enum';
import { MakingAppointmentStep } from 'src/app/core/enums/making-appointment-step.enum';
import { Agency } from 'src/app/core/models/agency.model';
import { AppointmentSlot } from 'src/app/core/models/appointment-slot.model';
import { Partner } from 'src/app/core/models/partner.model';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MakingAppointmentService } from 'src/app/core/services/making-appointment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { MeService } from 'src/app/core/services/me.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-making-appointment-confirmation',
  templateUrl: './making-appointment-confirmation.component.html',
  styleUrls: ['./making-appointment-confirmation.component.scss']
})
@AutoUnsub()
export class MakingAppointmentConfirmationComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  public partner: Partner;
  public agency: Agency;
  public byPhone: boolean = false;
  public appointmentSlot: AppointmentSlot;
  public gender = Gender;
  public appointmentSlotSeconds = appointmentSlotSeconds;
  public status: string = MakingAppointmentService.statusInProgress;
  public makingAppointmentServiceClass = MakingAppointmentService;

  constructor(private makingAppointmentService: MakingAppointmentService, private router: Router,
    private appointmentService: AppointmentService, private loadingService: LoadingService,
    private errorService: ErrorService, private authService: AuthService,
    private me: MeService) { }

  ngOnInit(): void {
    this.partner = this.makingAppointmentService.getPartner();
    this.agency = this.makingAppointmentService.getAgency();
    this.byPhone = this.makingAppointmentService.getByPhone();

    // Set appointment slot
    let endAt = new Date(this.makingAppointmentService.getBeginAt().getTime());
    endAt.setSeconds(this.makingAppointmentService.getAvailability().beginAt.getSeconds() + this.appointmentSlotSeconds[this.makingAppointmentService.getAvailability().slot]);
    this.appointmentSlot = new AppointmentSlot().deserialize({
      availability: this.makingAppointmentService.getAvailability(),
      beginAt: this.makingAppointmentService.getBeginAt(),
      endAt: endAt
    });
  }

  goToPreviousStep(): void {
    this.makingAppointmentService.setAvailability(null);
    this.makingAppointmentService.setBeginAt(null);
    this.router.navigate(['/prise-de-rendez-vous'], {
      queryParams: this.makingAppointmentService.getQueryParams(MakingAppointmentStep.AVAILABILITY)
    });
  }

  getAppointmentData(): any {
    return {
      availability: this.appointmentSlot.availability.getIRI(),
      beginAt: moment(this.appointmentSlot.beginAt).format(),
      endAt: moment(this.appointmentSlot.endAt).format(),
      user: this.me.getMe().getIRI()
    }
  }

  createAppointment(): void {
    this.loadingService.showLoading();

    const createAppointment = this.appointmentService.createAppointment(this.getAppointmentData()).pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(res => {
      this.status = MakingAppointmentService.statusConfirmed;
      this.makingAppointmentService.removeAppointmentSearch();
    }, err => {
      this.errorService.addError(err);
      this.errorService.show();
    });

    this._observers.add(createAppointment);
  }

  confirmAppointment(): void {
    const confirmAppointment = this.authService.isAuthenticated().subscribe(authenticated => {
      if (authenticated) {
        this.createAppointment();
      } else {       
        this.status = MakingAppointmentService.statusNotAuthenticated;
        this.makingAppointmentService.saveAppointmentSearch();
      }
    });

    this._observers.add(confirmAppointment);
  }

  cancelMakingAppointment(): void {
    this.makingAppointmentService.removeAppointmentSearch();
    this.router.navigate(['/']);
  }

}
