import { Injectable } from '@angular/core';
import { MakingAppointmentStep, makingAppointmentStepMapping } from '../enums/making-appointment-step.enum';
import { Agency } from '../models/agency.model';
import { Availability } from '../models/availability.model';
import { Partner } from '../models/partner.model';

@Injectable({
  providedIn: 'root'
})
export class MakingAppointmentService {
  
  static readonly statusInProgress: string = 'status_in_progress';
  static readonly statusNotAuthenticated: string = 'status_not_authenticated';
  static readonly statusConfirmed: string = 'status_confirmed';
  static readonly progress: MakingAppointmentStep[] = [
    MakingAppointmentStep.PARTNER, 
    MakingAppointmentStep.AGENCY,
    MakingAppointmentStep.AVAILABILITY,
    MakingAppointmentStep.CONFIRMATION
  ];
  static readonly byPhoneFilter: string = 'byPhone';
  static readonly searchFilter: string = 'search';
  static readonly beginAtFilter: string = 'beginAt';
  private _storageKey: string = 'appointment_search';
  private _localStorage =  window.localStorage;
  private _search: string = "";
  private _partner: Partner = null;
  private _agency: Agency = null; 
  private _availability: Availability = null; 
  private _beginAt: Date = null; 
  private _byPhone: boolean = false; 
  private _currentStep: MakingAppointmentStep = MakingAppointmentService.progress[0];
  public makingAppointmentStepMapping = makingAppointmentStepMapping;
  
  constructor() { }

  setSearch(search: string): void {
    this._search = (search) ? search : "";
  }

  getSearch(): string {
    return this._search;
  }

  setPartner(partner: Partner|null): void {
    this._partner = partner;
  }

  getPartner(): Partner|null {
    return this._partner;
  }

  setAgency(agency: Agency|null): void {
    this._agency = agency;
  }

  getAgency(): Agency|null {
    return this._agency;
  }

  setAvailability(availability: Availability|null): void {
    this._availability = availability;
  }

  getAvailability(): Availability|null {
    return this._availability;
  }

  setBeginAt(beginAt: Date|null): void {
    this._beginAt = beginAt;
  }

  getBeginAt(): Date|null {
    return this._beginAt;
  }

  setByPhone(byPhone: boolean): void {
    this._byPhone = byPhone;
  }

  getByPhone(): boolean {
    return this._byPhone;
  }

  resetData(): void {
    this._search = "";
    this._partner = null;
    this._agency = null;
    this._availability = null;
    this._beginAt = null;
    this._byPhone = false;
    this._currentStep = MakingAppointmentService.progress[0];
  }

  resetCurrentStep(): void {
    this._currentStep = MakingAppointmentService.progress[0];
  }

  setCurrentStep(makingAppointmentStep: MakingAppointmentStep): void {
    this._currentStep = makingAppointmentStep;
  }

  getCurrentStep(): MakingAppointmentStep {
    return this._currentStep;
  }

  getQueryParams(makingAppointmentStep: MakingAppointmentStep): any {
    let params = {};

    if (this._search) {
      params[MakingAppointmentService.searchFilter] = this._search;
    }

    switch (makingAppointmentStep) {  
      case MakingAppointmentStep.AGENCY:
        if (this._partner) {
          params[this.makingAppointmentStepMapping[MakingAppointmentStep.PARTNER]] = this._partner.getId();   
        }
        break;
      case MakingAppointmentStep.AVAILABILITY:
        if (this._byPhone) {
          params[MakingAppointmentService.byPhoneFilter] = this._byPhone;
        }
        if (this._partner) {
          params[this.makingAppointmentStepMapping[MakingAppointmentStep.PARTNER]] = this._partner.getId();   
        }
        if (this._agency) {
          params[this.makingAppointmentStepMapping[MakingAppointmentStep.AGENCY]] = this._agency.getId();  
        }
        break;
      case MakingAppointmentStep.CONFIRMATION:
        if (this._byPhone) {
          params[MakingAppointmentService.byPhoneFilter] = this._byPhone;
        }
        if (this._partner) {
          params[this.makingAppointmentStepMapping[MakingAppointmentStep.PARTNER]] = this._partner.getId();   
        }
        if (this._agency) {
          params[this.makingAppointmentStepMapping[MakingAppointmentStep.AGENCY]] = this._agency.getId();  
        }
        if (this._availability) {
          params[this.makingAppointmentStepMapping[MakingAppointmentStep.AVAILABILITY]] = this._availability.getId();  
        }
        if (this._beginAt) {
          params[MakingAppointmentService.beginAtFilter] = this._beginAt.toISOString();
        }
        break;    
      default: break;
    }

    return params;
  }

  getPrevStep(makingAppointmentStep: MakingAppointmentStep): MakingAppointmentStep {
    let index = MakingAppointmentService.progress.indexOf(makingAppointmentStep);

    return MakingAppointmentService.progress[(index > 0) ? (index - 1) : 0];
  } 

  getNextStep(makingAppointmentStep: MakingAppointmentStep): MakingAppointmentStep {
    let index = MakingAppointmentService.progress.indexOf(makingAppointmentStep);

    return MakingAppointmentService.progress[(index + 1 < MakingAppointmentService.progress.length) ? (index + 1) : index];
  }
  
  canConfirm(): boolean {
    return (this._partner && (this._agency || this._byPhone) && this._availability && this._beginAt) ? true : false;
  }

  saveAppointmentSearch(): void {
    this._localStorage.setItem(this._storageKey, JSON.stringify(this.getQueryParams(MakingAppointmentStep.CONFIRMATION)));
  }

  getAppointmentSearch(): any {
    return JSON.parse(this._localStorage.getItem(this._storageKey));
  }

  removeAppointmentSearch(): void {
    if (this._localStorage.getItem(this._storageKey)) {
      this._localStorage.removeItem(this._storageKey);
    }
  }
}
