import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MakingAppointmentStep } from 'src/app/core/enums/making-appointment-step.enum';
import { MakingAppointmentService } from 'src/app/core/services/making-appointment.service';

@Component({
  selector: 'app-making-appointment-search',
  templateUrl: './making-appointment-search.component.html',
  styleUrls: ['./making-appointment-search.component.scss']
})
export class MakingAppointmentSearchComponent implements OnInit {

  @Input() public focusSearchElement: boolean = false;
  @Output() public submittedSearch: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('search') searchElement: ElementRef;
  public makingAppointmentSearchForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private router: Router,
    private makingAppointmentService: MakingAppointmentService) { }

  ngOnChanges(changes: SimpleChanges): void {
      if (this.focusSearchElement) {
        this.searchElement.nativeElement.focus();
      }
  }

  ngOnInit(): void {
    this.prepareMakingAppointmentSearchForm();
  }

  prepareMakingAppointmentSearchForm(): void {
    this.makingAppointmentSearchForm = this.formBuilder.group({
      search: ['']
    });
  }

  submitMakingAppointmentSearchForm(): void {
    this.makingAppointmentService.resetData();
    this.makingAppointmentService.setSearch(this.makingAppointmentSearchForm.get('search').value);
    this.router.navigate(['/prise-de-rendez-vous'], {
      queryParams: this.makingAppointmentService.getQueryParams(MakingAppointmentStep.PARTNER)
    });
    this.submittedSearch.emit(true);
  }

}
