import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountValidationRequestSuccessComponent } from './account-validation-request-success/account-validation-request-success.component';
import { AccountValidationRequestComponent } from './account-validation-request/account-validation-request.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { AnonymousGuard } from './guards/anonymous.guard';
import { AuthGuard } from './guards/auth.guard';
import { ResetPasswordGuard } from './guards/reset-password.guard';
import { StaticPageGuard } from './guards/static-page.guard';
import { HelpAuthenticationComponent } from './help-authentication/help-authentication.component';
import { HomeComponent } from './home/home.component';
import { InformationRequestSuccessComponent } from './information-request-success/information-request-success.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PasswordRequestSuccessComponent } from './password-request-success/password-request-success.component';
import { PasswordRequestComponent } from './password-request/password-request.component';
import { PhoneUpdatingRequestComponent } from './phone-updating-request/phone-updating-request.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { RegistrationComponent } from './registration/registration.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { StaticPageComponent } from './static-page/static-page.component';
import { UsernameRequestComponent } from './username-request/username-request.component';

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [AnonymousGuard],
    runGuardsAndResolvers: 'always',
    data: {
      isFullscreen: true
    }
  },
  {
    path: "login",
    component: AuthenticationComponent,
    canActivate: [AnonymousGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "inscription",
    component: RegistrationComponent,
    canActivate: [AnonymousGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "inscription-terminee",
    component: RegistrationSuccessComponent,
    canActivate: [AnonymousGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "mot-de-passe-oublie",
    component: PasswordRequestComponent,
    canActivate: [AnonymousGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "identifiant-oublie",
    component: UsernameRequestComponent,
    canActivate: [AnonymousGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "numero-de-telephone-modifie",
    component: PhoneUpdatingRequestComponent,
    canActivate: [AnonymousGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "demande-envoyee",
    component: InformationRequestSuccessComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: "lien-recuperation-mot-de-passe-envoye",
    component: PasswordRequestSuccessComponent,
    canActivate: [AnonymousGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "validation-compte-expiree",
    component: AccountValidationRequestComponent,
    canActivate: [AnonymousGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "lien-validation-compte-envoye",
    component: AccountValidationRequestSuccessComponent,
    canActivate: [AnonymousGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "aide-connexion",
    component: HelpAuthenticationComponent,
    canActivate: [AnonymousGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "nouveau-mot-de-passe/:id",
    component: ResetPasswordComponent,
    canActivate: [AnonymousGuard, ResetPasswordGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "rendez-vous",
    loadChildren: '../appointments/appointments.module#AppointmentsModule',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      bodyClass: 'top-bar'
    }
  },
  {
    path: "prise-de-rendez-vous",
    loadChildren: '../making-appointment/making-appointment.module#MakingAppointmentModule',
    data: {
      bodyClass: 'bg-grey-95'
    }
  },
  {
    path: "recap-des-notes",
    loadChildren: '../notes-summary/notes-summary.module#NotesSummaryModule',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      bodyClass: 'top-bar'
    }
  },
  {
    path: "mes-documents",
    loadChildren: '../documents/documents.module#DocumentsModule',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      bodyClass: 'top-bar'
    }
  },
  {
    path: "mon-profil",
    loadChildren: '../account/account.module#AccountModule',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      bodyClass: 'top-bar'
    }
  },
  {
    path: ":id",
    component: StaticPageComponent,
    canActivate: [StaticPageGuard],
    runGuardsAndResolvers: 'always',
    data: {
      bodyClass: 'top-bar'
    }
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
