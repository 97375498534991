<div class="modal-header">
    <h4>Partager un de mes rendez-vous</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="sharingForm" (ngSubmit)="onSharingFormSubmit()">
        <div class="row" *ngIf="canSelectAppointment">
            <div class="col-12 py-3">
                <h5>Je sélectionne un rendez-vous</h5>
                <ng-select [searchable]="false" [clearable]="false" [items]="appointments" placeholder="Mes rendez-vous"
                    [loading]="appointmentsLoading" formControlName="appointment" loadingText="Chargement..."
                    notFoundText="Aucun résultat trouvé" (scrollToEnd)="loadAppointments()"
                    class="ng-select--primary ng-select--center text-left">
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div class="d-flex align-items-center">
                            <div class="img-wrapper rounded mr-2"
                                [style]="{ 'background-image': 'url(' + item.getPartner().getLogoPath() + ')' }">
                                <img src="assets/img/placeholders/landing-thumbnail.png" class="img-fluid"
                                    alt="item.getPartner().name" />
                            </div>
                            <span>{{ item.getFullDate() }}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        <div class="d-flex align-items-center">
                            <div class="img-wrapper rounded mr-2"
                                [style]="{ 'background-image': 'url(' + item.getPartner().getLogoPath() + ')' }">
                                <img src="assets/img/placeholders/landing-thumbnail.png" class="img-fluid"
                                    alt="item.getPartner().name" />
                            </div>
                            <span>{{ item.getFullDate() }}</span>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <ng-container *ngIf="appointment">
            <div class="row">
                <div class="appointment-thumbnail-wrapper w-100">
                    <app-appointment-thumbnail [appointment]="appointment" [config]="{ linkable: false, menu: false }">
                    </app-appointment-thumbnail>
                </div>
            </div>
            <div class="row">
                <div class="col-12 py-3">
                    <h5>Je sélectionne les destinataires</h5>
                    <ng-select [searchable]="false" [clearable]="false" [items]="workStations"
                        placeholder="Destinataires" [loading]="workStationsLoading" [multiple]="true"
                        formControlName="workStations" loadingText="Chargement..." notFoundText="Aucun résultat trouvé"
                        (scrollToEnd)="loadWorkStations()" class="ng-select--primary ng-select--center text-left">
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value" *ngFor="let item of items | slice:0:3">
                                <span class="ng-value-label">{{ item.user.getFullName() }}</span>
                                <span class="ng-value-icon" (click)="clear(item)" aria-hidden="true">×</span>
                            </div>
                            <div class="ng-value" *ngIf="items.length > 3">
                                <span class="ng-value-label">{{items.length - 3}} de plus...</span>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <span>{{ item.user.getFullName() }}</span>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button type="submit" class="btn btn-primary w-100 mt-6">Partager</button>
                </div>
            </div>
        </ng-container>
    </form>
</div>