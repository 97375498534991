import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserInformationRequestType } from '../enums/user-information-request-type.enum';
import { ErrorService } from '../services/error.service';
import { LoadingService } from '../services/loading.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-account-validation-request',
  templateUrl: './account-validation-request.component.html',
  styleUrls: ['./account-validation-request.component.scss']
})
export class AccountValidationRequestComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  public accountValidationRequestForm: FormGroup;
  public submittedAccountValidationRequestForm: boolean = false;

  constructor(private formBuilder: FormBuilder, private userService: UserService,
    private loadingService: LoadingService, private errorService: ErrorService,
    private router: Router) { }

  ngOnInit(): void {
    this.prepareAccountValidationRequestForm();
  }

  prepareAccountValidationRequestForm(): void {
    this.accountValidationRequestForm = this.formBuilder.group({
      username: ['', Validators.required],
    });
  }

  submitAccountValidationRequestForm(): void {
    this.submittedAccountValidationRequestForm = true;

    if (this.accountValidationRequestForm.valid) {
      this.loadingService.showLoading();

      const accountValidationRequest = this.userService.createInformationRequest(UserInformationRequestType.ACCOUNT_VALIDATION, this.accountValidationRequestForm.value).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        this.router.navigate(['/', 'lien-validation-compte-envoye']);
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(accountValidationRequest);
    }
  }

}
