import { Serializable } from '../interfaces/serializable';
import { Address } from './address.model';
import { PartnerImage } from './partner-image.model';

export class Partner implements Serializable {

    static readonly NO_IMAGE_URL = 'assets/img/no-image.png';

    private _uuid: string = null;
    public name: string = "";
    public slug: string = "";
    public phone: string = null;
    public email: string = null;
    public website: string = null;
    public shortDescription: string = "";
    public description: string = "";
    public logo: PartnerImage = null;
    public hasAvailabilities: boolean = false;
    public address: Address = null;
    public createdAt: Date = null;
    public updatedAt: Date = null;

    public getId(): string {
        return this.slug;
    }

    public getLogoPath(): string|ArrayBuffer {
        return this.logo ? this.logo.getAbsoluteFilePath() : Partner.NO_IMAGE_URL;
    }

    public getIRI(): string {
        return '/api/partners/' + this.getId();
    }

    public getWebsiteUrl(): string {
        return 'https://' + this.website;
    }

    public update(data: any = {}): this {
        if (!data) {
            return null;
        }

        const properties = Object.getOwnPropertyNames(this);
        Object.keys(data).forEach((key) => {
            switch (key) {
                case '_uuid': break;                
                default:
                    if (properties.includes(key)) {
                        this[key] = data[key];
                    }
                    break;
            }
        });

        return this;
    }

    public deserialize(input: any = {}): this {
        if (!input) {
            return null;
        }

        const properties = Object.getOwnPropertyNames(this);
        Object.keys(input).forEach((key) => {
            if (input[key] !== null) {
                switch (key) {
                    case 'uuid':
                        this._uuid = input[key];
                        break;
                    case 'address':
                        this[key] = new Address().deserialize(input[key]);
                        break;
                    case 'logo':
                        this[key] = new PartnerImage().deserialize(input[key]);
                        break;
                    case 'createdAt':
                    case 'updatedAt':
                        this[key] = new Date(input[key]);
                        break;                    
                    default:
                        if (properties.includes(key)) {
                            this[key] = input[key];
                        }
                        break;
                }
            }
        });

        return this;
    }

    public serialize(): any {
        var output = {};

        Object.getOwnPropertyNames(this).forEach(property => {
            switch (property) {
                case '_uuid':
                    if (this[property]) {
                        output['uuid'] = this[property];
                    }
                    break;                
                default:
                    output[property] = this[property];
                    break;
            }
        });

        return output;
    }
}
