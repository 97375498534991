<div class="partners-listing">
    <h1 class="mb-4" [ngPlural]="totalPartners">
        <span class="text-secondary font-weight-bold mr-2">{{ totalPartners }}</span>
        <ng-template ngPluralCase="=0">partenaire trouvé</ng-template>
        <ng-template ngPluralCase="=1">partenaire trouvé</ng-template>
        <ng-template ngPluralCase="other">partenaires trouvés</ng-template>
    </h1> 
    <div class="partners-listing__thumbnails-wrapper" libScrollEvent (onscroll)="lazyLoadPartners($event)" [bottomOffset]="50">
        <ng-container *ngFor="let partner of partners">  
            <div class="row mb-2">
                <div class="partners-listing__thumbnails-wrapper__thumbnail w-100 border-bottom border-grey-90">
                    <app-partner-thumbnail [partner]="partner" (selected)="makeAppointment($event)"></app-partner-thumbnail>
                </div>   
            </div>    
        </ng-container>
        <div class="text-center py-8" *ngIf="partnersLoading">
            <div class="spinner-border text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>

