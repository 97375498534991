import { AfterViewChecked, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appFloatLabel]'
})
export class FloatLabelDirective implements AfterViewChecked {

  private _floatingClass: string = "float-label";
  private _isFocus: boolean = false;

  constructor(private el: ElementRef, private render: Renderer2) {
  }

  ngAfterViewChecked(): void {
    this.floatLabel();
  }

  @HostListener('focus') onFocus() {
    this._isFocus = true;
    this.render.addClass(this.el.nativeElement, this._floatingClass);
  }

  @HostListener('blur') onBlur() {
    this._isFocus = false;
    this.floatLabel();
  }

  private floatLabel() {
    const value = this.el.nativeElement.value;

    (value != "" || this._isFocus) ? this.render.addClass(this.el.nativeElement, this._floatingClass) : this.render.removeClass(this.el.nativeElement, this._floatingClass);
  }

}
