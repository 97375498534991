import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MakingAppointmentStep } from 'src/app/core/enums/making-appointment-step.enum';
import { Agency } from 'src/app/core/models/agency.model';
import { MakingAppointmentService } from 'src/app/core/services/making-appointment.service';

@Component({
  selector: 'app-agency-thumbnail',
  templateUrl: './agency-thumbnail.component.html',
  styleUrls: ['./agency-thumbnail.component.scss']
})
export class AgencyThumbnailComponent implements OnInit {

  @Input() public agency: Agency;
  @Input() public configuration: any = {
    canSelect: true
  };
  @Output() public selected: EventEmitter<Agency> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  select(): void {
    this.selected.emit(this.agency);
  }

}
