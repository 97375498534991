<header class="header" #topBar [ngClass]="{'swiped': swipedHeader}">
    <nav class="navbar flex-nowrap align-items-center">
        <ng-container [ngSwitch]="leftAction.id">
            <a [routerLink]="leftAction.data" *ngSwitchCase="'return'" class="fs-4" title="Retour">
                <i class="fas fa-arrow-left"></i>
            </a>
            <button *ngSwitchDefault type="button" class="fs-4" (click)="triggerDefaultLeftAction()">
                <i class="fas fa-bars" [ngClass]="{ 'fa-bars': me, 'fa-arrow-left': !me }"></i>
            </button>
        </ng-container>        
        <h1 class="navbar-title mb-0 px-2 text-center">
            {{ title }}
        </h1>
        <ng-container [ngSwitch]="rightAction.id">
            <button *ngSwitchCase="'cancel'" type="button" class="fs-4" title="Annuler" (click)="triggerRightAction()">
                <i class="fas fa-ban"></i>
            </button>
            <button *ngSwitchCase="'share'" class="fs-4" title="Partager" (click)="triggerRightAction()">
                <i class="fas fa-share-alt"></i>
            </button>
            <button *ngSwitchCase="'edit'" class="fs-4" title="Modifier" (click)="triggerRightAction()">
                <i class="fas fa-pencil-alt"></i>
            </button>
            <span *ngSwitchDefault aria-hidden="true"></span>
        </ng-container>
    </nav>
</header>
<aside class="sidebar py-3" [ngClass]="{'active': sidebarIsActive}" *ngIf="me">
    <button type="button" class="btn-close" (click)="closeMenu()">
        <i class="fas fa-times"></i>
    </button>
    <div class="d-flex flex-wrap h-100">
        <div class="align-self-start w-100">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <a class="link" [routerLink]="['/', 'mon-profil']">
                            <span class="text-capitalize mr-1">{{ me.firstName }}</span> <span class="text-uppercase">{{ me.lastName }}</span>
                            <span class="d-block fs-1 text-grey-35">Voir mon profil</span>
                        </a>                        
                    </div>                    
                </div> 
                <div class="row mt-3">
                    <div class="col-12 p-0">
                        <ul class="sidebar-nav list-unstyled">
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/', 'rendez-vous']" routerLinkActive="active">
                                    <i class="far fa-calendar-alt"></i>
                                    Mes rendez-vous
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/', 'recap-des-notes']" routerLinkActive="active">
                                    <i class="far fa-edit"></i>
                                    Recap' des notes
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/', 'mes-documents']" routerLinkActive="active">
                                    <i class="far fa-file"></i>
                                    Mes documents
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div> 
        <div class="align-self-end w-100 border-top border-grey-85">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 p-0">
                        <ul class="sidebar-nav list-unstyled mb-0">
                            <li class="nav-item">
                                <button type="button" class="nav-link text-danger" (click)="logout()">
                                    Se déconnecter
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 py-4 border-top border-grey-85">
                        <ul class="sidebar-nav small list-unstyled mb-0">
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/', 'politique-de-confidentialite']">Politique de confidentialité</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/', 'mentions-legales']">Mentions légales</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/', 'conditions-generales-d-utilisation']">CGU</a>
                            </li>
                        </ul>
                    </div>
                </div>            
                <div class="row align-items-center">
                    <div class="col-3">
                        <img src="../assets/img/logo-clicnote.svg" class="img-fluid" />
                    </div>
                    <div class="col-9 fs-1 pl-0 text-dark-grey">
                        &copy; Tous droits réservés {{ today| date:'yyyy' }}
                    </div>
                </div> 
            </div>
        </div>
    </div>
</aside>
<div class="sidebar-backdrop"></div>