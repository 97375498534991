import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PasswordRequestService } from '../services/password-request.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordGuard implements CanActivate {

  constructor(private passwordRequestService: PasswordRequestService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!route.params.id) {
        return of(false);
      }

      return this.passwordRequestService.getPasswordRequest(route.params.id).pipe(
        switchMap(response => {
          if (!response.isValid()) {
            return throwError("Password request invalid.");
          }

          return of(true);
        }),
        catchError(error => {
          this.router.navigate(['/login']);
          return of(false);
        })
      );
  }
  
}
