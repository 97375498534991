<app-top-bar [title]="title" [rightAction]="topBarRightAction" (clickedRightAction)="openSharingNotesSummaryModal()"></app-top-bar>
<div class="my-notes-summary py-3">
    <div class="container-fluid" libScrollEvent (onscroll)="lazyLoadNotes($event)" [bottomOffset]="50">
        <ng-container *ngIf="notes.length > 0; else noNotes">
            <ng-container *ngFor="let note of notes; let i = index">
                <h2 class="mb-3" [ngClass]="{ 'mt-6': i > 0 }" *ngIf="isOtherDay(i)">{{ note.appointment.beginAt|momentFormat:"dddd"|slice:0:1|uppercase }}{{ note.appointment.beginAt|momentFormat:"dddd"|slice:1 }} {{ note.appointment.beginAt|momentFormat:"DD MMMM" }}</h2>
                <div class="row mb-3">
                    <div class="col-12 my-notes-summary__note">
                        <div class="font-weight-semi-bold text-grey-35 mb-2 d-flex align-items-center">
                            {{ note.appointment.beginAt|momentFormat:"HH" }}h{{ note.appointment.beginAt|momentFormat:"mm" }},
                            <div class="p-1 bg-white rounded overflow-hidden border border-92 mx-2">
                                <div class="img-wrapper" [style]="{ 'background-image': 'url(' + note.appointment.getPartner().getLogoPath() + ')' }">
                                    <img src="assets/img/placeholders/square-thumbnail.png" class="img-fluid" alt="{{ note.appointment.getPartner().name }}" />            
                                </div>
                            </div>
                            <span>{{ note.appointment.getPartner().name }}</span>
                        </div>
                        <app-note [note]="note" [isShareable]="false" [isEditable]="false" [enableComment]="true"></app-note>
                    </div>
                </div>            
            </ng-container>
        </ng-container>
        <ng-template #noNotes>
            <p *ngIf="!notesLoading">Aucune note trouvée.</p>
        </ng-template>
        <div class="text-center py-8" *ngIf="notesLoading">
            <div class="spinner-border text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div> 
</div>