import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutoUnsub } from '../decorators/auto-unsub.decorator';
import { AuthService } from '../services/auth.service';
import { ErrorService } from '../services/error.service';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
@AutoUnsub()
export class AuthenticationComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  public authenticationForm: FormGroup;
  public submittedAuthenticationForm: boolean = false;
  public hasAccountValidationRequest: boolean = false;
  private queryParams: any;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, 
    private loadingService: LoadingService, private errorService: ErrorService,
    private router: Router, private route: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.prepareAuthenticationForm();
    this.checkHasAccountValidationRequest();
  }

  checkHasAccountValidationRequest(): void {
    const getQueryParams = this.route.queryParams.subscribe(params => {
      this.queryParams = params;
      this.hasAccountValidationRequest = params._hash && params.token && params.expires;
    });

    this._observers.add(getQueryParams);
  }

  prepareAuthenticationForm(): void {
    this.authenticationForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  submitAuthenticationForm(): void {
    this.submittedAuthenticationForm = true;

    if (this.authenticationForm.valid) { 
      this.loadingService.showLoading();  

      const authenticate =  this.authService.authenticate(this.authenticationForm.value, this.queryParams).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        if (this.hasAccountValidationRequest) {
          this.toastr.success('Votre compte est bien validé', 'Félicitations !');
        }
        this.router.navigate(['/', 'rendez-vous']);
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });
      
      this._observers.add(authenticate);      
    }
  }

}
