import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { matchingValidator } from 'src/app/shared/directives/matching-validator.directive';
import { AutoUnsub } from '../decorators/auto-unsub.decorator';
import { PasswordRequest } from '../models/password-request.model';
import { ErrorService } from '../services/error.service';
import { LoadingService } from '../services/loading.service';
import { PasswordRequestService } from '../services/password-request.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
@AutoUnsub()
export class ResetPasswordComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  public resetPasswordForm: FormGroup;
  public submittedResetPasswordForm: boolean = false;
  public passwordRequest: PasswordRequest;

  constructor(private formBuilder: FormBuilder, private loadingService: LoadingService, 
    private errorService: ErrorService, private router: Router,
    private passwordRequestService: PasswordRequestService, private route: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.prepareResetPasswordForm();
  }

  prepareResetPasswordForm(): void {
    this.resetPasswordForm = this.formBuilder.group({
      username: ['', Validators.required],
      plainPassword: ['', [Validators.required, Validators.minLength(6), matchingValidator('confirmPlainPassword', true)]],
      confirmPlainPassword: ['', [Validators.required, matchingValidator('plainPassword')]]
    });
  }

  submitResetPasswordForm(): void {
    this.submittedResetPasswordForm = true;

    if (this.resetPasswordForm.valid) {
      this.loadingService.showLoading();

      const resetPassword = this.passwordRequestService.resetPassword(this.route.snapshot.params.id, this.resetPasswordForm.value).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        this.toastr.success("Mot de passe modifié avec succès.", "Félicitations !");
        this.router.navigate(['/login']);
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(resetPassword);    
    }
  }

}
