import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { AppointmentSlot } from '../models/appointment-slot.model';

@Injectable({
  providedIn: 'root'
})
export class AppointmentSlotService {

  private _apiRoutePath: string = "/appointment-slots";

  constructor(private apiService: ApiService) { }

  getAllAppointmentSlots(page: number = 1, params: any = {}): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Accept': 'application/ld+json'
      }),
      params: {
        page: page
      }
    };

    options.params = Object.assign(options.params, params);

    return this.apiService.get(this._apiRoutePath, options).pipe(
      map(res => {
        return {
          totalItems: res['hydra:totalItems'],
          items: res['hydra:member'].map(item => new AppointmentSlot().deserialize(item))
        };
      })
    );
  }
}
