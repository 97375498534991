<app-top-bar [title]="title"></app-top-bar>
<div class="my-documents pb-10">
    <div #myDocumentsSelection class="my-documents__selection container-fluid position-fixed w-100 py-2 bg-success text-white fs-2" *ngIf="documentService.hasSelection()">
        <div class="row align-items-center">
            <div class="col-1 font-weight-bold fs-4 pr-0">
                {{ documentService.getNbSelection() }}
            </div>
            <div class="col-11 d-flex align-items-center justify-content-end">
                <ng-container *ngIf="!searchInProgress">
                    <button type="button" class="d-flex align-items-center text-white" (click)="moveHere()" title="Déplacer ici">
                        <i class="mlp-font mlp-folder-move fs-6 mr-1"></i> Déplacer ici
                    </button>
                    <span class="mx-3">|</span>
                </ng-container>                
                <button type="button" class="d-flex align-items-center text-white" (click)="shareDocuments()" title="Partager par email">
                    <i class="fas fa-share fs-4 mr-1 "></i> Partager
                </button>
                <span class="mx-3">|</span>
                <button type="button" class="d-flex text-white" (click)="documentService.emptySelection()" title="Annuler la sélection">
                    <i class="far fa-times-circle fs-4"></i>
                </button>
            </div>
        </div>
    </div>
    <div #myDocumentsContainer class="container-fluid">
        <ng-container *ngIf="folder; else noRootFolder">
            <div class="row">
                <div class="col-12 py-2 bg-grey-90 fs-2">
                    <ng-container *ngIf="searchInProgress; else breadcrumb">
                        Recherche
                    </ng-container>
                    <ng-template #breadcrumb>
                        <ng-container *ngIf="folder.parent">
                            <span *ngIf="folder.parent.parent">/..</span>
                            <span *ngIf="folder.parent">
                                <span *ngIf="folder.parent.title.length > 15; else folderParentTitle">/{{
                                    folder.parent.title|slice:0:15 }}...</span>
                                <ng-template #folderParentTitle><span>/{{ folder.parent.title }}</span></ng-template>
                            </span>
                        </ng-container>
                        <span *ngIf="folder.title.length > 15; else folderTitle">/{{ folder.title|slice:0:15 }}...</span>
                        <ng-template #folderTitle><span>/{{ folder.title }}</span></ng-template>
                    </ng-template>                    
                </div>
            </div>
            <div class="row mt-2" *ngIf="!searchInProgress">
                <div class="col-12 text-right">
                    <input type="file" #file ng2FileSelect [uploader]="uploader" multiple class="d-none" />
                    <button type="button" class="text-secondary" title="Nouveau dossier" (click)="showNewFolder = true">
                        <i class="fas fa-folder-plus"></i> Nouveau dossier
                    </button> 
                    <button type="button" class="ml-3 text-secondary" title="Nouveau fichier" (click)="file.click()">
                        <i class="fas fa-file-upload"></i> Nouveau fichier
                    </button>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="my-documents__search">
                        <form [formGroup]="searchForm" (ngSubmit)="submitSearchForm()">
                            <h2 class="">Recherche</h2>
                            <div class="input-group btn-search">
                                <input type="text" #search class="form-control" placeholder="Votre recherche..."
                                    aria-label="Votre recherche..." aria-describedby="btn-search-addon"
                                    formControlName="search">
                                <button class="btn btn-dark-grey fs-5" type="submit" id="btn-search-addon">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="row mt-4" *ngIf="searchInProgress; else folderContent">
                <div class="col-12">
                    <div class="my-documents__search-elements">
                        <div class="row" *ngIf="folder.parent">
                            <div class="col-12">
                                <button type="button" (click)="cancelSearch()">
                                    <div class="d-flex align-items-center py-2">
                                        <i class="far fa-times-circle fs-5 mr-2"></i>
                                        <span>Annuler la recherche</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="my-documents__search-elements__list">
                                    <ng-container *ngIf="searchElements.length > 0; else noSearchElements">
                                        <div class="row" *ngFor="let element of searchElements">
                                            <div class="col-12">
                                                <ng-container *ngIf="element.hasOwnProperty('lvl'); else documentThumbnail">
                                                    <app-folder-thumbnail [folder]="element"
                                                    (onDeleteFolder)="deleteElement($event)"></app-folder-thumbnail>
                                                </ng-container>
                                                <ng-template #documentThumbnail>
                                                    <app-document-thumbnail [document]="element"
                                                    (onDeleteDocument)="deleteElement($event)"></app-document-thumbnail>
                                                </ng-template>  
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #noSearchElements>
                                        <p class="text-center mt-8" *ngIf="!loadingService.isLoading()">Aucun résultat trouvé.</p>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #folderContent>
                <div class="row mt-4">
                    <div class="col-12">
                        <div class="my-documents__elements">
                            <div class="row" *ngIf="folder.parent">
                                <div class="col-12">
                                    <a [routerLink]="['/mes-documents', folder.parent ? folder.parent.getId() : '']" class="d-inline-block">
                                        <div class="d-flex align-items-center py-2">
                                            <i class="far fa-arrow-alt-circle-left fs-5 mr-2"></i>
                                            <span>Retour</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="row" *ngIf="showNewFolder">
                                <div class="col-12">
                                    <div class="my-documents__elements__new-folder">
                                        <form [formGroup]="createFolderForm" (ngSubmit)="submitCreateFolderForm()">
                                            <div class="d-flex align-items-center py-2">
                                                <div
                                                    class="my-documents__elements__new-folder__icon d-flex align-items-center justify-content-center">
                                                    <i class="fas fa-folder fs-4"></i>
                                                </div>
                                                <input type="text" #newFolder class="form-control mx-3 w-25 flex-grow-1"
                                                    formControlName="title" placeholder="Nouveau dossier"
                                                    aria-label="Nouveau dossier" />
                                                <button type="submit" class="text-secondary btn p-1 fs-4" title="Valider">
                                                    <i class="fas fa-check"></i>
                                                </button>
                                                <button type="button" class="text-danger btn p-1 fs-4" title="Annuler" (click)="cancelNewFolder()">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>
                                            <ng-container
                                                *ngIf="createFolderForm.get('title').invalid && (createFolderForm.get('title').dirty || createFolderForm.get('title').touched)">
                                                <small class="text-danger"
                                                    *ngIf="createFolderForm.get('title').errors.required">
                                                    Le nom du dossier est obligatoire
                                                </small>
                                            </ng-container>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="my-documents__elements__list">
                                        <ng-container *ngIf="elements.length > 0; else noElements">
                                            <div class="row" *ngFor="let element of elements">
                                                <div class="col-12">
                                                    <ng-container *ngIf="element.hasOwnProperty('lvl'); else documentThumbnail">
                                                        <app-folder-thumbnail [folder]="element"
                                                        (onDeleteFolder)="deleteElement($event)"></app-folder-thumbnail>
                                                    </ng-container>
                                                    <ng-template #documentThumbnail>
                                                        <app-document-thumbnail [document]="element"
                                                        (onDeleteDocument)="deleteElement($event)"></app-document-thumbnail>
                                                    </ng-template>  
                                                </div>                                              
                                            </div>
                                        </ng-container>
                                        <ng-template #noElements>
                                            <p class="text-center mt-8" *ngIf="!loadingService.isLoading()">Ce dossier est vide.</p>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>            
        </ng-container>
        <ng-template #noRootFolder>
            <p class="text-center mt-8" *ngIf="!loadingService.isLoading()">Vous ne disposez pas du dossier <b>"Mes documents"</b>, veuillez contacter la mission locale pour résoudre le problème.</p>
        </ng-template>
    </div>
</div>
<app-size-progress></app-size-progress>