import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OrderUtil } from 'src/app/shared/utils/order.util';
import { AutoUnsub } from '../decorators/auto-unsub.decorator';
import { genderMapping } from '../enums/gender.enum';
import { UserInformationRequestType } from '../enums/user-information-request-type.enum';
import { UserInformationRequestFormHandler } from '../form-handlers/user-information-request.form-handler';
import { ErrorService } from '../services/error.service';
import { LoadingService } from '../services/loading.service';
import { SecretQuestionService } from '../services/secret-question.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-phone-updating-request',
  templateUrl: './phone-updating-request.component.html',
  styleUrls: ['./phone-updating-request.component.scss']
})
@AutoUnsub()
export class PhoneUpdatingRequestComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  public userInformationRequestFormHandler: UserInformationRequestFormHandler;
  public phoneUpdatingRequestForm: FormGroup;
  public submittedPhoneUpdatingRequestForm: boolean = false;
  public genderMapping: any = genderMapping;

  constructor(private secretQuestionService: SecretQuestionService, private localeService: BsLocaleService,
    private loadingService: LoadingService, private router: Router,
    private userService: UserService, private errorService: ErrorService, 
    public orderUtil: OrderUtil) { 
    this.userInformationRequestFormHandler = new UserInformationRequestFormHandler(secretQuestionService);
  }

  ngOnInit(): void {
    this.localeService.use('fr');
    this.preparePhoneUpdatingRequestForm();
  }

  preparePhoneUpdatingRequestForm(): void {
    this.userInformationRequestFormHandler.init(UserInformationRequestType.PHONE_UPDATING);
    this.phoneUpdatingRequestForm = this.userInformationRequestFormHandler.getForm();
  }

  submitPhoneUpdatingRequestForm(): void {
    this.submittedPhoneUpdatingRequestForm = true;

    if (this.phoneUpdatingRequestForm.valid) {
      this.loadingService.showLoading();

      const phoneUpdatingRequest = this.userService.createInformationRequest(UserInformationRequestType.PHONE_UPDATING, this.userInformationRequestFormHandler.getUserInformationValue()).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        this.router.navigateByUrl('/demande-envoyee');
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(phoneUpdatingRequest);
    }
  }

}
