<div class="container">
    <div class="row">
       <div class="col-12">
            <app-header-logo></app-header-logo>
       </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h1 class="mb-5">Nouveau mot de passe</h1>
            <form [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPasswordForm()">
                <div class="form-group">                    
                    <input appFloatLabel id="username" type="text" formControlName="username" class="form-control"/>
                    <label for="username">Identifiant</label>
                    <ng-container
                        *ngIf="resetPasswordForm.get('username').invalid && (resetPasswordForm.get('username').dirty || resetPasswordForm.get('username').touched || submittedResetPasswordForm)">
                        <small class="text-danger"
                            *ngIf="resetPasswordForm.get('username').errors.required">
                            L'identifiant est obligatoire
                        </small>
                    </ng-container>  
                </div> 
                <div class="form-group">                    
                    <input appFloatLabel id="plainPassword" type="password" formControlName="plainPassword" class="form-control"/>
                    <label for="plainPassword">Mot de passe</label>
                    <ng-container
                        *ngIf="resetPasswordForm.get('plainPassword').invalid && (resetPasswordForm.get('plainPassword').dirty || resetPasswordForm.get('plainPassword').touched || submittedResetPasswordForm)">
                        <small class="text-danger"
                            *ngIf="resetPasswordForm.get('plainPassword').errors.required">
                            Le mot de passe est obligatoire
                        </small>
                        <small class="text-danger"
                            *ngIf="resetPasswordForm.get('plainPassword').errors.minlength">
                            Le mot de passe doit comporter 6 caractères minimum
                        </small>
                    </ng-container>  
                </div> 
                <div class="form-group">                    
                    <input appFloatLabel id="confirmPlainPassword" type="password" formControlName="confirmPlainPassword" class="form-control"/>
                    <label for="confirmPlainPassword">Confirmation du mot de passe</label>
                    <ng-container
                        *ngIf="resetPasswordForm.get('confirmPlainPassword').invalid && (resetPasswordForm.get('confirmPlainPassword').dirty || resetPasswordForm.get('confirmPlainPassword').touched || submittedResetPasswordForm)">
                        <small class="text-danger"
                            *ngIf="resetPasswordForm.get('confirmPlainPassword').errors.required">
                            La confirmation du mot de passe est obligatoire
                        </small>
                        <small class="text-danger" *ngIf="resetPasswordForm.get('confirmPlainPassword').errors.matching">
                            Les deux mots de passe doivent être identiques
                        </small>
                    </ng-container>  
                </div> 
                <div class="mt-12 text-center">
                    <button type="submit" class="btn bg-primary text-white mx-auto mb-4">
                        Valider
                    </button>
                    <div>
                        <a [routerLink]="['/login']" class="d-inline-block mx-auto text-primary">
                            Revenir à la connexion
                        </a>
                    </div>
                </div>                                
            </form>
        </div>
    </div>
    <div class="row mt-12">
        <div class="col-12 text-center">
            <app-footer-legal-notice></app-footer-legal-notice>
        </div>
    </div>
</div>