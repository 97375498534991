import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MakingAppointmentService } from '../services/making-appointment.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  
  constructor(private authService: AuthService, private router: Router,
    private makingAppointmentService: MakingAppointmentService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authService.isAuthenticated().pipe(
        switchMap(authenticated => {
          if (authenticated) {
            const appointmentSearch = this.makingAppointmentService.getAppointmentSearch();
            if (appointmentSearch) {
              this.router.navigate(['/prise-de-rendez-vous'], {
                queryParams: appointmentSearch
              });
            }
            return of(true);
          } else {
            this.router.navigate(['login'], { queryParams: { redirectionUrl: state.url }});
            return of(false);
          }
        })
      );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
  
}
