import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Gender } from 'src/app/core/enums/gender.enum';
import { AppointmentSlot } from 'src/app/core/models/appointment-slot.model';

@Component({
  selector: 'app-appointment-slot-thumbnail',
  templateUrl: './appointment-slot-thumbnail.component.html',
  styleUrls: ['./appointment-slot-thumbnail.component.scss']
})
export class AppointmentSlotThumbnailComponent implements OnInit {

  @Input() public appointmentSlot: AppointmentSlot;
  @Output() public selected: EventEmitter<AppointmentSlot> = new EventEmitter();
  public gender = Gender;

  constructor() { }

  ngOnInit(): void {
  }

  select(): void {
    this.selected.emit(this.appointmentSlot);
  }

}
