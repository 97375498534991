<div class="container home h-100">
    <div class="row h-100 flex-column flex-nowrap">
        <div class="col">
            <div class="row flex-column h-100">
                <div class="col">
                    <app-header-logo></app-header-logo>
                </div>
            </div>           
        </div>
        <div class="col flex-fill py-3 text-center" *ngIf="showMissionLocalePartner">
            <a [routerLink]="['/prise-de-rendez-vous']" [queryParams]="getMissionLocaleQueryParams()" class="btn btn-secondary rounded-pill w-75 mx-auto mb-3">
                Je prends rendez-vous avec la mission locale
            </a>    
        </div>
        <div class="col align-self-end bg-dark-grey block-authentication py-5 text-center">
            <h2 class="text-white mb-6 h1">Accès à votre compte</h2>
            <a [routerLink]="['/login']" class="btn bg-white w-75 mx-auto mb-3">
                Je me connecte
            </a>
            <a [routerLink]="['/inscription']" class="d-inline-block mx-auto text-white mb-6">
                Je créé mon compte
            </a>
            <app-footer-legal-notice [config]="{ 'itemClass': 'text-white' }"></app-footer-legal-notice>
        </div>
    </div>
</div>
