<div class="document-thumbnail">
    <form *ngIf="showRenameDocument; else showDocument" [formGroup]="renameDocumentForm" (ngSubmit)="submitRenameDocumentForm()">
        <div class="d-flex align-items-center py-2">
            <div class="document-thumbnail__icon d-flex align-items-center justify-content-center">
                <i class="far fa-file-alt fs-4"></i>
            </div>
            <input type="text" #renameDocument class="form-control mx-3 w-25 flex-grow-1" formControlName="title" />            
            <button type="submit" class="text-secondary btn p-1 fs-4" title="Valider">
                <i class="fas fa-check"></i>
            </button>
            <button type="button" class="text-danger btn p-1 fs-4" title="Annuler" (click)="showRenameDocument = false">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <ng-container *ngIf="renameDocumentForm.get('title').invalid && (renameDocumentForm.get('title').dirty || renameDocumentForm.get('title').touched)">
            <small class="text-danger"
                *ngIf="renameDocumentForm.get('title').errors.required">
                Le nom du document est obligatoire
            </small>
        </ng-container> 
    </form>
    <ng-template #showDocument>
        <div class="d-flex align-items-center">
            <button type="button" (tap)="showRenameDocument = true" (press)="select()" class="flex-fill overflow-hidden">
                <div class="d-flex align-items-center py-2">
                    <ng-template [ngTemplateOutlet]="icon"></ng-template>  
                    <h4 class="px-3 mb-0 flex-fill text-left w-100" [ngClass]="{ 'text-grey-65': documentService.isInSelection(document) }">
                        <span *ngIf="document.title.length > 18; else documentTitle">{{ document.title|slice:0:18 }}...</span>
                        <ng-template #documentTitle><span>{{ document.title }}</span></ng-template>
                    </h4>
                </div>
            </button>
            <div class="btn-group align-self-start py-2 flex-shrink-0" dropdown placement="bottom right">
                <button id="dropdown-btn-document-{{ document.getId() }}" dropdownToggle type="button" class="px-2 fs-4">
                    <i class="fas fa-ellipsis-v"></i>
                </button>
                <ul id="dropdown-menu-document-{{ document.getId() }}" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-alignment">
                    <li role="menuitem">
                        <button type="button" class="dropdown-item link" (click)="select()">
                            <ng-container *ngIf="!documentService.isInSelection(document); else unSelect">
                                <i class="far fa-check-square"></i> Sélectionner
                            </ng-container>
                            <ng-template #unSelect>
                                <i class="far fa-square"></i> Désélectionner
                            </ng-template>
                        </button>
                    </li>
                    <li role="menuitem">
                        <button type="button" class="dropdown-item link" (click)="showRenameDocument = true">
                            <i class="fas fa-pencil-alt"></i> Renommer
                        </button>
                    </li>
                    <li role="menuitem">
                        <button type="button" class="dropdown-item link" (click)="download()">
                            <i class="fas fa-download"></i> Télécharger
                        </button>
                    </li>
                    <li role="menuitem">
                        <button type="button" class="dropdown-item text-danger link" (click)="openModal(deleteDocumentModal)">
                            <i class="fas fa-trash-alt"></i> Supprimer
                        </button>
                    </li>                
                </ul>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #icon>
    <div class="document-thumbnail__icon d-flex align-items-center justify-content-center flex-shrink-0" [ngClass]="{ 'bg-transparent': document.file.getType() == 'image' }">
        <ng-container [ngSwitch]="document.file.getType()">
            <img #iconImage *ngSwitchCase="'image'" [ngClass]="{ 'cover-portrait': document.file.dimensions[0] < document.file.dimensions[1], 'cover-landscape': document.file.dimensions[0] >= document.file.dimensions[1] }" [src]="document.getAbsoluteFilePath()" />
            <i *ngSwitchCase="'spreadsheet'" class="mlp-font mlp-file-spreadsheet fs-6"></i>
            <i *ngSwitchCase="'wordProcessor'" class="mlp-font mlp-file-text fs-6"></i>
            <i *ngSwitchCase="'presentationFile'" class="mlp-font mlp-file-presentation fs-6"></i>
            <i *ngSwitchCase="'video'" class="mlp-font mlp-file-video fs-6"></i>
            <i *ngSwitchCase="'audio'" class="mlp-font mlp-file-audio fs-6"></i>
            <i *ngSwitchCase="'archive'" class="mlp-font mlp-file-archive fs-6"></i>
            <i *ngSwitchDefault class="far fa-file-alt fs-4"></i>
        </ng-container>
        <div class="mask-selection d-flex align-items-center justify-content-center" *ngIf="documentService.isInSelection(document)">
            <i class="fas fa-check fs-4"></i>
        </div>
    </div>      
</ng-template>

<ng-template #deleteDocumentModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Suppression du document</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Vous êtes sur le point de supprimer le document <span class="font-weight-semi-bold">{{ document.title }}</span>.</p>
        <button type="button" class="btn btn-danger w-100 mt-6" (click)="delete()">Supprimer</button>
    </div>
</ng-template>