import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MakingAppointmentStep } from 'src/app/core/enums/making-appointment-step.enum';
import { Partner } from 'src/app/core/models/partner.model';
import { MakingAppointmentService } from 'src/app/core/services/making-appointment.service';
import { PartnerInformationsModalComponent } from '../partner-informations-modal/partner-informations-modal.component';

@Component({
  selector: 'app-partner-thumbnail',
  templateUrl: './partner-thumbnail.component.html',
  styleUrls: ['./partner-thumbnail.component.scss']
})
export class PartnerThumbnailComponent implements OnInit {

  @Input() public partner: Partner;
  @Input() public configuration: any = {
    showDescription: true,
    canMakingAppointment: true
  };
  @Output() public selected: EventEmitter<Partner> = new EventEmitter();
  public bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService, private route: Router) { }

  ngOnInit(): void {
  }

  openPartnerInformationsModal(): void {    
    this.bsModalRef = this.modalService.show(PartnerInformationsModalComponent);
    this.bsModalRef.content.partner = this.partner;
  }

  select(): void {
    this.selected.emit(this.partner);
  }

}
