import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppointmentsRoutingModule } from './appointments-routing.module';
import { MyAppointmentsComponent } from './my-appointments/my-appointments.component';
import { SharedModule } from '../shared/shared.module';
import { AppointmentComponent } from './appointment/appointment.component';
import { CancellationModalComponent } from './cancellation-modal/cancellation-modal.component';
import { SharingModalComponent } from './sharing-modal/sharing-modal.component';


@NgModule({
  declarations: [MyAppointmentsComponent, AppointmentComponent, CancellationModalComponent, SharingModalComponent],
  imports: [
    CommonModule,
    AppointmentsRoutingModule,
    SharedModule
  ]
})
export class AppointmentsModule { }
