import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { Document } from 'src/app/core/models/document.model';
import { DocumentService } from 'src/app/core/services/document.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/core/services/error.service';

@Component({
  selector: 'app-sharing-modal',
  templateUrl: './sharing-modal.component.html',
  styleUrls: ['./sharing-modal.component.scss']
})
@AutoUnsub()
export class SharingModalComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  private _formBuilder: FormBuilder = new FormBuilder();
  public sharingForm: FormGroup;
  public submittedSharingForm: boolean = false;
  public documents: Document[] = [];

  constructor(private loadingService: LoadingService, public bsModalRef: BsModalRef,
    private documentService: DocumentService, private toastrService: ToastrService,
    private errorService: ErrorService) { }

  ngOnInit(): void {
    this.prepareSharingForm();
    this.loadDocuments();
  }

  prepareSharingForm(): void {
    this.sharingForm = this._formBuilder.group({
      email: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ''
    })
  }

  loadDocuments(): void {
    this.documents = this.documentService.getSelectionElements().filter(item => (item instanceof Document));
  }

  onSharingFormSubmit(): void {
    this.submittedSharingForm = true;

    if (this.sharingForm.valid) {
      this.loadingService.showLoading();

      let data = _.cloneDeep(this.sharingForm.value);      
      data.documents = this.documents.map(item => item.getIRI());

      const shareDocuments = this.documentService.shareDocuments(data).pipe(
        finalize(() => {
          this.loadingService.dismissLoading();
        })
      ).subscribe(res => {
        this.toastrService.success(`Documents partagés avec succès avec ${res.email}.`, "Félicitations !");
        this.documentService.emptySelection();
        this.bsModalRef.hide();        
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(shareDocuments);
    }
  }

}
