import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutoUnsub } from '../decorators/auto-unsub.decorator';
import { ErrorService } from '../services/error.service';
import { LoadingService } from '../services/loading.service';
import { PasswordRequestService } from '../services/password-request.service';

@Component({
  selector: 'app-password-request',
  templateUrl: './password-request.component.html',
  styleUrls: ['./password-request.component.scss']
})
@AutoUnsub()
export class PasswordRequestComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  public passwordRequestForm: FormGroup;
  public submittedPasswordRequestForm: boolean = false;

  constructor(private formBuilder: FormBuilder, private passwordRequestService: PasswordRequestService, 
    private loadingService: LoadingService, private errorService: ErrorService,
    private router: Router) { }

  ngOnInit(): void {
    this.preparePasswordRequestForm();
  }

  preparePasswordRequestForm(): void {
    this.passwordRequestForm = this.formBuilder.group({
      username: ['', Validators.required],
      redirectUrl: this.passwordRequestService.getResetPasswordPath()
    });
  }

  submitPasswordRequestForm(): void {
    this.submittedPasswordRequestForm = true;

    if (this.passwordRequestForm.valid) {
      this.loadingService.showLoading();

      const passwordRequest = this.passwordRequestService.createPasswordRequest(this.passwordRequestForm.value).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        this.router.navigate(['/', 'lien-recuperation-mot-de-passe-envoye']);
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(passwordRequest);
    }
  }

}
