<div class="modal-header">
    <h4>Partager des documents</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="sharingForm" (ngSubmit)="onSharingFormSubmit()">
        <div class="row">
            <div class="col-12 py-3">
                <h5>Mes documents à partager</h5>
                <ng-container *ngFor="let document of documents">
                    <div class="document-small-thumbnail-wrapper w-100">
                        <app-document-sharing-thumbnail [document]="document"></app-document-sharing-thumbnail>
                    </div>                    
                </ng-container>
            </div>
        </div>
        <div class="row border-top">
            <div class="col-12 py-3">
                <h5>Destinataire</h5>
                <div class="form-group">                    
                    <input appFloatLabel id="email" type="email" formControlName="email" class="form-control"/>
                    <label for="email">Adresse email</label>
                    <ng-container
                        *ngIf="sharingForm.get('email').invalid && (sharingForm.get('email').dirty || sharingForm.get('email').touched || submittedSharingForm)">
                        <small class="text-danger"
                            *ngIf="sharingForm.get('email').errors.required">
                            L'adresse email est obligatoire
                        </small>
                    </ng-container>  
                </div>
                <div class="form-group">                    
                    <input appFloatLabel id="subject" type="text" formControlName="subject" class="form-control"/>
                    <label for="subject">Sujet</label>
                    <ng-container
                        *ngIf="sharingForm.get('subject').invalid && (sharingForm.get('subject').dirty || sharingForm.get('subject').touched || submittedSharingForm)">
                        <small class="text-danger"
                            *ngIf="sharingForm.get('subject').errors.required">
                            Le sujet est obligatoire
                        </small>
                    </ng-container>  
                </div>
                <div class="form-group">                    
                    <textarea appFloatLabel id="message" formControlName="message" class="form-control"></textarea>
                    <label for="message">Message</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button type="submit" class="btn btn-primary w-100 mt-6">Envoyer</button>
            </div>
        </div>
    </form>
</div>