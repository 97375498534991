import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieStorage } from 'cookie-storage';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private _cookieStorage = new CookieStorage({
    path: '/'
  });
  private _accessTokenKey: string = 'at';
  private _refreshTokenKey: string = 'rt';
  private _scheme: string = environment.api.scheme;
  private _url: string = environment.api.url;
  public _httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json'
    })
  };

  constructor(private httpClient: HttpClient) {
  }

  private getUri(path: string) {
    return this._scheme + '://' + this._url + path;
  }

  setAuthorization(accessToken: string) {
    this._httpOptions.headers = this._httpOptions.headers.set('Authorization', 'Bearer ' + accessToken);
  }

  setCookies(data: any = {}) {
    this._cookieStorage.clear();

    if (data.token) {
      this._cookieStorage.setItem(this._accessTokenKey, data.token);
    }
    if (data.refresh_token) {
      this._cookieStorage.setItem(this._refreshTokenKey, data.refresh_token);
    }
  }

  removeCookies() {
    this._cookieStorage.removeItem(this._accessTokenKey);
    this._cookieStorage.removeItem(this._refreshTokenKey);
  }

  getAccessToken(data: any = null, params: any = null): Observable<any> {
    const path = `/token/auth`;
    let httpOptions = {
      headers: new HttpHeaders({'Content-Type':'application/json; charset=utf-8'})
    };

    if (null == data) {
      data = {
        grant_type: "client_credentials",
        token: environment.api.publicAccess.authToken
      }
    }

    if (params) {
      httpOptions['params'] = params;
    }

    return this.httpClient.post<any>(this.getUri(path), data, httpOptions);
  }

  refreshToken(refreshToken: string): Observable<any> {
    const path = `/token/refresh`;
    const data = {
      refresh_token: refreshToken
    };

    this.setAuthorization(environment.api.publicAccess.authToken);

    return this.httpClient.post<any>(this.getUri(path), data);
  }

  get(path: string, options: any = null): Observable<any> {
    let httpOptions = Object.assign({}, this._httpOptions);

    if (options) {
      if (options.headers){
        options.headers.keys().forEach((key) => {
          if (options.headers.has(key)) {
            httpOptions.headers = httpOptions.headers.set(key, options.headers.get(key));
          } else {
            httpOptions.headers = httpOptions.headers.append(key, options.headers.get(key));
          }
        });
      }

      if (options.params) {
        httpOptions['params'] = options.params;
      }

      if (options.responseType) {
          httpOptions['responseType'] = options.responseType;
      }
    }

    return this.httpClient.get<any>(this.getUri(path), httpOptions).pipe(
      catchError(error => {
        if (401 == error.status && "Expired JWT Token" == error.error.message) {
          const refreshToken = this._cookieStorage.getItem(this._refreshTokenKey);

          return this.refreshToken(refreshToken).pipe(
            switchMap(response => {
              this.setAuthorization(response.token);
              this.setCookies(response);

              return this.get(path, options);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }

  post(path: string, data: any, options: any = null): Observable<any> {
    let httpOptions = Object.assign({}, this._httpOptions);

    if (options) {
      if (options.headers){
        options.headers.keys().forEach((key) => {
          if (options.headers.has(key)) {
            httpOptions.headers = httpOptions.headers.set(key, options.headers.get(key));
          } else {
            httpOptions.headers = httpOptions.headers.append(key, options.headers.get(key));
          }
        });
      }

      if (options.responseType) {
          httpOptions['responseType'] = options.responseType;
      }
    }

    return this.httpClient.post<any>(this.getUri(path), data, httpOptions).pipe(
      catchError(error => {
        if (401 == error.status && "Expired JWT Token" == error.error.message) {
          const refreshToken = this._cookieStorage.getItem(this._refreshTokenKey);

          return this.refreshToken(refreshToken).pipe(
            switchMap(response => {
              this.setAuthorization(response.token);
              this.setCookies(response);

              return this.post(path, data, options);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }

  put(path: string, data: any): Observable<any> {
    return this.httpClient.put<any>(this.getUri(path), data, this._httpOptions).pipe(
      catchError(error => {
        if (401 == error.status && "Expired JWT Token" == error.error.message) {
          const refreshToken = this._cookieStorage.getItem(this._refreshTokenKey);

          return this.refreshToken(refreshToken).pipe(
            switchMap(response => {
              this.setAuthorization(response.token);
              this.setCookies(response);

              return this.put(path, data);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }

  delete(path: string): Observable<any> {
    return this.httpClient.delete<any>(this.getUri(path), this._httpOptions).pipe(
      catchError(error => {
        if (401 == error.status && "Expired JWT Token" == error.error.message) {
          const refreshToken = this._cookieStorage.getItem(this._refreshTokenKey);

          return this.refreshToken(refreshToken).pipe(
            switchMap(response => {
              this.setAuthorization(response.token);
              this.setCookies(response);

              return this.delete(path);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }
}
