<div class="appointment-slot-thumbnail bg-white">
    <div class="container-fluid">
        <div class="row no-gutters align-items-center flex-nowrap">
            <div class="flex-fill">
                <button type="button" (click)="select()" class="d-flex align-items-center py-3 w-100">
                    <div class="calendar-marker rounded-circle p-2 bg-dark-grey d-flex align-items-center justify-content-center flex-shrink-0">
                        <i class="fas fa-calendar-day text-white fa-2x"></i>
                    </div>
                    <div class="text-left px-3">
                        <h4 class="mb-0">{{ appointmentSlot.getFullDate() }}</h4>
                        <p class="mb-0 text-grey-35 font-weight-medium">
                            <span *ngIf="appointmentSlot.availability.workStation.user.gender == gender.MALE; then genderMale else genderFemale"></span>
                                <ng-template #genderMale>Interlocuteur</ng-template>
                                <ng-template #genderFemale>Interlocutrice</ng-template>
                            : {{ appointmentSlot.availability.workStation.user.getFullName() }}
                        </p>
                    </div>          
                </button>      
            </div>                
        </div>
    </div>
</div>  