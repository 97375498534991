import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PasswordRequest } from '../models/password-request.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordRequestService {

  private _apiRoutePath: string = "/password-requests";
  private _resetPasswordPath: string = "/nouveau-mot-de-passe";

  constructor(private apiService: ApiService) { }

  getResetPasswordPath(): string {
    return window.location.origin + this._resetPasswordPath;
  }

  createPasswordRequest(data: any): Observable<PasswordRequest> {
    return this.apiService.post(this._apiRoutePath, data).pipe(
      map(res => new PasswordRequest().deserialize(res))
    );
  }

  getPasswordRequest(id: string): Observable<PasswordRequest> {
    return this.apiService.get(this._apiRoutePath + '/' + id).pipe(
      map(res => new PasswordRequest().deserialize(res))
    );
  }

  resetPassword(id: string, data: any): Observable<PasswordRequest> {
    return this.apiService.put(this._apiRoutePath + '/' + id + '/reset-password', data).pipe(
      map(res => new PasswordRequest().deserialize(res))
    );
  }
}
