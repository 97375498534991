import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

export class BaseFormHandler {

    protected _form: FormGroup;
    protected formBuilder: FormBuilder = new FormBuilder();

    constructor() {       
    }

    getForm(): FormGroup {
        return this._form;
    }

    getFormArray(path: string | (string | number)[], parent: AbstractControl = null): FormArray | null {
        if (!parent) {
            parent = this._form;
        }

        return parent.get(path) as FormArray;
    }

    getFormGroup(path: string | (string | number)[], parent: AbstractControl = null): FormGroup | null {
        if (!parent) {
            parent = this._form;
        }

        return parent.get(path) as FormGroup;
    }

    getFormControl(path: string | (string | number)[], parent: AbstractControl = null): FormControl | null {
        if (!parent) {
            parent = this._form;
        }

        return parent.get(path) as FormControl;
    }

    clear(): void {
        this._form.reset();
    }

}
