import { Component, Input, OnInit } from '@angular/core';
import { Availability } from 'src/app/core/models/availability.model';

@Component({
  selector: 'app-availability-thumbnail',
  templateUrl: './availability-thumbnail.component.html',
  styleUrls: ['./availability-thumbnail.component.scss']
})
export class AvailabilityThumbnailComponent implements OnInit {

  @Input() public availability: Availability;

  constructor() { }

  ngOnInit(): void {
  }

  selectAvailability(): void {
    
  }

}
