import { Component, OnInit } from '@angular/core';
import { Appointment } from 'src/app/core/models/appointment.model';
import { AppointmentType } from 'src/app/core/enums/appointment-type.enum';
import { Gender } from 'src/app/core/enums/gender.enum';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { finalize } from 'rxjs/operators';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from 'src/app/core/services/error.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cancellation-modal',
  templateUrl: './cancellation-modal.component.html',
  styleUrls: ['./cancellation-modal.component.scss']
})
@AutoUnsub()
export class CancellationModalComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  public appointment: Appointment;
  public appointmentType = AppointmentType;
  public gender = Gender;

  constructor(private appointmentService: AppointmentService, private loadingService: LoadingService,
    private toastrService: ToastrService, private errorService: ErrorService, public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  cancelAppointment(): void {
    this.loadingService.showLoading();

    const cancelAppointment = this.appointmentService.cancelAppointment(this.appointment.getId(), { canceledAt: new Date(), canceledBy: Appointment.CANCELED_BY_USER }).pipe(
      finalize(() => {
        this.loadingService.dismissLoading();
        this.bsModalRef.hide();
      })
    ).subscribe(res => {
      this.appointment.canceledAt = res.canceledAt;
      this.appointment.canceledBy = res.canceledBy;
      this.toastrService.success("Vous allez recevoir un message de confirmation", "Rendez-vous annulé !");
    }, err => {
      this.errorService.addError(err);
      this.errorService.show();
    });
    
    this._observers.add(cancelAppointment);
  }

}
