import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyNotesSummaryComponent } from './my-notes-summary/my-notes-summary.component';

const routes: Routes = [
  {
    path: "",
    component: MyNotesSummaryComponent,
    runGuardsAndResolvers: 'always',
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotesSummaryRoutingModule { }
