<div class="availability-thumbnail">
    <div class="container-fluid">
        <div class="row no-gutters align-items-center flex-nowrap">
            <div class="flex-fill">
                <button type="button" (click)="selectAvailability()" class="d-flex align-items-center py-3">
                    <div class="map-marker rounded-circle p-2 bg-dark-grey d-flex align-items-center justify-content-center flex-shrink-0">
                        <i class="fas fa-calendar-day text-white fa-2x"></i>
                    </div>
                    <div class="px-3">
                        <h4>{{ availability.beginAt|date:'EEEE d MMMM - HH:mm' }}</h4>
                    </div>          
                </button>      
            </div>                
        </div>
    </div>
</div>