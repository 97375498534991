import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { Document } from 'src/app/core/models/document.model';
import { DocumentService } from 'src/app/core/services/document.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import { FolderService } from 'src/app/core/services/folder.service';

@Component({
  selector: 'app-document-thumbnail',
  templateUrl: './document-thumbnail.component.html',
  styleUrls: ['./document-thumbnail.component.scss']
})
@AutoUnsub()
export class DocumentThumbnailComponent implements OnInit {

  @Input() public document: Document;
  @Output() public onDeleteDocument: EventEmitter<any> = new EventEmitter();
  @ViewChildren('renameDocument') renameDocumentElement: QueryList<ElementRef>;
  @ViewChild('iconImage') iconImage: ElementRef;
  private _observers: Subscription = new Subscription();
  public modalRef: BsModalRef;
  public renameDocumentForm: FormGroup;
  public showRenameDocument: boolean = false;

  constructor(private router: Router, private modalService: BsModalService,
    public documentService: DocumentService, private loadingService: LoadingService,
    private errorService: ErrorService, private toastrService: ToastrService,
    private formBuilder: FormBuilder, private folderService: FolderService) { }

  ngOnInit(): void {
    this.prepareRenameDocumentForm();
    if (this.document.file.isImage()) {
      this.getIconImage();
    }
  }

  ngAfterViewInit() {
    this.renameDocumentElement.changes.subscribe(_ => {
      if (this.showRenameDocument) {
        this.renameDocumentElement.first.nativeElement.focus();
      }
    });
  }

  getIconImage(): void {
    this.documentService.downloadDocument(this.document.getId(), { format: 'thumbnail' }).pipe(
      take(1)
    ).subscribe(res => {
      let reader = new FileReader();

      reader.addEventListener('loadend',() => {
        this.document.contentUrl = reader.result;
      });

      reader.readAsDataURL(res);
    });
  }

  prepareRenameDocumentForm(): void {
    this.renameDocumentForm = this.formBuilder.group({
      title: [this.document.title.slice(0, this.document.title.lastIndexOf('.')), Validators.required]
    });
  }

  openModal(template: TemplateRef<any>,) {
    this.modalRef = this.modalService.show(template);
  }

  select(): void {
    this.documentService.isInSelection(this.document) ? this.documentService.removeSelectionElement(this.document) : this.documentService.addSelectionElement(this.document);
  }

  delete(): void {
    this.loadingService.showLoading();
    
    const deleteDocument = this.documentService.deleteDocument(this.document.getId()).pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(res => {      
      this.onDeleteDocument.emit(this.document);      
      this.folderService.updateFolderContent({
        status: 'removeDocument',
        document: this.document
      });
      
      this.toastrService.success("Document supprimé avec succès.", "Félicitations !");
      this.modalRef.hide();
    }, err => {
      this.errorService.addError(err);
      this.errorService.show();
    });

    this._observers.add(deleteDocument);
  }

  download(): void {
    this.loadingService.showLoading();

    const downloadDocument = this.documentService.downloadDocument(this.document.getId()).pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(res => {
      var blob = new Blob([res], { type: this.document.file.mimeType });

      setTimeout(() => {
          FileSaver.saveAs(blob, this.document.title);
      }, 250);
    }, err => {
      this.errorService.addError(err);
      this.errorService.show();
    });

    this._observers.add(downloadDocument);
  }

  submitRenameDocumentForm(): void {
    if (this.renameDocumentForm.valid) {
      this.loadingService.showLoading();

      let data = _.cloneDeep(this.renameDocumentForm.value);
      data.title = data.title + this.document.title.slice(this.document.title.lastIndexOf('.'));

      const renameDocument = this.documentService.updateDocument(this.document.getId(), data).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        this.document.title = res.title;
        this.showRenameDocument = false;
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(renameDocument);
    }
  }


}
