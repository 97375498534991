import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocumentsRoutingModule } from './documents-routing.module';
import { MyDocumentsComponent } from './my-documents/my-documents.component';
import { SharedModule } from '../shared/shared.module';
import { SharingModalComponent } from './sharing-modal/sharing-modal.component';
import { SizeProgressComponent } from './size-progress/size-progress.component';


@NgModule({
  declarations: [MyDocumentsComponent, SharingModalComponent, SizeProgressComponent],
  imports: [
    CommonModule,
    DocumentsRoutingModule,
    SharedModule
  ]
})
export class DocumentsModule { }
