<app-top-bar [title]="title" [rightAction]="topBarRightAction" (clickedRightAction)="openSharingAppointmentModal()"></app-top-bar>
<div class="my-appointments pb-10">    
    <tabset>
        <tab [customClass]="'nav-item-underline'">
            <ng-template tabHeading>
                <div class="d-flex align-items-center">
                    <span class="mb-0 mr-2">Prochainement</span> <span class="badge badge-grey-50">{{ comingSoonAppointmentsTotal }}</span>
                </div>                
            </ng-template>  
            <div class="container-fluid">                      
                <div class="my-appointments__coming-soon" libScrollEvent (onscroll)="lazyLoadComingSoonAppointments($event)" [bottomOffset]="50">
                    <h2>Prochainement</h2>
                    <div class="my-appointments__coming-soon__list" *ngIf="comingSoonAppointments.length; else noComingSoonAppointments">
                        <ng-container *ngFor="let appointment of comingSoonAppointments">
                            <div class="row mb-2">
                                <div class="appointment-thumbnail-wrapper w-100">
                                    <app-appointment-thumbnail [appointment]="appointment" [comingSoon]="true"></app-appointment-thumbnail>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <ng-template #noComingSoonAppointments>
                        <p *ngIf="!comingSoonAppointmentsLoading">Aucun rendez-vous à venir.</p>
                    </ng-template>
                    <div class="text-center py-8" *ngIf="comingSoonAppointmentsLoading">
                        <div class="spinner-border text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div> 
            </div>
        </tab>
        <tab [customClass]="'nav-item-underline'">
            <ng-template tabHeading>
                <div class="d-flex align-items-center">
                    <span class="mb-0 mr-2">Historique</span> <span class="badge badge-grey-50">{{ pastAppointmentsTotal }}</span>
                </div>                
            </ng-template>
            <div class="container-fluid">     
                <div class="my-appointments__past" libScrollEvent (onscroll)="lazyLoadPastAppointments($event)" [bottomOffset]="50">
                    <h2>Historique</h2>
                    <div class="my-appointments__past__list" *ngIf="pastAppointments.length; else noPastAppointments">
                        <ng-container *ngFor="let appointment of pastAppointments">
                            <div class="row mb-2">
                                <div class="appointment-thumbnail-wrapper w-100">
                                    <app-appointment-thumbnail [appointment]="appointment"></app-appointment-thumbnail>
                                </div>
                            </div>                   
                        </ng-container>
                    </div>
                    <ng-template #noPastAppointments>
                        <p *ngIf="!pastAppointmentsLoading">Aucun rendez-vous passé.</p>
                    </ng-template>
                    <div class="text-center py-8" *ngIf="pastAppointmentsLoading">
                        <div class="spinner-border text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </tab>
    </tabset>
    <a [routerLink]="['/prise-de-rendez-vous']" [queryParams]="getMissionLocaleQueryParams()" class="btn btn-primary btn-floating-action">
        Je prends rendez-vous avec la mission locale
    </a>
</div>
