<form [formGroup]="registrationStepForm" (ngSubmit)="submitRegistrationStepForm()">
    <div class="form-group">                    
        <input appFloatLabel id="firstName" type="text" formControlName="firstName" class="form-control"/>
        <label for="firstName">Prénom</label>
        <ng-container
            *ngIf="registrationStepForm.get('firstName').invalid && (registrationStepForm.get('firstName').dirty || registrationStepForm.get('firstName').touched || submittedRegistrationStepForm)">
            <small class="text-danger"
                *ngIf="registrationStepForm.get('firstName').errors.required">
                Le prénom est obligatoire
            </small>
        </ng-container>  
    </div> 
    <div class="form-group">                    
        <input appFloatLabel id="lastName" type="text" formControlName="lastName" class="form-control"/>
        <label for="lastName">Nom</label>
        <ng-container
            *ngIf="registrationStepForm.get('lastName').invalid && (registrationStepForm.get('lastName').dirty || registrationStepForm.get('lastName').touched || submittedRegistrationStepForm)">
            <small class="text-danger"
                *ngIf="registrationStepForm.get('lastName').errors.required">
                Le prénom est obligatoire
            </small>
        </ng-container>  
    </div> 
    <div class="form-group">                               
        <ng-select appearance="outline"
                [searchable]="false"
                [clearable]="false"
                [items]="genderMapping|keyvalue:orderUtil.originalOrder"
                bindValue="key"
                bindLabel="value"
                formControlName="gender"
                labelForId="gender"
                class="ng-select--primary text-left {{ registrationStepForm.get('gender').value != '' ? 'float-label' : '' }}">
            </ng-select>
            <label for="gender">Civilité</label>  
        <ng-container
            *ngIf="registrationStepForm.get('gender').invalid && (registrationStepForm.get('gender').dirty || registrationStepForm.get('gender').touched || submittedRegistrationStepForm)">
            <small class="text-danger"
                *ngIf="registrationStepForm.get('gender').errors.required">
                La civilité est obligatoire
            </small>
        </ng-container>  
    </div>  
    <div class="form-group">                    
        <input appFloatLabel
            id="birthDate"
            class="form-control"
            #datepickerBirthDate="bsDatepicker"
            bsDatepicker
            formControlName="birthDate"
            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', isAnimated: true, returnFocusToInput: true, containerClass: 'theme-default' }">
        <label for="birthDate">Date de naissance</label>
        <ng-container
            *ngIf="registrationStepForm.get('birthDate').invalid && (registrationStepForm.get('birthDate').dirty || registrationStepForm.get('birthDate').touched || submittedRegistrationStepForm)">
            <small class="text-danger"
                *ngIf="registrationStepForm.get('birthDate').errors.required">
                La date de naissance est obligatoire
            </small>
        </ng-container>
    </div> 
    <div class="form-group">                    
        <input appFloatLabel id="phone" type="text" formControlName="phone" class="form-control"/>
        <label for="phone">Numéro de téléphone</label>
        <ng-container
            *ngIf="registrationStepForm.get('phone').invalid && (registrationStepForm.get('phone').dirty || registrationStepForm.get('phone').touched || submittedRegistrationStepForm)">
            <small class="text-danger"
                *ngIf="registrationStepForm.get('phone').errors.required">
                Le numéro de téléphone est obligatoire
            </small>
        </ng-container>  
    </div>  
    <div class="mt-12 text-center">
        <button type="submit" class="btn bg-primary text-white mx-auto mb-4">
            Continuer
        </button>
        <div class="mb-1">
            <a [routerLink]="['/login']" class="d-inline-block mx-auto text-primary">
                Revenir à la connexion
            </a>
        </div>
    </div>                                
</form>