import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { Gender, genderMapping } from 'src/app/core/enums/gender.enum';
import { UserInformationRequestType } from 'src/app/core/enums/user-information-request-type.enum';
import { User } from 'src/app/core/models/user.model';
import { ErrorService } from 'src/app/core/services/error.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MeService } from 'src/app/core/services/me.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
@AutoUnsub()
export class MyProfileComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  public title: string = "Mon profil";
  public me: User;
  public gender = Gender;
  public genderMapping = genderMapping;
  public today: Date = new Date();
  public topBarRightAction: any = {
    id: 'edit'
  };

  constructor(private router: Router, private route: ActivatedRoute,
    private meService: MeService, private loadingService: LoadingService,
    private userService: UserService, private errorService: ErrorService) { }

  ngOnInit(): void {
    this.me = this.meService.getMe();
  }

  navigateToProfileEdition(): void {
    this.router.navigate(['modifier'], { relativeTo: this.route });
  }

  retrievePersonalDataRequest(): void {
    this.loadingService.showLoading();

    const personalDataRequest = this.userService.createInformationRequest(UserInformationRequestType.PERSONAL_DATA, this.me.serialize()).pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(res => {
      this.router.navigateByUrl('/demande-envoyee');
    }, err => {
      this.errorService.addError(err);
      this.errorService.show();
    });

    this._observers.add(personalDataRequest);
  }

  deleteOwnAccountRequest(): void {
    this.loadingService.showLoading();

    const personalDataRequest = this.userService.createInformationRequest(UserInformationRequestType.DELETE_ACCOUNT, this.me.serialize()).pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(res => {
      this.router.navigateByUrl('/demande-envoyee');
    }, err => {
      this.errorService.addError(err);
      this.errorService.show();
    });

    this._observers.add(personalDataRequest);
  }

}
