import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe, NgPlural, NgPluralCase } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FloatLabelDirective } from './directives/float-label.directive';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgxScrollEventModule } from 'ngx-scroll-event';
import { PartnerThumbnailComponent } from './components/partner-thumbnail/partner-thumbnail.component';
import { PartnerInformationsModalComponent } from './components/partner-informations-modal/partner-informations-modal.component';
import { AgencyThumbnailComponent } from './components/agency-thumbnail/agency-thumbnail.component';
import { MakingAppointmentSearchComponent } from './components/making-appointment-search/making-appointment-search.component';
import { AvailabilityThumbnailComponent } from './components/availability-thumbnail/availability-thumbnail.component';
import { AppointmentSlotThumbnailComponent } from './components/appointment-slot-thumbnail/appointment-slot-thumbnail.component';
import { HeaderLogoComponent } from './components/header-logo/header-logo.component';
import { FooterLegalNoticeComponent } from './components/footer-legal-notice/footer-legal-notice.component';
import { CoreRoutingModule } from '../core/core-routing.module';
import { AppointmentThumbnailComponent } from './components/appointment-thumbnail/appointment-thumbnail.component';
import { NoteComponent } from './components/note/note.component';
import { QuillModule } from 'ngx-quill';
import { MomentFormatPipe } from './pipes/moment-format.pipe';
import { MomentDiffPipe } from './pipes/moment-diff.pipe';
import { FolderThumbnailComponent } from './components/folder-thumbnail/folder-thumbnail.component';
import { DocumentThumbnailComponent } from './components/document-thumbnail/document-thumbnail.component';
import { InstanceofPipe } from './pipes/instanceof.pipe';
import { FileUploadModule } from 'ng2-file-upload';
import { DocumentSharingThumbnailComponent } from './components/document-sharing-thumbnail/document-sharing-thumbnail.component';


@NgModule({
  declarations: [FloatLabelDirective, HeaderLogoComponent, FooterLegalNoticeComponent, TopBarComponent, PartnerThumbnailComponent, PartnerInformationsModalComponent, AgencyThumbnailComponent, MakingAppointmentSearchComponent, AvailabilityThumbnailComponent, AppointmentSlotThumbnailComponent, AppointmentThumbnailComponent, NoteComponent, MomentFormatPipe, MomentDiffPipe, FolderThumbnailComponent, DocumentThumbnailComponent, InstanceofPipe, DocumentSharingThumbnailComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    CoreRoutingModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxScrollEventModule,
    QuillModule.forRoot(),
    FileUploadModule,
    ProgressbarModule.forRoot(),
    TabsModule.forRoot()
  ],
  exports: [
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    BsDatepickerModule,
    ModalModule,
    TabsModule,
    BsDropdownModule,
    NgxScrollEventModule,
    ProgressbarModule,
    QuillModule,
    FileUploadModule,
    FloatLabelDirective,
    TopBarComponent,
    PartnerThumbnailComponent,
    AgencyThumbnailComponent,
    AvailabilityThumbnailComponent,
    AppointmentSlotThumbnailComponent,
    MakingAppointmentSearchComponent,
    HeaderLogoComponent,
    FooterLegalNoticeComponent,
    AppointmentThumbnailComponent,
    NoteComponent,
    FolderThumbnailComponent,
    DocumentThumbnailComponent,
    DocumentSharingThumbnailComponent,
    MomentFormatPipe,
    MomentDiffPipe,
    InstanceofPipe,
  ],
  providers: [
    TopBarComponent,
    NgPlural,
    NgPluralCase,
    DatePipe,
    MomentFormatPipe,
    MomentDiffPipe,
    InstanceofPipe,
    DecimalPipe,
    {provide: LOCALE_ID, useValue: "fr-FR" }
  ]
})
export class SharedModule { }
