import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgxScrollEvent } from 'ngx-scroll-event';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MakingAppointmentStep } from 'src/app/core/enums/making-appointment-step.enum';
import { Partner } from 'src/app/core/models/partner.model';
import { MakingAppointmentService } from 'src/app/core/services/making-appointment.service';
import { PartnerService } from 'src/app/core/services/partner.service';

@Component({
  selector: 'app-partners-listing',
  templateUrl: './partners-listing.component.html',
  styleUrls: ['./partners-listing.component.scss']
})
export class PartnersListingComponent implements OnInit {

  @Input() public search: string;
  private _observers: Subscription = new Subscription();
  private _partnersPage: number = 1;
  public partnersLoading: boolean = false;
  public partners: Partner[] = [];
  public totalPartners: number = 0;

  constructor(private partnerService: PartnerService, private makingAppointmentService: MakingAppointmentService,
    private route: Router) { }

  ngOnInit(): void {
  }

  ngOnChanges(changements: SimpleChanges): void {
    if (changements.search && changements.search.previousValue != changements.search.currentValue) {
      this.initPartners();
      this.loadPartners();
    }
  }

  initPartners(): void {
    this._partnersPage = 1;
    this.partners = []; 
  }

  loadPartners(): void {
    if (this._partnersPage && !this.partnersLoading) {
      this.partnersLoading = true;
      const getPartners: Subscription = this.partnerService.getAllPartners(this._partnersPage, this.getLoadPartnersParams()).pipe(
        finalize(() => {
          this.partnersLoading = false;
        })
      ).subscribe(res => {                    
          this.totalPartners = res.totalItems;
          this.partners = this.partners.concat(res.items);
          this._partnersPage = (res.totalItems > this.partners.length) ? (this._partnersPage + 1) : 0;
      });

      this._observers.add(getPartners);
    }
  }

  lazyLoadPartners(event: NgxScrollEvent): void {
    if (event.isReachingBottom) {
      this.loadPartners();
    }
  }

  getLoadPartnersParams(): any {
    let params = {};

    if (this.search) {
      params['search'] = this.search;
    }

    return params;
  }

  makeAppointment(partner: Partner): void {
    this.makingAppointmentService.setPartner(partner);
    this.route.navigate(['/prise-de-rendez-vous'], {
      queryParams: this.makingAppointmentService.getQueryParams(MakingAppointmentStep.AGENCY)
    });
  }

}
