import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserInformationRequestType } from '../enums/user-information-request-type.enum';
import { Folder } from '../models/folder.model';
import { NotesSummary } from '../models/notes-summary.model';
import { User } from '../models/user.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _apiRoutePath: string = "/users";

  constructor(private apiService: ApiService) { }

  createInformationRequest(userInformationRequestType: UserInformationRequestType, data: any): Observable<any> {
    const path = this._apiRoutePath + '/information-request?type=' + userInformationRequestType;

    return this.apiService.post(path, data);
  }

  createUser(data: any): Observable<User> {
    return this.apiService.post(this._apiRoutePath, data).pipe(
      map(res => new User().deserialize(res))
    );
  }

  updateUser(id: string, data: any): Observable<User> {
    return this.apiService.put(this._apiRoutePath + '/' + id, data).pipe(
      map(res => new User().deserialize(res))
    );
  }

  updateUserPassword(id: string, data: any): Observable<User> {
    return this.apiService.put(this._apiRoutePath + '/' + id + '/password', data).pipe(
      map(res => new User().deserialize(res))
    );
  }

  getNotesSummary(id: string): Observable<NotesSummary> {
    return this.apiService.get(this._apiRoutePath + '/' + id + '/notes-summary').pipe(
      map(res => new NotesSummary().deserialize(res))
    );
  }

  getRootFolder(id: string): Observable<Folder> {
    return this.apiService.get(this._apiRoutePath + '/' + id + '/root-folder').pipe(
      map(res => new Folder().deserialize(res))
    );
  }
  
}
