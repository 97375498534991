export const environment = {
  production: true,
  api: {
    scheme: 'https',
    url: "admin.clicnote.fr/api",
    publicAccess: {
      authToken: "4eec8543106b5b56a55427e0f6f4b6a9ded883499d34682d0d69e2bc2de448c3"
    }
  },
  publicServer: {
    url: 'https://admin.clicnote.fr',
    directories: {
      medias: '/medias'
    },
    storage: {
      size: {
        max: 100
      }
    }
  }
};
