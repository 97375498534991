import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { AppointmentType } from 'src/app/core/enums/appointment-type.enum';
import { Gender } from 'src/app/core/enums/gender.enum';
import { Appointment } from 'src/app/core/models/appointment.model';
import { WorkStation } from 'src/app/core/models/work-station.model';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { SharingService } from 'src/app/core/services/sharing.service';
import { WorkstationService } from 'src/app/core/services/workstation.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-sharing-modal',
  templateUrl: './sharing-modal.component.html',
  styleUrls: ['./sharing-modal.component.scss']
})
@AutoUnsub()
export class SharingModalComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  private _workStationsPage: number = 1;
  private _appointmentsPage: number = 1;
  private _formBuilder: FormBuilder = new FormBuilder();
  public canSelectAppointment: boolean = true;
  public appointment: Appointment;
  public appointmentType = AppointmentType;
  public gender = Gender;
  public workStations: WorkStation[] = [];  
  public workStationsLoading: boolean = false;
  public appointments: Appointment[] = [];  
  public appointmentsLoading: boolean = false;
  public sharingForm: FormGroup;
  public sharedAppointmentWorkStations: EventEmitter<WorkStation[]> = new EventEmitter();

  constructor(private loadingService: LoadingService, public bsModalRef: BsModalRef,
    private sharingService: SharingService, private workStationService: WorkstationService,
    private errorService: ErrorService, private toastrService: ToastrService,
    public appointmentService: AppointmentService) { }

  ngOnInit(): void {
    this.prepareSharingForm();
    this.loadAppointments();
    this.loadWorkStations();   
    this.setObservers(); 
  }

  setObservers(): void {
    this._observers.add(this.onAppointmentFormChanged());
  }

  prepareSharingForm(): void {
    this.sharingForm = this._formBuilder.group({
      appointment: [null, [Validators.required]],
      workStations: [[], [Validators.minLength(1)]]
    })
  }

  onAppointmentFormChanged(): Subscription {
    return this.sharingForm.get('appointment').valueChanges.subscribe(res => {
      this.appointment = res;
    });
  }

  setSharingAppointment(appointment: Appointment): void {
    this.appointment = appointment;
    this.sharingForm.get('appointment').patchValue(this.appointment);
  }

  loadAppointments(): void {
    if (this._appointmentsPage && !this.appointmentsLoading) {
      this.appointmentsLoading = true;

      const getAppointments: Subscription =  this.appointmentService.getAllAppointments(this._appointmentsPage, this.getLoadAppointmentsParams()).pipe(
        finalize(() => {
          this.appointmentsLoading = false;
        })
      ).subscribe(res => {
        this.appointments = this.appointments.concat(res.items);
        this._appointmentsPage = (res.totalItems > this.appointments.length) ? (this._appointmentsPage + 1) : 0;
      });

      this._observers.add(getAppointments);
    }
  }

  getLoadAppointmentsParams(): any {
    let params = {
      ['exists[canceledAt]']: false,
      ['order[beginAt]']: 'desc'
    };

    return params;
  }

  loadWorkStations(): void {
    if (this._workStationsPage && !this.workStationsLoading) {
      this.workStationsLoading = true;

      const getWorkStations: Subscription = this.workStationService.getAllWorkStationsMet(this._workStationsPage).pipe(
        finalize(() => {
          this.workStationsLoading = false;
        })
      ).subscribe(res => {
        this.workStations = this.workStations.concat(res.items);
        this._workStationsPage = (res.totalItems > this.workStations.length) ? (this._workStationsPage + 1) : 0;
      });

      this._observers.add(getWorkStations);
    }
  }

  onSharingFormSubmit(): void {
    if (this.sharingForm.valid) {
      this.loadingService.showLoading();
      let data = _.cloneDeep(this.sharingForm.value);

      data.appointment = data.appointment.getIRI();
      data.workStations = data.workStations.map(item => item.getIRI());

      const shareAppointment = this.sharingService.createSharing(data).pipe(
        finalize(() => {
          this.loadingService.dismissLoading();
        })
      ).subscribe(res => {
        this.sharedAppointmentWorkStations.emit(res.workStations);
        this.toastrService.success("Rendez-vous partagé.", "Félicitations !");
        this.bsModalRef.hide();        
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(shareAppointment);
    }
  }

}
