<div class="container">
    <div class="row">
       <div class="col-12">
            <app-header-logo></app-header-logo>
       </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="mb-5">Lien de récupération envoyé !</h1>
            <p>
                Nous venons de vous envoyer un lien de récupération de mot de passe par sms.
            </p>
            <div class="mt-12">
                <a [routerLink]="['/login']" class="d-inline-block mx-auto text-primary">
                    Revenir à la connexion
                </a>
            </div>
        </div>
    </div>
    <div class="row mt-12">
        <div class="col-12 text-center">
            <app-footer-legal-notice></app-footer-legal-notice>
        </div>
    </div>
</div>