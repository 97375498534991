import { Serializable } from "../interfaces/serializable";
import { Availability } from "./availability.model";
import * as moment from 'moment';

export class AppointmentSlot implements Serializable {

    public availability: Availability = null;
    public beginAt: Date = null;
    public endAt: Date = null;

    public getFullDate(): string {
        if (!this.beginAt) {
            return "";
        }
        
        const weekDay = moment(this.beginAt).format('dddd').charAt(0).toUpperCase() + moment(this.beginAt).format('dddd').slice(1);
        const day = moment(this.beginAt).format('DD MMMM')
        const hour = moment(this.beginAt).format('HH') + 'h' + moment(this.beginAt).format('mm');
        
        return weekDay + ' ' + day + ' - ' + hour;
    }

    public update(data: any = {}): this {
        if (!data) {
            return null;
        }

        const properties = Object.getOwnPropertyNames(this);
        Object.keys(data).forEach((key) => {
            switch (key) {
                case '_uuid': break;                
                default:
                    if (properties.includes(key)) {
                        this[key] = data[key];
                    }
                    break;
            }
        });

        return this;
    }

    public deserialize(input: any = {}): this {
        if (!input) {
            return null;
        }

        const properties = Object.getOwnPropertyNames(this);
        Object.keys(input).forEach((key) => {
            if (input[key] !== null) {
                switch (key) { 
                    case 'availability':
                        this[key] = new Availability().deserialize(input[key]);
                        break;                   
                    case 'beginAt':
                    case 'endAt':
                        this[key] = new Date(input[key]);
                        break;                    
                    default:
                        if (properties.includes(key)) {
                            this[key] = input[key];
                        }
                        break;
                }
            }
        });

        return this;
    }

    public serialize(): any {
        var output = {};

        Object.getOwnPropertyNames(this).forEach(property => {
            switch (property) {                                
                default:
                    output[property] = this[property];
                    break;
            }
        });

        return output;
    }
}
