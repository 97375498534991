import { FormControl, Validators } from '@angular/forms';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { matchingValidator } from 'src/app/shared/directives/matching-validator.directive';
import { SecretAnswer } from '../models/secret-answer.model';
import { SecretQuestion } from '../models/secret-question.model';
import { User } from '../models/user.model';
import { SecretQuestionService } from '../services/secret-question.service';
import { BaseFormHandler } from './base.form-handler';
import * as moment from 'moment';

export class UserFormHandler extends BaseFormHandler {

    static readonly USER_FORM_TYPE_REGISTRATION = 'user_form_type_registration';
    static readonly USER_FORM_TYPE_PROFILE = 'user_form_type_profile';
    static readonly USER_FORM_TYPE_PASSWORD = 'user_form_type_password';

    private _type: string = UserFormHandler.USER_FORM_TYPE_PROFILE;
    private _secretQuestionsSubject: Subject<SecretQuestion[]> =  new Subject();
    private _user: User = new User();
    public secretQuestions: SecretQuestion[] = [];       


    constructor(private secretQuestionService: SecretQuestionService) {
        super();
    }

    public init(type: string = ""): void {
        if (type) {
            this._type = type;
        }

        let informationsFields = {
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            gender: ['', Validators.required],
            birthDate: ['', Validators.required],
            phone: ['', Validators.required],
            email: ['']
        };
        let passwordFields = {
            plainPassword: ['', [Validators.required, Validators.minLength(6), matchingValidator('confirmPlainPassword', true)]],
            confirmPlainPassword: ['', [Validators.required, matchingValidator('plainPassword')]],
        };

        let fields = {};
        switch (this._type) {
            case UserFormHandler.USER_FORM_TYPE_REGISTRATION:
                delete informationsFields.email;
                passwordFields['acceptedTermsOfService'] = [false, Validators.requiredTrue];
                passwordFields = Object.assign({ secretAnswers: this.formBuilder.array([]) }, passwordFields);
                fields = {
                    step1: this.formBuilder.group(informationsFields),
                    step2: this.formBuilder.group(passwordFields),
                }
                break;
            case UserFormHandler.USER_FORM_TYPE_PROFILE:                
                informationsFields['secretAnswers'] = this.formBuilder.array([]);
                fields = informationsFields;
                break;
            case UserFormHandler.USER_FORM_TYPE_PASSWORD:                
                fields = passwordFields;
                break;
            default: break;
        }

        this._form = this.formBuilder.group(fields);

        switch (this._type) {
            case UserFormHandler.USER_FORM_TYPE_PROFILE:
            case UserFormHandler.USER_FORM_TYPE_REGISTRATION:
                this.getSecretQuestions();
                break;
            default: break;
        }
    }

    public update(user: User): void {
        this._user = user;

        let data = {
            firstName: user.firstName,
            lastName: user.lastName,
            gender: user.gender,
            birthDate: user.birthDate,
            phone: user.phone,
            email: user.email
        };

        // Set data
        switch (this._type) {
            case UserFormHandler.USER_FORM_TYPE_REGISTRATION:
                delete data.email;
                this._form.get('step1').patchValue(data);
                break;
            case UserFormHandler.USER_FORM_TYPE_PROFILE:                
                this._form.patchValue(data);
                this.updateSecretAnswers(user.secretAnswers);
                break;            
            default: break;
        }
    }

    public getSecretQuestionsObservable(): Observable<SecretQuestion[]> {
        return this._secretQuestionsSubject.asObservable();
    }

    private getSecretQuestions(): void {
        this.secretQuestionService.getAllSecretQuestion().pipe(
            take(1)
        ).subscribe(res => {                    
            this.addSecretQuestions(res);            
        });
    }

    private addSecretQuestions(secretQuestions: SecretQuestion[] = []): void {
        this.secretQuestions = secretQuestions;

        const parentForm = (this._type == UserFormHandler.USER_FORM_TYPE_REGISTRATION) ? 'step2' : null;
        const secretAnswersFormArray = this.getFormArray('secretAnswers', this._form.get(parentForm));
        
        this.secretQuestions.forEach(secretQuestion => {
            secretAnswersFormArray.push(this.formBuilder.control('', Validators.required));
        });

        this._secretQuestionsSubject.next(this.secretQuestions);
    }

    private updateSecretAnswers(secretAnswers: SecretAnswer[] = []): void {
        const secretAnswersFormArray = this.getFormArray('secretAnswers', this._form);
        const getSecretQuestions$ = (this.secretQuestions.length > 0) ? of(this.secretQuestions) : this.getSecretQuestionsObservable();

        const getSecretQuestions = getSecretQuestions$.pipe(
            take(1)
        ).subscribe(res => {
            secretAnswersFormArray.patchValue(secretAnswers.map(item => item.answer));
        });
    }

    getUserValue(): any {
        let data = this._form.value;

        if (this._type == UserFormHandler.USER_FORM_TYPE_REGISTRATION) {
            data = Object.assign({}, this._form.get('step1').value, this._form.get('step2').value);
            data.secretAnswers = this.getSecretAnswersValue();
        }

        if (this._type == UserFormHandler.USER_FORM_TYPE_REGISTRATION || this._type == UserFormHandler.USER_FORM_TYPE_PASSWORD) {
            delete data.confirmPlainPassword;
        }
        
        if (this._type == UserFormHandler.USER_FORM_TYPE_REGISTRATION || this._type == UserFormHandler.USER_FORM_TYPE_PROFILE) {
            data.birthDate = moment(data.birthDate).format("YYYY-MM-DD");
        }

        if (this._type == UserFormHandler.USER_FORM_TYPE_PROFILE) {
            delete data.secretAnswers;
        }

        return data;
    }

    private getSecretAnswersValue(): any {  
        const parentForm = (this._type == UserFormHandler.USER_FORM_TYPE_REGISTRATION) ? 'step2' : null;      
        const secretAnswersFormArray = this.getFormArray('secretAnswers', this._form.get(parentForm));
        let secretAnswers: any[] = [];

        this.secretQuestions.forEach((secretQuestion, index) => {
            secretAnswers.push({
                secretQuestion: secretQuestion.getIRI(),
                answer: secretAnswersFormArray.at(index).value
            });             
        });

        return secretAnswers;
    }
}
