import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { from, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, combineAll, concatMap, finalize, mergeMap, pairwise, sequenceEqual, switchMap, takeWhile, tap } from 'rxjs/operators';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { MakingAppointmentStep, makingAppointmentStepMapping } from 'src/app/core/enums/making-appointment-step.enum';
import { Agency } from 'src/app/core/models/agency.model';
import { Partner } from 'src/app/core/models/partner.model';
import { AvailabilityService } from 'src/app/core/services/availability.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MakingAppointmentService } from 'src/app/core/services/making-appointment.service';
import { PartnerService } from 'src/app/core/services/partner.service';
import { SearchModalComponent } from '../search-modal/search-modal.component';

@Component({
  selector: 'app-making-appointment-process',
  templateUrl: './making-appointment-process.component.html',
  styleUrls: ['./making-appointment-process.component.scss']
})
@AutoUnsub()
export class MakingAppointmentProcessComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  private _params: any;
  public currentStep: string;
  public makingAppointmentStep = MakingAppointmentStep;
  public makingAppointmentStepMapping = makingAppointmentStepMapping;
  public bsModalRef: BsModalRef;
  

  constructor(public makingAppointmentService: MakingAppointmentService, private activatedRoute: ActivatedRoute,
    private partnerService: PartnerService, public loadingService: LoadingService,
    private modalService: BsModalService, private route: Router,
    private availabilityService: AvailabilityService) { }

  ngOnInit(): void {
    this.getParams();
  }

  getParams(): void {
    const getParams = this.activatedRoute.queryParams.subscribe(params => {
      this._params = params;
      this.setSearch();      
      this.getAppointmentStep();
    });

    this._observers.add(getParams);
  }

  setSearch(): void {
    if (this._params[MakingAppointmentService.searchFilter]) {
      this.makingAppointmentService.setSearch(this._params[MakingAppointmentService.searchFilter]);
    }
  }

  getAppointmentStep(): void {
    this.loadingService.showLoading();
    this.makingAppointmentService.resetCurrentStep();
    const getAppointmentStep = from(MakingAppointmentService.progress).pipe(
      takeWhile(val => this._params[this.makingAppointmentStepMapping[val]] 
        || (val == MakingAppointmentStep.AGENCY 
            && this._params[MakingAppointmentService.byPhoneFilter])
      ),
      concatMap(val => iif(() => val == MakingAppointmentStep.PARTNER, of(val).pipe(
        concatMap(val => {
          return (this.makingAppointmentService.getPartner()) ? of(this.makingAppointmentService.getNextStep(val)) : this.getPartner(this._params[this.makingAppointmentStepMapping[MakingAppointmentStep.PARTNER]]);
        })
      ),
        iif(() => val == MakingAppointmentStep.AGENCY, of(val).pipe(
          concatMap(val => {
            return (this.makingAppointmentService.getAgency()) ? of(this.makingAppointmentService.getNextStep(val)) : this.getAgency(this._params[this.makingAppointmentStepMapping[MakingAppointmentStep.PARTNER]], this._params[this.makingAppointmentStepMapping[MakingAppointmentStep.AGENCY]]);
          })
        ),
          iif(() => val == MakingAppointmentStep.AVAILABILITY, of(val).pipe(
            concatMap(val => {
              let beginAtParam = this._params[MakingAppointmentService.beginAtFilter];
              let beginAt = new Date(beginAtParam);

              if (!beginAtParam || !(beginAt instanceof Date)) {
                throwError('Invalid beginAt');
              }
              
              this.makingAppointmentService.setBeginAt(beginAt);
              return (this.makingAppointmentService.getAvailability()) ? of(this.makingAppointmentService.getNextStep(val)) : this.getAvailability(this._params[this.makingAppointmentStepMapping[MakingAppointmentStep.AVAILABILITY]]);
            })),
            of(this.makingAppointmentService.getNextStep(val))
          )
        ))
      ),
      finalize(() => {        
        this.loadingService.dismissLoading();
      })
    ).subscribe(res => {
      this.makingAppointmentService.setCurrentStep(res);
      if (MakingAppointmentStep.CONFIRMATION == res) {
        this.route.navigate(['confirmation'], { relativeTo: this.activatedRoute });
      }
    });

    this._observers.add(getAppointmentStep);
  }

  getPartner(slug: string): Observable<any> {
    return this.partnerService.getPartner(slug).pipe(
      tap(val => this.makingAppointmentService.setPartner(val)),
      switchMap(val => of(MakingAppointmentStep.AGENCY))
    );
  }

  getAgency(partnerSlug: string, agencyId: string): Observable<any> {
    return this.partnerService.getPartnerAgencies(partnerSlug).pipe(
      switchMap(res => {
        const agency = res.items.find(agency => agency.getId() == agencyId);

        if (!agency) {          
          if (this._params[MakingAppointmentService.byPhoneFilter]) {
            this.makingAppointmentService.setByPhone(true);
            return of(MakingAppointmentStep.AVAILABILITY);
          }

          return throwError('Agency could not be found.');
        }

        this.makingAppointmentService.setAgency(agency);

        return of(MakingAppointmentStep.AVAILABILITY);
      })
    );    
  }

  getAvailability(id: string): Observable<any> {
    return this.availabilityService.getAvailability(id).pipe(
      tap(val => {
        this.makingAppointmentService.setAvailability(val);
      }),
      switchMap(val => of(MakingAppointmentStep.CONFIRMATION))
    );
  }

  goToStep(makingAppointmentStep: MakingAppointmentStep): void {
    switch (makingAppointmentStep) {
      case MakingAppointmentStep.PARTNER:
        this.makingAppointmentService.setPartner(null);
        this.makingAppointmentService.setAgency(null);
        this.makingAppointmentService.setByPhone(false);
        this.makingAppointmentService.setAvailability(null);
        this.makingAppointmentService.setBeginAt(null);
        break;
      case MakingAppointmentStep.AGENCY:
        this.makingAppointmentService.setAgency(null);
        this.makingAppointmentService.setByPhone(false);
        this.makingAppointmentService.setAvailability(null);
        this.makingAppointmentService.setBeginAt(null);
        break;
      default: break;
    }

    this.route.navigate(['/prise-de-rendez-vous'], {
      queryParams: this.makingAppointmentService.getQueryParams(makingAppointmentStep)
    });
  }

  openMakingAppointmentSearchModal(): void {    
    this.bsModalRef = this.modalService.show(SearchModalComponent);
    this.bsModalRef.content.focusSearchElement = true;
  }

  cancelMakingAppointment(): void {
    this.makingAppointmentService.removeAppointmentSearch();
    this.route.navigate(['/']);
  }
}
