<div class="container">
    <div class="row">
       <div class="col-12">
            <app-header-logo></app-header-logo>
       </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h1 class="mb-5">Aide à la connexion</h1>
            <div class="mb-4 p-3 rounded bg-grey-92">
                <p class="mb-0">
                    J'ai mon identifiant, mais je ne retrouve plus mon mot de passe.
                </p>
                <a [routerLink]="['/mot-de-passe-oublie']" class="d-inline-block text-primary">
                    Je modifie mon mot de passe
                </a>
            </div>
            <div class="mb-4 p-3 rounded bg-grey-92">
                <p class="mb-0">
                    Mon lien de validation de compte n'est plus valide ou expiré.
                </p>
                <a [routerLink]="['/validation-compte-expiree']" class="d-inline-block text-primary">
                    Je demande un nouveau lien de validation
                </a>
            </div>
            <div class="mb-4 p-3 rounded bg-grey-92">
                <p class="mb-0">
                    J'ai oublié mon identifiant, je dois remplir un formlaire pour qu'on me le transmette par sms.
                </p>
                <a [routerLink]="['/identifiant-oublie']" class="d-inline-block text-primary">
                    Je remplis le formulaire
                </a>
            </div>
            <div class="mb-4 p-3 rounded bg-grey-92">
                <p class="mb-0">
                    J'ai perdu mon mot de passe et changé mon numéro de téléphone, je dois remplir un formlaire pour valider mon nouveau numéro.
                </p>
                <a [routerLink]="['/numero-de-telephone-modifie']" class="d-inline-block text-primary">
                    Je remplis le formulaire
                </a>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-12 text-center">
            <div>
                <a [routerLink]="['/login']" class="d-inline-block mx-auto text-primary">
                    Revenir à la connexion
                </a>
            </div>
        </div>
    </div>
    <div class="row mt-12">
        <div class="col-12 text-center">
            <app-footer-legal-notice></app-footer-legal-notice>
        </div>
    </div>
</div>