import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'instanceof'
})
export class InstanceofPipe implements PipeTransform {

  transform(value: any, name: string): boolean {
    return value.constructor.name === name;
  }

}
