import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Note } from '../models/note.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  private _apiRoutePath: string = "/notes";

  constructor(private apiService: ApiService) { }

  getNote(id: string, params: any = {}): Observable<Note> {
    return this.apiService.get(this._apiRoutePath + '/' + id, params).pipe(
      map(res => new Note().deserialize(res))
    );
  }

  createNote(data: any): Observable<Note> {
    return this.apiService.post(this._apiRoutePath, data).pipe(
      map(res => new Note().deserialize(res))
    );
  }

  updateNote(id: string, data: any): Observable<Note> {
    return this.apiService.put(this._apiRoutePath + '/' + id, data).pipe(
      map(res => new Note().deserialize(res))
    );
  }

  deleteNote(id: string): Observable<any> {
    return this.apiService.delete(this._apiRoutePath + '/' + id);
  }
}
