<div class="container">
    <div class="row">
       <div class="col-12">
            <app-header-logo></app-header-logo>
       </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h1 class="mb-5">Connexion</h1>
            <p *ngIf="hasAccountValidationRequest">Pour valider votre compte, veuillez vous connecter.</p>
            <form [formGroup]="authenticationForm" (ngSubmit)="submitAuthenticationForm()">
                <div class="form-group">                    
                    <input appFloatLabel id="username" type="text" formControlName="username" class="form-control"/>
                    <label for="username">Identifiant</label>
                    <ng-container
                        *ngIf="authenticationForm.get('username').invalid && (authenticationForm.get('username').dirty || authenticationForm.get('username').touched || submittedAuthenticationForm)">
                        <small class="text-danger"
                            *ngIf="authenticationForm.get('username').errors.required">
                            L'identifiant est obligatoire
                        </small>
                    </ng-container>  
                </div> 
                <div class="form-group">                    
                    <input appFloatLabel id="password" type="password" formControlName="password" class="form-control"/>
                    <label for="password">Mot de passe</label>
                    <ng-container
                        *ngIf="authenticationForm.get('password').invalid && (authenticationForm.get('password').dirty || authenticationForm.get('password').touched || submittedAuthenticationForm)">
                        <small class="text-danger"
                            *ngIf="authenticationForm.get('password').errors.required">
                            Le mot de passe est obligatoire
                        </small>
                    </ng-container>  
                </div> 
                <div class="mt-12 text-center">
                    <button type="submit" class="btn bg-primary text-white mx-auto mb-4">
                        Valider
                    </button>
                    <div class="mb-1">
                        <a [routerLink]="['/aide-connexion']" class="d-inline-block mx-auto text-primary">
                            Aide à la connexion
                        </a>
                    </div>
                    <div>
                        <a [routerLink]="['/inscription']" class="d-inline-block mx-auto text-primary">
                            Créer votre compte
                        </a>
                    </div>
                </div>                                
            </form>
        </div>
    </div>
    <div class="row mt-12">
        <div class="col-12 text-center">
            <app-footer-legal-notice></app-footer-legal-notice>
        </div>
    </div>
</div>