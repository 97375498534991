import { AfterContentChecked, Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AutoUnsub } from './core/decorators/auto-unsub.decorator';
import { HeaderService } from './core/services/header.service';
import { LoadingService } from './core/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
@AutoUnsub()
export class AppComponent implements OnInit, AfterContentChecked {

  private _observers: Subscription = new Subscription();
  public title: string = 'Clicnote';
  public loading: boolean = false;
  public hideHeader: boolean = false;
  public swipedHeader: boolean = false;
  public isFullscreen: boolean = false;
  public hasMainMenu: boolean = true;
  public componentClass: string = "";

  constructor(
    private headerService: HeaderService,
    private loadingService: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.setObservers();
  }

  ngAfterContentChecked(): void {
    if (this.loading != this.loadingService.isLoading()) {
      this.loading = this.loadingService.isLoading();
    }
  }

  setObservers(): void {
    this._observers.add(this.routerObserver());
  }

  routerObserver(): Subscription {
    return this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd) 
    ).subscribe(e => {      
        var activatedRoute = this.activatedRoute.firstChild;
        while (activatedRoute) {
          const regex = new RegExp('top-bar');
          const data = activatedRoute.snapshot.data;
          this.hideHeader = (null != data.hideHeader) ? data.hideHeader : false;
          this.isFullscreen = (null != data.isFullscreen) ? data.isFullscreen : false;
          this.componentClass = (null != data.componentClass) ? data.componentClass : "";
          (null != data.bodyClass) ? this.renderer.addClass(document.body, data.bodyClass) : this.renderer.setAttribute(document.body, 'class', "");   
          if (!regex.test(data.bodyClass) || null == data.bodyClass) {
            this.renderer.removeStyle(document.body, 'padding-top');
          }
          activatedRoute = activatedRoute.firstChild;
        }
        this.headerService.closeMenu();
    });
  }
}
