import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AppointmentService } from '../services/appointment.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentGuard implements CanActivate {

  constructor(private appointmentService: AppointmentService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!route.params.id) {
        return of(false);
      }

      return this.appointmentService.getAppointment(route.params.id).pipe(        
        switchMap(res => of(true)),
        catchError(error => {
          this.router.navigate(['/']);
          return of(false);
        })
      );
  }
  
}
