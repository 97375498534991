<div class="making-appointment-progress">
    <header #header class="making-appointment-progress__header">
        <div class="container position-relative bg-dark-grey py-4">
            <div class="steps-progress text-white">
                <button type="button" (click)="cancelMakingAppointment()" class="close text-white">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4>Votre besoin</h4>
                <button type="button" class="d-flex align-items-center text-white" (click)="openMakingAppointmentSearchModal()">
                    <span *ngIf="makingAppointmentService.getSearch(); else emptySearch">{{ makingAppointmentService.getSearch() }}</span>
                    <ng-template #emptySearch>
                        <span class="font-italic">Aucun besoin spécifié</span>
                    </ng-template>
                    <i class="fas fa-pencil-alt text-grey ml-3"></i>
                </button>
                <div class="mt-4" *ngIf="[makingAppointmentStep.AGENCY, makingAppointmentStep.AVAILABILITY].includes(makingAppointmentService.getCurrentStep())">                
                    <h4>Votre rendez-vous</h4>
                    <div class="d-flex flex-wrap align-items-center">
                        <button type="button" class="d-flex align-items-center text-white mr-4 my-2" (click)="goToStep(makingAppointmentStep.PARTNER)" *ngIf="makingAppointmentService.getPartner() as partner">
                            <div class="p-1 bg-white rounded overflow-hidden border border-92 mr-2 flex-shrink-0">
                                <div class="img-wrapper" [style]="{ 'background-image': 'url(' + partner.getLogoPath() + ')' }">
                                    <img src="assets/img/placeholders/square-thumbnail.png" class="img-fluid" alt="partner.name" />            
                                </div>
                            </div>
                            <span class="text-left">{{ partner.name }}</span>                        
                            <i class="fas fa-pencil-alt text-grey ml-3 align-middle"></i>
                        </button>
                        <button type="button" class="d-flex align-items-center text-white my-2" (click)="goToStep(makingAppointmentStep.AGENCY)" *ngIf="makingAppointmentService.getAgency() || makingAppointmentService.getByPhone()">
                            <div class="map-marker rounded-circle mr-2 bg-black d-flex align-items-center justify-content-center flex-shrink-0">
                                <i class="fas text-white" [ngClass]="{'fa-map-marker-alt': makingAppointmentService.getAgency(), 'fa-phone': makingAppointmentService.getByPhone()}"></i>
                            </div>
                            <span *ngIf="makingAppointmentService.getAgency(); else byPhoneTemplate" [innerHTML]="makingAppointmentService.getAgency().address.getFullName(true)" class="text-left"></span> 
                            <ng-template #byPhoneTemplate>Par téléphone</ng-template>                       
                            <i class="fas fa-pencil-alt text-grey ml-3 align-middle"></i>
                        </button>
                    </div>                    
                </div>
            </div>
            <div class="steps-progress-bar d-flex flex-nowrap" *ngIf="makingAppointmentService.getCurrentStep() as currentStep">
                <div *ngFor="let i of [0,1,2]" class="step-bar col" [ngClass]="{ 'bg-secondary': i == 0, 'bg-grey-50': i == 1 && ![makingAppointmentStep.AGENCY, makingAppointmentStep.AVAILABILITY].includes(currentStep), 'bg-grey-60': i == 2 && currentStep != makingAppointmentStep.AVAILABILITY, 'bg-success': i == 1 && [makingAppointmentStep.AGENCY, makingAppointmentStep.AVAILABILITY].includes(currentStep), 'bg-warning': i == 2 && currentStep == makingAppointmentStep.AVAILABILITY }"></div>
            </div>   
        </div>        
    </header>
    <ng-container *ngIf="!loadingService.isLoading()">
        <div class="making-appointment-progress__step py-6" *ngIf="makingAppointmentService.getCurrentStep() as currentStep">
            <div class="container">
                <div [ngSwitch]="currentStep">
                    <app-appointment-slots-listing *ngSwitchCase="makingAppointmentStep.AVAILABILITY" [agency]="makingAppointmentService.getAgency()" [byPhone]="makingAppointmentService.getByPhone()"></app-appointment-slots-listing>
                    <app-agencies-listing *ngSwitchCase="makingAppointmentStep.AGENCY" [partner]="makingAppointmentService.getPartner()"></app-agencies-listing>
                    <app-partners-listing *ngSwitchDefault [search]="makingAppointmentService.getSearch()"></app-partners-listing>
                </div>
            </div>
        </div>
    </ng-container>    
</div>