import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class OrderUtil {

    originalOrder(a: any, b: any): number {
        return 1;
    }
}
