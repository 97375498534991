import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MakingAppointmentConfirmationGuard } from '../core/guards/making-appointment-confirmation.guard';
import { MakingAppointmentConfirmationComponent } from './making-appointment-confirmation/making-appointment-confirmation.component';
import { MakingAppointmentProcessComponent } from './making-appointment-process/making-appointment-process.component';

const routes: Routes = [
  {
    path: "",
    component: MakingAppointmentProcessComponent,
    runGuardsAndResolvers: 'always'
  },
  {
    path: "confirmation",
    component: MakingAppointmentConfirmationComponent,
    canActivate: [MakingAppointmentConfirmationGuard],
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MakingAppointmentRoutingModule { }
