<app-top-bar [title]="title" [leftAction]="topBarLeftAction"></app-top-bar>
<div class="edit-my-profile py-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h2>Identifiant</h2>
                <p class="mb-0 text-grey-35 font-italic font-weight-semi-bold">{{ me.username }}</p>
            </div>
        </div>
        <div class="row mt-6">
            <div class="col-12">
                <h2 class="mb-3">Mes coordonnées personnelles</h2>
                <form [formGroup]="profileForm" (ngSubmit)="submitProfileForm()">
                    <div class="form-group">
                        <input appFloatLabel id="firstName" type="text" formControlName="firstName"
                            class="form-control" />
                        <label for="firstName">Prénom*</label>
                        <ng-container
                            *ngIf="profileForm.get('firstName').invalid && (profileForm.get('firstName').dirty || profileForm.get('firstName').touched || submittedProfileForm)">
                            <small class="text-danger" *ngIf="profileForm.get('firstName').errors.required">
                                Le prénom est obligatoire
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <input appFloatLabel id="lastName" type="text" formControlName="lastName"
                            class="form-control" />
                        <label for="lastName">Nom*</label>
                        <ng-container
                            *ngIf="profileForm.get('lastName').invalid && (profileForm.get('lastName').dirty || profileForm.get('lastName').touched || submittedProfileForm)">
                            <small class="text-danger" *ngIf="profileForm.get('lastName').errors.required">
                                Le prénom est obligatoire
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <ng-select appearance="outline" [searchable]="false" [clearable]="false"
                            [items]="genderMapping|keyvalue:orderUtil.originalOrder" bindValue="key" bindLabel="value"
                            formControlName="gender" labelForId="gender"
                            class="ng-select--primary text-left {{ profileForm.get('gender').value != '' ? 'float-label' : '' }}">
                        </ng-select>
                        <label for="gender">Civilité*</label>
                        <ng-container
                            *ngIf="profileForm.get('gender').invalid && (profileForm.get('gender').dirty || profileForm.get('gender').touched || submittedProfileForm)">
                            <small class="text-danger" *ngIf="profileForm.get('gender').errors.required">
                                La civilité est obligatoire
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <input appFloatLabel id="birthDate" class="form-control" #datepickerBirthDate="bsDatepicker"
                            bsDatepicker formControlName="birthDate"
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', isAnimated: true, returnFocusToInput: true, containerClass: 'theme-default' }">
                        <label for="birthDate">Date de naissance*</label>
                        <ng-container
                            *ngIf="profileForm.get('birthDate').invalid && (profileForm.get('birthDate').dirty || profileForm.get('birthDate').touched || submittedProfileForm)">
                            <small class="text-danger" *ngIf="profileForm.get('birthDate').errors.required">
                                La date de naissance est obligatoire
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <input appFloatLabel id="phone" type="text" formControlName="phone" class="form-control" />
                        <label for="phone">Numéro de téléphone*</label>
                        <ng-container
                            *ngIf="profileForm.get('phone').invalid && (profileForm.get('phone').dirty || profileForm.get('phone').touched || submittedProfileForm)">
                            <small class="text-danger" *ngIf="profileForm.get('phone').errors.required">
                                Le numéro de téléphone est obligatoire
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <input appFloatLabel id="email" type="email" formControlName="email" class="form-control" />
                        <label for="email">Adresse email</label>
                    </div>
                    <ng-container formArrayName="secretAnswers" *ngIf="userProfileFormHandler.getFormArray('secretAnswers').controls as secretAnswersControls">
                        <div class="form-group" *ngFor="let secretAnswerControl of secretAnswersControls; let i = index">                    
                            <input appFloatLabel id="secretQuestion{{i}}" type="text" [formControl]="secretAnswerControl" class="form-control"/>
                            <label for="secretQuestion{{i}}">{{ userProfileFormHandler.secretQuestions[i].question }}</label>
                            <ng-container
                                *ngIf="secretAnswerControl.invalid && (secretAnswerControl.dirty || secretAnswerControl.touched || submittedProfileForm)">
                                <small class="text-danger"
                                    *ngIf="secretAnswerControl.errors.required">
                                    La réponse est obligatoire
                                </small>
                            </ng-container>  
                        </div> 
                    </ng-container>
                    <div class="mt-12 text-center">
                        <button type="submit" class="btn bg-primary text-white mx-auto mb-4">
                            Confirmer
                        </button>
                    </div>
                    <div class="mt-6 text-right">
                        <p class="small">* champs obligatoires</p>
                    </div>
                </form>
            </div>
        </div>
        <div class="row mt-6">
            <div class="col-12">
                <h2 class="mb-3">Mot de passe</h2>
                <form [formGroup]="passwordForm" (ngSubmit)="submitPasswordForm()">
                    <div class="form-group">
                        <input appFloatLabel id="plainPassword" type="password" formControlName="plainPassword"
                            class="form-control" />
                        <label for="plainPassword">Mot de passe</label>
                        <ng-container
                            *ngIf="passwordForm.get('plainPassword').invalid && (passwordForm.get('plainPassword').dirty || passwordForm.get('plainPassword').touched || submittedPasswordForm)">
                            <small class="text-danger"
                                *ngIf="passwordForm.get('plainPassword').errors.required">
                                Le mot de passe est obligatoire
                            </small>
                            <small class="text-danger"
                                *ngIf="passwordForm.get('plainPassword').errors.minlength">
                                Le mot de passe doit comporter 6 caractères minimum
                            </small>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <input appFloatLabel id="confirmPlainPassword" type="password"
                            formControlName="confirmPlainPassword" class="form-control" />
                        <label for="confirmPlainPassword">Confirmation du mot de passe</label>
                        <ng-container
                            *ngIf="passwordForm.get('confirmPlainPassword').invalid && (passwordForm.get('confirmPlainPassword').dirty || passwordForm.get('confirmPlainPassword').touched || submittedPasswordForm)">
                            <small class="text-danger"
                                *ngIf="passwordForm.get('confirmPlainPassword').errors.required">
                                La confirmation du mot de passe est obligatoire
                            </small>
                            <small class="text-danger"
                                *ngIf="passwordForm.get('confirmPlainPassword').errors.matching">
                                Les deux mots de passe doivent être identiques
                            </small>
                        </ng-container>
                    </div>
                    <div class="mt-12 text-center">
                        <button type="submit" class="btn bg-primary text-white mx-auto mb-4">
                            Confirmer
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>