import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Note } from '../models/note.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NotesSummaryService {

  private _apiRoutePath: string = "/notes-summaries";

  constructor(private apiService: ApiService) { }

  getAllNotes(id: string, page: number = 1, params: any = {}): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Accept': 'application/ld+json'
      }),
      params: {
        page: page
      }
    };

    options.params = Object.assign(options.params, params);

    return this.apiService.get(this._apiRoutePath + '/' + id + '/notes', options).pipe(
      map(res => {
        return {
          totalItems: res['hydra:totalItems'],
          items: res['hydra:member'].map(item => new Note().deserialize(item))
        };
      })
    );
  }
}
