<div class="container">
    <div class="row">
       <div class="col-12">
            <app-header-logo></app-header-logo>
       </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <h1 class="mb-5">Demande envoyée !</h1>
            <p>
                Nous avons bien reçu votre demande, pour y répondre favorablement, il se peut que nous vous contactons prochainement.
            </p>
            <div class="mt-12">
                <a [routerLink]="['/login']" class="d-inline-block mx-auto text-primary">
                    Retour
                </a>
            </div>
        </div>
    </div>
    <div class="row mt-12">
        <div class="col-12 text-center">
            <app-footer-legal-notice></app-footer-legal-notice>
        </div>
    </div>
</div>