import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Sharing } from '../models/sharing.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  private _apiRoutePath: string = "/sharings";

  constructor(private apiService: ApiService) { }

  createSharing(data: any): Observable<Sharing> {
    return this.apiService.post(this._apiRoutePath, data).pipe(
      map(res => new Sharing().deserialize(res))
    );
  }
}
