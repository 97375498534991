export enum AppointmentSlot {
    HALF_HOUR = 'appointment_slot_half_hour',
    THREE_QUARTERS = 'appointment_slot_three_quarters',
    HOUR = 'appointment_slot_hour',
    TWO_HOURS = 'appointment_slot_two_hours'
}

export const appointmentSlotSeconds: any = {
    [AppointmentSlot.HALF_HOUR]: 1800,
    [AppointmentSlot.THREE_QUARTERS]: 2700,
    [AppointmentSlot.HOUR]: 3600,
    [AppointmentSlot.TWO_HOURS]: 7200
};
