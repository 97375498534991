import { Component, Input, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { Appointment } from 'src/app/core/models/appointment.model';
import { Sharing } from 'src/app/core/models/sharing.model';
import { WorkStation } from 'src/app/core/models/work-station.model';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NoteService } from 'src/app/core/services/note.service';
import { SharingService } from 'src/app/core/services/sharing.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Note } from 'src/app/core/models/note.model';
import { NoteCommentService } from 'src/app/core/services/note-comment.service';
import { NoteComment } from 'src/app/core/models/note-comment.model';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
@AutoUnsub()
export class NoteComponent implements OnInit {

  @Input() public note: Note;
  @Input() public appointment: Appointment;
  @Input() public isEditable: boolean = true;
  @Input() public isShareable: boolean = true;
  @Input() public enableComment: boolean = false;
  private _observers: Subscription = new Subscription();
  private _formBuilder: FormBuilder = new FormBuilder();
  private _sharingsPage: number = 1;
  public modalRef: BsModalRef;
  public sharingsLoading: boolean = false;
  public sharings: Sharing[] = [];
  public workStations: WorkStation[] = [];
  public editNoteForm: FormGroup;
  public editNoteCommentForm: FormGroup;
  public shareNoteForm: FormGroup;
  public noteEditorConfig: any = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ]
  };

  constructor(private modalService: BsModalService, private noteService: NoteService,
    private loadingService: LoadingService, private errorService: ErrorService,
    private toastrService: ToastrService, private sharingService: SharingService,
    private appointmentService: AppointmentService, private noteCommentService: NoteCommentService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.note && !changes.note.firstChange) {
      this.updateNoteForms();
    }
  }

  ngOnInit(): void {
    this.prepareEditNoteForm();
    this.prepareShareNoteForm();

    if (this.enableComment) {
      this.prepareEditNoteCommentForm();
    }
  }

  prepareEditNoteForm(): void {
    this.editNoteForm = this._formBuilder.group({
      content: [this.note ? this.note.content : '']
    });
  }

  updateNoteForms(): void {
    this.editNoteForm.get('content').patchValue(this.note ? this.note.content : '');
    this.shareNoteForm.get('note').setValue(this.note);
  }

  prepareShareNoteForm(): void {
    this.shareNoteForm = this._formBuilder.group({
      note: [this.note, [Validators.required]],
      workStations: this._formBuilder.array([], [Validators.required])
    })
  }

  prepareEditNoteCommentForm(): void {
    this.editNoteCommentForm = this._formBuilder.group({
      content: [this.note && this.note.noteComment ? this.note.noteComment.content : '']
    });
  }

  canBeEdited(): boolean {
    const now = new Date();
    const appointment = this.note ? this.note.appointment : this.appointment;

    if (!appointment || !this.isEditable) {
      return false;
    }

    return !appointment.canceledAt && (moment(appointment.endAt).add(2, 'days') >= moment(now));
  }

  canBeShared(): boolean {
    const appointment = this.note ? this.note.appointment : this.appointment;

    if (!this.isShareable) {
      return false;
    }

    return appointment.canceledAt === null;
  }

  openModal(template: TemplateRef<any>, type: string = '') {
    if (('shareNote' == type && !this.canBeShared()) ||
      ('editNote' == type && !this.canBeEdited()) ||
      ('editNoteComment' == type && !this.enableComment)) {
      return;
    }

    if ('shareNote' == type) {
      this._sharingsPage = 1;
      this.workStations = [];
      this.sharings = [];
      this.loadSharings();
      (this.shareNoteForm.get('workStations') as FormArray).clear();
    }

    this.modalRef = this.modalService.show(template);
  }

  onEditNoteFormSubmit(): void {
    if (this.editNoteForm.valid) {
      if (!this.note && !this.editNoteForm.value.content) {
        this.modalRef.hide();
        return;
      }

      this.loadingService.showLoading();

      let editNote$ = new Observable();
      if (this.note && !this.editNoteForm.value.content) {
        editNote$ = this.noteService.deleteNote(this.note.getId());
      } else {
        editNote$ = this.note
          ? this.noteService.updateNote(this.note.getId(), this.editNoteForm.value)
          : this.noteService.createNote(Object.assign({ appointment: this.appointment.getIRI() }, this.editNoteForm.value))
      }

      const editNote = editNote$.pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        this.shareNoteForm.get('note').patchValue(res);
        this.note = (res instanceof Note) ? res : null;

        this.toastrService.success("Notes modifiées avec succès.", "Félicitations !");
        this.modalRef.hide();
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(editNote);
    }
  }

  onEditNoteCommentFormSubmit(): void {
    if (this.editNoteCommentForm.valid) {
      const noteComment = this.note ? this.note.noteComment : null;

      if (!noteComment && !this.editNoteCommentForm.value.content) {
        this.modalRef.hide();
        return;
      }

      this.loadingService.showLoading();

      let editNoteComment$ = new Observable();
      if (noteComment && !this.editNoteCommentForm.value.content) {
        editNoteComment$ = this.noteCommentService.deleteNoteComment(noteComment.getId());
      } else {
        editNoteComment$ = noteComment
          ? this.noteCommentService.updateNoteComment(noteComment.getId(), this.editNoteCommentForm.value)
          : this.noteCommentService.createNoteComment(Object.assign({ note: this.note.getIRI() }, this.editNoteCommentForm.value))
      }

      const editNoteComment = editNoteComment$.pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        this.note.noteComment = (res instanceof NoteComment) ? res : null;

        this.toastrService.success("Commentaire modifié avec succès.", "Félicitations !");
        this.modalRef.hide();
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(editNoteComment);
    }
  }

  onShareNoteFormSubmit(): void {
    if (this.shareNoteForm.valid) {
      this.loadingService.showLoading();
      let data = _.cloneDeep(this.shareNoteForm.value);

      data.note = data.note.getIRI();
      data.workStations = data.workStations.map(item => item.getIRI());

      const shareNote = this.sharingService.createSharing(data).pipe(
        finalize(() => {
          this.loadingService.dismissLoading();
        })
      ).subscribe(res => {
        this.toastrService.success("Notes partagées.", "Félicitations !");
        this.modalRef.hide();
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(shareNote);
    }
  }

  loadSharings(): void {
    if (this._sharingsPage && !this.sharingsLoading) {
      this.sharingsLoading = true;

      const getSharings: Subscription = this.appointmentService.getAllAppointmentSharings(this._sharingsPage, this.note.appointment.getId()).pipe(
        finalize(() => {
          this.sharingsLoading = false;
        })
      ).subscribe(res => {
        this.sharings = this.sharings.concat(res.items);
        this._sharingsPage = (res.totalItems > this.sharings.length) ? (this._sharingsPage + 1) : 0;
        this.sharings.forEach(sharing => {
          this.workStations = this.workStations.concat(sharing.workStations.filter(workStation => !this.workStations.find(w => w.getId() == workStation.getId())));
        });
      });

      this._observers.add(getSharings);
    }
  }

  addSharingWorkStation(workStation: WorkStation): void {
    (this.shareNoteForm.get('workStations') as FormArray).push(this._formBuilder.control(workStation));
  }

  removeSharingWorkStation(data: any): void {
    (this.shareNoteForm.get('workStations') as FormArray).removeAt(data.index);
  }

}
