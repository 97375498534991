import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OrderUtil } from 'src/app/shared/utils/order.util';
import { AutoUnsub } from '../decorators/auto-unsub.decorator';
import { genderMapping } from '../enums/gender.enum';
import { UserInformationRequestType } from '../enums/user-information-request-type.enum';
import { UserInformationRequestFormHandler } from '../form-handlers/user-information-request.form-handler';
import { ErrorService } from '../services/error.service';
import { LoadingService } from '../services/loading.service';
import { SecretQuestionService } from '../services/secret-question.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-username-request',
  templateUrl: './username-request.component.html',
  styleUrls: ['./username-request.component.scss']
})
@AutoUnsub()
export class UsernameRequestComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  public userInformationRequestFormHandler: UserInformationRequestFormHandler;
  public usernameRequestForm: FormGroup;
  public submittedUsernameRequestForm: boolean = false;
  public genderMapping: any = genderMapping;

  constructor(private secretQuestionService: SecretQuestionService, private localeService: BsLocaleService,
    private loadingService: LoadingService, private userService: UserService, 
    private router: Router, private errorService: ErrorService,
    public orderUtil: OrderUtil) { 
    this.userInformationRequestFormHandler = new UserInformationRequestFormHandler(secretQuestionService);
  }

  ngOnInit(): void {
    this.localeService.use('fr');
    this.prepareUsernameRequestForm();
  }

  prepareUsernameRequestForm(): void {
    this.userInformationRequestFormHandler.init();
    this.usernameRequestForm = this.userInformationRequestFormHandler.getForm();
  }

  submitUsernameRequestForm(): void {
    this.submittedUsernameRequestForm = true;

    if (this.usernameRequestForm.valid) {
      this.loadingService.showLoading();

      const usernameRequest = this.userService.createInformationRequest(UserInformationRequestType.USERNAME, this.userInformationRequestFormHandler.getUserInformationValue()).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        this.router.navigateByUrl('/demande-envoyee');
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(usernameRequest);
    }
  }

}
