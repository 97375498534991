import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppError } from '../interfaces/app-error';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private _errors: AppError[] = [];


  constructor(private toastr: ToastrService) {}

  getErrors(): any[] {
    return this._errors;
  }

  removeError(index: number): this {
    this._errors.splice(index, 1);

    return this;
  }

  addError(error: any): this {
    if (error.error && error.error.violations) {
      error.error.violations.forEach(violation => {
        this._errors.push({
          title: "",
          message: violation.message
        });
      });
    } else {
      this._errors.push({
        title: "Erreur",
        message: error.error.message ?? error.error.detail
      });
    }
    return this;
  }

  show(): void {
    this._errors.forEach(error => {
      this.toastr.error(error.message, error.title);
    });
    this.clear();
  }

  clear(): void {
    this._errors = [];
  }
}
