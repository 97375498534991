import { Serializable } from "../interfaces/serializable";
import { Address } from "./address.model";
import { Agency } from "./agency.model";
import { Availability } from "./availability.model";
import { Partner } from "./partner.model";
import { User } from "./user.model";
import { WorkStation } from "./work-station.model";
import * as moment from 'moment';

export class Appointment implements Serializable {

    static readonly CANCELED_BY_USER = 'user';
    static readonly CANCELED_BY_COUNSELOR = 'counselor';
    static readonly CANCELLATION_LIMIT = 172800;

    private _uuid: string = null;
    public user: User = null;
    public availability: Availability = null;
    public beginAt: Date = null;
    public endAt: Date = null;
    public summary: any = null;
    public note: string = null;
    public canceledAt: Date = null;
    public canceledBy: string = null;
    public createdAt: Date = null;
    public updatedAt: Date = null;

    public getId(): string {
        return this._uuid;
    }

    public getIRI(): string {
        return '/api/appointments/' + this.getId();
    }

    public getFullDate(): string {
        if (!this.beginAt) {
            return "";
        }

        const weekDay = moment(this.beginAt).format('dddd').charAt(0).toUpperCase() + moment(this.beginAt).format('dddd').slice(1);
        const day = moment(this.beginAt).format('DD MMMM')
        const hour = moment(this.beginAt).format('HH') + 'h' + moment(this.beginAt).format('mm');
        
        return weekDay + ' ' + day + ' - ' + hour;
    }

    public isComingSoon(): boolean {
        const now = new Date();

        return moment(this.beginAt) > moment(now);
    }

    public diffWithNow(): any {
        const now = new Date();        
        const duration = moment.duration(moment(this.beginAt).diff(moment(now)));

        return {
            minutes: duration.minutes(),
            hours: duration.hours(),
            days: duration.days()
        };
    }

    public getWorkStation(): WorkStation {
        return this.availability.workStation;
    }

    public getAddress(): Address {
        return this.availability.workStation.agency.address;
    }

    public getWorkStationUser(): User {
        return this.availability.workStation.user;
    }

    public getPartner(): Partner {
        return this.availability.workStation.agency.partner;
    }

    public getAgency(): Agency {
        return this.availability.workStation.agency;
    }

    public canBeCanceled(): boolean {
        const now = new Date();

        return !this.canceledAt && moment(this.beginAt).subtract(Appointment.CANCELLATION_LIMIT, 'seconds') > moment(now);
    }

    public update(data: any = {}): this {
        if (!data) {
            return null;
        }

        const properties = Object.getOwnPropertyNames(this);
        Object.keys(data).forEach((key) => {
            switch (key) {
                case '_uuid': break;                
                default:
                    if (properties.includes(key)) {
                        this[key] = data[key];
                    }
                    break;
            }
        });

        return this;
    }

    public deserialize(input: any = {}): this {
        if (!input) {
            return null;
        }

        const properties = Object.getOwnPropertyNames(this);
        Object.keys(input).forEach((key) => {
            if (input[key] !== null) {
                switch (key) {
                    case 'uuid':
                        this._uuid = input[key];
                        break;
                    case 'user':
                        this[key] = new User().deserialize(input[key]);
                        break;
                    case 'availability':                    
                        this[key] = new Availability().deserialize(input[key]);
                        if (this[key]['workStation'] == null) {              
                            if (input['summary']['workStation'] !== null) {
                                this.availability.workStation = new WorkStation().deserialize(input['summary']['workStation']);
                            }
                        }
                        break;
                    case 'summary':
                        this[key] = input[key];                     
                        break;
                    case 'beginAt':
                    case 'endAt':
                    case 'canceledAt':
                    case 'createdAt':
                    case 'updatedAt':
                        this[key] = new Date(input[key]);
                        break;                    
                    default:
                        if (properties.includes(key)) {
                            this[key] = input[key];
                        }
                        break;
                }
            }
        });

        return this;
    }

    public serialize(): any {
        var output = {};

        Object.getOwnPropertyNames(this).forEach(property => {
            switch (property) {
                case '_uuid':
                    if (this[property]) {
                        output['uuid'] = this[property];
                    }
                    break;                
                default:
                    output[property] = this[property];
                    break;
            }
        });

        return output;
    }
}
