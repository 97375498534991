import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { MeService } from './me.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _authentication = new BehaviorSubject<boolean>(false);
  private _apiMeRoutePath: string = '/users/me';

  constructor(private apiService: ApiService,
    private meService: MeService) {
  }

  getAuthenticatedUser(): Observable<any> {
    return this.apiService.get(this._apiMeRoutePath).pipe(
      map(user => {
        return new User().deserialize(user)
      })
    );
  }

  isAuthenticated(): Observable<boolean> {
    return this._authentication.asObservable();
  }

  updateAuthentication(authenticated: boolean) {
    this._authentication.next(authenticated);
  }

  authenticateAnonymous(): Observable<any> {
    return this.apiService.getAccessToken().pipe(
      catchError(error => {
        return throwError(error);
      }),
      switchMap(response => {
        this.apiService.setCookies(response);
        this.apiService.setAuthorization(response.token);

        return of(true);
      })
    );
  }

  authenticate(data: any = {}, params: any = null): Observable<any> {
    return this.apiService.getAccessToken(data, params).pipe(
      switchMap(response => {
        this.apiService.setCookies(response);
        this.apiService.setAuthorization(response.token);

        return this.getAuthenticatedUser();
      }),
      catchError(error => {
        this.apiService.removeCookies();
        this.meService.deleteMe();
        this._authentication.next(false);

        return throwError(error);
      }),
      switchMap(user => {
        this.meService.storeMe(user);
        this._authentication.next(true);

        return of(true);
      })
    );
  }

  public logout(): Observable<any> {
    this._authentication.next(false);
    this.apiService.removeCookies();

    return of(true);
  }
}
