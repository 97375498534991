<div class="folder-thumbnail">
    <form *ngIf="showRenameFolder; else showFolder" [formGroup]="renameFolderForm" (ngSubmit)="submitRenameFolderForm()">
        <div class="d-flex align-items-center py-2">
            <div class="folder-thumbnail__icon d-flex align-items-center justify-content-center">
                <i class="fas fa-folder fs-4"></i>
            </div>
            <input type="text" #renameFolder class="form-control mx-3 w-25 flex-grow-1" formControlName="title" />
            <button type="submit" class="text-secondary btn p-1 fs-4" title="Valider">
                <i class="fas fa-check"></i>
            </button>
            <button type="button" class="text-danger btn p-1 fs-4" title="Annuler" (click)="showRenameFolder = false">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <ng-container *ngIf="renameFolderForm.get('title').invalid && (renameFolderForm.get('title').dirty || renameFolderForm.get('title').touched)">
            <small class="text-danger"
                *ngIf="renameFolderForm.get('title').errors.required">
                Le nom du dossier est obligatoire
            </small>
        </ng-container> 
    </form>
    <ng-template #showFolder>
        <div class="d-flex align-items-center">
            <button type="button" (click)="goToFolder()" (press)="select()" class="flex-fill overflow-hidden">
                <div class="d-flex align-items-center py-2">
                    <div class="folder-thumbnail__icon d-flex align-items-center justify-content-center flex-shrink-0">
                        <i class="fas fa-folder fs-4"></i>
                        <div class="mask-selection d-flex align-items-center justify-content-center" *ngIf="documentService.isInSelection(folder)">
                            <i class="fas fa-check fs-4"></i>
                        </div>
                    </div>
                    <h4 class="px-3 mb-0 text-left flex-fill" [ngClass]="{ 'text-grey-65': documentService.isInSelection(folder) }">{{ folder.title }}</h4>
                </div>
            </button>
            <div class="btn-group align-self-start py-2 flex-shrink-0" dropdown placement="bottom right">
                <button id="dropdown-btn-folder-{{ folder.getId() }}" dropdownToggle type="button" class="px-2 fs-4">
                    <i class="fas fa-ellipsis-v"></i>
                </button>
                <ul id="dropdown-menu-folder-{{ folder.getId() }}" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-alignment">
                    <li role="menuitem">
                        <button type="button" class="dropdown-item link" (click)="select()">
                            <ng-container *ngIf="!documentService.isInSelection(folder); else unSelect">
                                <i class="far fa-check-square"></i> Sélectionner
                            </ng-container>
                            <ng-template #unSelect>
                                <i class="far fa-square"></i> Désélectionner
                            </ng-template>
                        </button>
                    </li>
                    <li role="menuitem">
                        <button type="button" class="dropdown-item link" (click)="showRenameFolder = true">
                            <i class="fas fa-pencil-alt"></i> Renommer
                        </button>
                    </li>
                    <li role="menuitem">
                        <button type="button" class="dropdown-item text-danger link" (click)="openModal(deleteFolderModal)">
                            <i class="fas fa-trash-alt"></i> Supprimer
                        </button>
                    </li>                
                </ul>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #deleteFolderModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Suppression du dossier</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Vous êtes sur le point de supprimer le dossier <span class="font-weight-semi-bold">{{ folder.title }}</span>. En supprimant ce dossier, vous supprimerez également tous les fichiers et dossiers qu'il contient.</p>
        <button type="button" class="btn btn-danger w-100 mt-6" (click)="delete()">Supprimer</button>
    </div>
</ng-template>