<ul class="fs-1 list-unstyled list-inline">
    <li class="list-inline-item" [ngClass]="config.itemClass">
        <a class="font-weight-light" [routerLink]="['/', 'politique-de-confidentialite']">Politique de confidentialité</a>
    </li>
    <li class="list-inline-item" [ngClass]="config.itemClass">
        <a class="font-weight-light" [routerLink]="['/', 'conditions-generales-d-utilisation']">CGU</a>
    </li>
    <li class="list-inline-item" [ngClass]="config.itemClass">
        <a class="font-weight-light" [routerLink]="['/', 'mentions-legales']">Mentions légales</a>
    </li>
</ul>
