import { Archive } from '../enums/archive.enum';
import { Audio } from '../enums/audio.enum';
import { Image } from '../enums/image.enum';
import { PresentationFile } from '../enums/presentation-file.enum';
import { Spreadsheet } from '../enums/spreadsheet.enum';
import { Video } from '../enums/video.enum';
import { WordProcessor } from '../enums/word-processor.enum';
import { Serializable } from '../interfaces/serializable';

export class File implements Serializable {

    public originalName: string = "";
    public size: number = 0;
    public mimeType: string = null;    
    public dimensions: number[] = [];  
    public data: string = "";

    constructor() { }

    public isImage(): boolean {
        return (Object.values(Image) as string[]).includes(this.mimeType);
    }

    public isVideo(): boolean {
        return (Object.values(Video) as string[]).includes(this.mimeType);
    }

    public isAudio(): boolean {
        return (Object.values(Audio) as string[]).includes(this.mimeType);
    }

    public isSpreadsheet(): boolean {
        return (Object.values(Spreadsheet) as string[]).includes(this.mimeType);
    }

    public isPresentationFile(): boolean {
        return (Object.values(PresentationFile) as string[]).includes(this.mimeType);
    }

    public isWordProcessor(): boolean {
        return (Object.values(WordProcessor) as string[]).includes(this.mimeType);
    }

    public isArchive(): boolean {
        return (Object.values(Archive) as string[]).includes(this.mimeType);
    }

    public getType(): string {
        if (this.isImage()) {
            return 'image';
        } else if (this.isVideo()) {
            return 'video';
        } else if (this.isAudio()) {
            return 'audio';
        } else if (this.isSpreadsheet()) {
            return 'spreadsheet';
        } else if (this.isPresentationFile()) {
            return 'presentationFile';
        } else if (this.isWordProcessor()) {
            return 'wordProcessor';
        }  else if (this.isArchive()) {
            return 'archive';
        }

        return 'file';
    }

    public update(data: any = {}): this {
        if (!data) {
            return null;
        }

        const properties = Object.getOwnPropertyNames(this);
        Object.keys(data).forEach((key) => {
            switch (key) {
                default:
                    if (properties.includes(key)) {
                        this[key] = data[key];
                    }
                    break;
            }
        });

        return this;
    }

    public deserialize(input: any = {}): this {
        if (!input) {
            return null;
        }

        const properties = Object.getOwnPropertyNames(this);
        Object.keys(input).forEach((key) => {
            if (input[key] !== null) {
                switch (key) {
                    default:
                        if (properties.includes(key)) {
                            this[key] = input[key];
                        }
                        break;
                }
            }
        });

        return this;
    }

    public serialize(): any {
        var output = {};

        Object.getOwnPropertyNames(this).forEach(property => {
            switch (property) {
                default:
                    output[property] = this[property];
                    break;
            }
        });

        return output;
    }
}
