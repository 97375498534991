<div class="modal-header">
    <div class="row no-gutters align-items-center">
        <div class="col-2">
            <div class="p-2 bg-white rounded overflow-hidden border border-92">
                <div class="img-wrapper" [style]="{ 'background-image': 'url(' + partner.getLogoPath() + ')' }">
                    <img src="assets/img/placeholders/square-thumbnail.png" class="img-fluid" alt="partner.name" />            
                </div>
            </div>            
        </div>
        <div class="col-10 pl-3">
            <h4 class="mb-0">{{ partner.name }}</h4>
        </div>
    </div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="bg-grey-90 p-3 mb-3">
        <ul class="list-unstyled list-inline font-italic additional-informations mb-0 fs-2">
            <li class="list-item mb-1" *ngIf="partner.phone">
                <a href="tel:{{ partner.phone }}" class="link">
                    <i class="fas fa-phone-alt"></i>{{ partner.phone }}
                </a>
            </li>
            <li class="list-item mb-1" *ngIf="partner.email">
                <a href="mailto:{{ partner.email }}" class="link">
                    <i class="far fa-envelope"></i>{{ partner.email }}
                </a>
            </li>
            <li class="list-item" *ngIf="partner.website">
                <a href="{{ partner.getWebsiteUrl() }}" class="link" target="_blank">
                    <i class="fas fa-desktop"></i>{{ partner.website }}
                </a>
            </li>
        </ul>
    </div>
    <p [innerHtml]="partner.description"></p>
</div>