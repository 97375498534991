export enum MakingAppointmentStep {
    PARTNER = 'making_appointment_step_partner',
    AGENCY = 'making_appointment_step_agency',
    AVAILABILITY = 'making_appointment_step_availability',
    CONFIRMATION = 'making_appointment_step_confirmation',
}

export const makingAppointmentStepMapping: any = {
    [MakingAppointmentStep.PARTNER]: "partner",
    [MakingAppointmentStep.AGENCY]: "agency",
    [MakingAppointmentStep.AVAILABILITY]: "availability",
};
