import { AfterContentChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { MeService } from 'src/app/core/services/me.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
@AutoUnsub()
export class TopBarComponent implements OnInit, AfterContentChecked {

  @Input() public title: string = "";
  @Input() public leftAction: any = {
    id: 'menu',
    data: ''
  };
  @Input() public rightAction: any = {
    id: 'none',
    data: ''
  };
  @Output() public clickedRightAction: EventEmitter<any> = new EventEmitter();
  @ViewChild('topBar') topBar: ElementRef;
  private _observers: Subscription = new Subscription();
  public connectedUser: User;
  public sidebarIsActive: boolean = false;
  public swipedHeader: boolean = false;
  public today = new Date();
  public me: User;  

  constructor(private authService: AuthService, private router: Router, 
    private meService: MeService, private renderer: Renderer2,
    private el: ElementRef, private headerService: HeaderService) { }

  ngOnInit(): void {
    this.setObservers();
  }

  ngAfterContentChecked(): void {
    this.onResize();
  }

  @HostListener('window:resize', [])
  onResize() {
    if (this.topBar) {
      const height = `${this.topBar.nativeElement.offsetHeight}px`;
      this.renderer.setStyle(document.body, 'padding-top', height);
    }
  }

  setObservers(): void {
    this._observers.add(this.getAuthenticatedUser());
    this._observers.add(this.openedMenu());
  }

  getAuthenticatedUser(): Subscription {
    return this.authService.isAuthenticated().subscribe(res => {
      this.me = res ? this.meService.getMe() : null;
    });
  }

  openedMenu(): Subscription {
    return this.headerService.menuIsOpened().subscribe(res => {
      this.sidebarIsActive = res;
    });
  }

  closeMenu(): void {
    this.headerService.closeMenu();
  }

  logout(): void {
    const logout = this.authService.logout().subscribe(res => this.router.navigate(['/']));
    this._observers.add(logout);
  }

  triggerRightAction(): void {
    this.clickedRightAction.emit(null);
  }

  triggerDefaultLeftAction(): void {
    this.me ? this.headerService.openMenu() : this.router.navigate(['/']);
  }

}
