import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { AuthenticationComponent } from './authentication/authentication.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from '../shared/shared.module';
import { AppInitService } from './services/app-init.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { PasswordRequestComponent } from './password-request/password-request.component';
import { PasswordRequestSuccessComponent } from './password-request-success/password-request-success.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AppointmentsModule } from '../appointments/appointments.module';
import { HelpAuthenticationComponent } from './help-authentication/help-authentication.component';
import { UsernameRequestComponent } from './username-request/username-request.component';
import { PhoneUpdatingRequestComponent } from './phone-updating-request/phone-updating-request.component';
import { InformationRequestSuccessComponent } from './information-request-success/information-request-success.component';
import { RegistrationComponent } from './registration/registration.component';
import { Step1Component } from './registration/step1/step1.component';
import { Step2Component } from './registration/step2/step2.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { AccountValidationRequestComponent } from './account-validation-request/account-validation-request.component';
import { AccountValidationRequestSuccessComponent } from './account-validation-request-success/account-validation-request-success.component';
import { MakingAppointmentModule } from '../making-appointment/making-appointment.module';
import { NotesSummaryModule } from '../notes-summary/notes-summary.module';
import { AccountModule } from '../account/account.module';
import { DocumentsModule } from '../documents/documents.module';
import { StaticPageComponent } from './static-page/static-page.component';

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.initApp();
  }
}

@NgModule({
  declarations: [AuthenticationComponent, HomeComponent, NotFoundComponent, PasswordRequestComponent, PasswordRequestSuccessComponent, ResetPasswordComponent, HelpAuthenticationComponent, UsernameRequestComponent, PhoneUpdatingRequestComponent, InformationRequestSuccessComponent, RegistrationComponent, Step1Component, Step2Component, RegistrationSuccessComponent, AccountValidationRequestComponent, AccountValidationRequestSuccessComponent, StaticPageComponent],
  imports: [
    CommonModule,
    CoreRoutingModule,
    SharedModule,   
    AppointmentsModule ,
    MakingAppointmentModule,
    AppointmentsModule,
    NotesSummaryModule,
    AccountModule,
    DocumentsModule
  ]
})
export class CoreModule { 
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true }
      ]
    }
  }
}
