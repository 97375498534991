import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppointmentGuard } from '../core/guards/appointment.guard';
import { AppointmentComponent } from './appointment/appointment.component';
import { MyAppointmentsComponent } from './my-appointments/my-appointments.component';

const routes: Routes = [
  {
    path: ":id",
    component: AppointmentComponent,
    canActivate: [AppointmentGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: "**",
    component: MyAppointmentsComponent,
    runGuardsAndResolvers: 'always',
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppointmentsRoutingModule { }
