<div class="making-appointment-confirmation">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <app-header-logo></app-header-logo>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <ng-container [ngSwitch]="status">
                    <h1 *ngSwitchCase="makingAppointmentServiceClass.statusNotAuthenticated">Veuillez vous connecter !</h1>
                    <h1 *ngSwitchCase="makingAppointmentServiceClass.statusConfirmed">Rendez-vous confirmé !</h1>
                    <h1 *ngSwitchDefault>Veuillez confirmer le rendez-vous !</h1>
                </ng-container>                
            </div>
        </div>
        <div class="row mt-5">             
            <ng-container [ngSwitch]="status">
                <div class="col-12" *ngSwitchCase="makingAppointmentServiceClass.statusNotAuthenticated">
                    <p class="text-center">Vous devez être connecté pour pouvoir prendre rendez-vous. Votre rendez-vous est temporairement sauvegardé, vous devrez le confirmer une fois connecté.</p>
                </div>
                <div class="col-12" *ngSwitchCase="makingAppointmentServiceClass.statusConfirmed">
                    <p class="text-center">Votre rendez-vous avec <span *ngIf="appointmentSlot.availability.workStation.user.gender == gender.MALE; then genderMale else genderFemale" class="font-weight-bold"></span>
                        <ng-template #genderMale>Mr</ng-template>
                        <ng-template #genderFemale>Mme</ng-template>                
                        <span class="font-weight-bold"> {{ appointmentSlot.availability.workStation.user.getFullName() }} - {{ partner.name }} - le {{ appointmentSlot.beginAt|date:'d MMMM' }} à {{ appointmentSlot.beginAt|date:'HH' }}h{{ appointmentSlot.beginAt|date:'mm' }}</span>
                        <span *ngIf="byPhone; then appointmentByPhone else appointmentAgency" class="font-weight-bold"></span>
                        <ng-template #appointmentByPhone> par téléphone</ng-template>
                        <ng-template #appointmentAgency> au {{ agency.address.getFullName() }}</ng-template>
                        est bien enregistré, votre <span *ngIf="appointmentSlot.availability.workStation.user.gender == gender.MALE; then genderMaleCouncelor else genderFemaleCouncelor"></span>
                        <ng-template #genderMaleCouncelor>Interlocuteur</ng-template>
                        <ng-template #genderFemaleCouncelor>Interlocutrice</ng-template>
                        a bien reçu votre demande.<br />Vous recevrez un récapitulatif de votre rendez-vous par sms.
                    </p>
                </div>
                <div class="col-12 making-appointment-confirmation__appointment" *ngSwitchDefault>
                    <h2 class="mb-2">Votre rendez-vous</h2>
                    <div class="d-flex align-items-center py-2">
                        <div class="p-1 bg-white rounded overflow-hidden border border-92 mr-2 flex-shrink-0">
                            <div class="img-wrapper" [style]="{ 'background-image': 'url(' + partner.getLogoPath() + ')' }">
                                <img src="assets/img/placeholders/square-thumbnail.png" class="img-fluid" alt="partner.name" />            
                            </div>
                        </div>
                        <h4 class="mb-0">{{ partner.name }}</h4>                   
                    </div>  
                    <div class="d-flex align-items-center w-100 py-2">
                        <div
                            class="map-marker rounded-circle mr-2 p-2 bg-dark-grey d-flex align-items-center justify-content-center flex-shrink-0">
                            <i class="fas text-white fa-2x" [ngClass]="{'fa-map-marker-alt': agency, 'fa-phone': byPhone}"></i>                    
                        </div>
                        <h4 class="mb-0">
                            <span *ngIf="agency; else byPhoneTemplate" [innerHTML]="agency.address.getFullName(true)"></span> 
                            <ng-template #byPhoneTemplate>
                                <span>Par téléphone</span>
                            </ng-template>
                        </h4>
                    </div>
                    <div class="d-flex align-items-center py-2 w-100">
                        <div class="map-marker rounded-circle p-2 bg-dark-grey d-flex align-items-center justify-content-center flex-shrink-0">
                            <i class="fas fa-calendar-day text-white fa-2x"></i>
                        </div>
                        <div class="text-left px-3">
                            <h4 class="mb-0">{{ appointmentSlot.getFullDate() }}</h4>
                            <p class="mb-0 text-grey-35 font-weight-medium">
                                <span *ngIf="appointmentSlot.availability.workStation.user.gender == gender.MALE; then genderMale else genderFemale"></span>
                                    <ng-template #genderMale>Interlocuteur</ng-template>
                                    <ng-template #genderFemale>Interlocutrice</ng-template>
                                : {{ appointmentSlot.availability.workStation.user.getFullName() }}
                            </p>
                        </div>          
                    </div> 
                </div>
            </ng-container>
        </div>
        <div class="row mt-12 text-center"> 
            <ng-container [ngSwitch]="status">
                <div class="col-12" *ngSwitchCase="makingAppointmentServiceClass.statusNotAuthenticated">
                    <a [routerLink]="['/login']" class="d-inline-block mx-auto text-primary">
                        Me connecter
                    </a>
                </div>
                <div class="col-12" *ngSwitchCase="makingAppointmentServiceClass.statusConfirmed">
                    <a [routerLink]="['/rendez-vous']" class="d-inline-block mx-auto text-primary">
                        Revenir à mon espace personnel
                    </a>
                </div>  
                <div class="col-12" *ngSwitchDefault>
                    <button type="button" class="btn bg-primary text-white mx-auto mb-3" (click)="confirmAppointment()">
                        Je confirme
                    </button>                    
                    <div class="mb-4">
                        <button type="button" (click)="goToPreviousStep()" class="d-inline-block mx-auto text-primary">
                            Revenir aux rendez-vous
                        </button>
                    </div>
                    <div>
                        <button type="button" (click)="cancelMakingAppointment()" class="d-inline-block mx-auto text-danger">
                            J'annule ma prise de rendez-vous
                        </button>
                    </div>
                </div>  
            </ng-container>          
        </div>
        <div class="row mt-12">
            <div class="col-12 text-center">
                <app-footer-legal-notice></app-footer-legal-notice>
            </div>
        </div>
    </div>
</div>