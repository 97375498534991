import { AfterViewInit, Component, ElementRef, HostListener, OnChanges, OnInit, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { expand, finalize, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { Folder } from 'src/app/core/models/folder.model';
import { User } from 'src/app/core/models/user.model';
import { FolderService } from 'src/app/core/services/folder.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { MeService } from 'src/app/core/services/me.service';
import * as _ from 'lodash';
import { ErrorService } from 'src/app/core/services/error.service';
import { UserService } from 'src/app/core/services/user.service';
import { Document } from 'src/app/core/models/document.model';
import { FileUploader } from 'ng2-file-upload';
import { DocumentService } from 'src/app/core/services/document.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SharingModalComponent } from '../sharing-modal/sharing-modal.component';

@Component({
  selector: 'app-my-documents',
  templateUrl: './my-documents.component.html',
  styleUrls: ['./my-documents.component.scss']
})
@AutoUnsub()
export class MyDocumentsComponent implements OnInit, AfterViewInit {

  @ViewChildren('newFolder') newFolderElement: QueryList<ElementRef>;
  @ViewChildren('myDocumentsSelection') myDocumentsSelectionElement: QueryList<ElementRef>;
  @ViewChild('myDocumentsContainer') myDocumentsContainer: ElementRef;
  private _observers: Subscription = new Subscription();
  private _folders: Folder[] = [];
  private _documents: Document[] = [];
  private _searchFolders: Folder[] = [];
  private _searchDocuments: Document[] = [];
  private _me: User;
  public title: string = "Mes documents";
  public showNewFolder: boolean = false;
  public searchForm: FormGroup;
  public createFolderForm: FormGroup;
  public elements: any[] = [];
  public searchElements: any[] = [];
  public folder: Folder = null;
  public uploader: FileUploader = new FileUploader({ itemAlias: 'file' });
  public searchInProgress: boolean = false;

  constructor(private folderService: FolderService, private formBuilder: FormBuilder,
    public loadingService: LoadingService, private route: ActivatedRoute,
    private meService: MeService, private errorService: ErrorService,
    private router: Router, private userService: UserService,
    public documentService: DocumentService, private renderer: Renderer2,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this._me = this.meService.getMe();
    this.prepareSearchForm();
    this.prepareCreateFolderForm();
    this.setUploader();    
    this.setObservers();
  }

  ngAfterViewInit() {
    this.newFolderElement.changes.subscribe(_ => {
      if (this.showNewFolder) {
        this.newFolderElement.first.nativeElement.focus();
      }
    });

    this.myDocumentsSelectionElement.changes.subscribe(_ => {
      if (this.documentService.hasSelection()) {
        const height = `${_.first.nativeElement.offsetHeight}px`;
        this.renderer.setStyle(this.myDocumentsContainer.nativeElement, 'padding-top', height);
      } else {
        this.renderer.removeStyle(this.myDocumentsContainer.nativeElement, 'padding-top');
      }
    });
  }

  private elementsSorting(): any {
    return (a: any, b: any) => {
      let x = a.title.toLowerCase();
      let y = b.title.toLowerCase();

      return x < y ? -1 : x > y ? 1 : 0;
    }
  }

  setObservers(): void {
    this._observers.add(this.getRouteParams());
  }

  getRouteParams(): Subscription {
    return this.route.paramMap.subscribe(paramMap => {
      this.loadElements(paramMap.get('id'));
    });
  }

  setUploader(): void {

    // Add documents
    this.uploader.onAfterAddingAll = files => {
      this.loadingService.showLoading();

      const sources = {
        ...files.map(
          (fileItem: any) => this.addDocument(fileItem.file.rawFile)
        )
      };

      forkJoin(sources).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        this.elements = this.elements.concat(Object.values(res));
        this.elements.sort(this.elementsSorting());
        this.folderService.updateFolderContent({
          status: 'addDocuments',
          documents: Object.values(res)
        });
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });
    }
  }

  loadFolderChildren(id: string): Observable<any> {
    const source$ = (id) ? this.folderService.getFolder(id) : this.userService.getRootFolder(this._me.getId());
    
    return source$.pipe(
      take(1),
      tap(res => {
        this.folder = res;
        this._folders = res.children;
      }),
      switchMap(res => this.loadDocuments(this.folder.getId()))
    );
  }

  loadDocuments(id: string): Observable<any> {    
    let page = 1;

    return this.folderService.getAllDocuments(id, page).pipe(
      expand(res => {
        this._documents = this._documents.concat(res.items);
        page = (res.totalItems > this._documents.length) ? (page + 1) : 0;
        return (page > 0) ? this.folderService.getAllDocuments(id ? id : this._me.getId(), page) : of(null);
      }),
      takeWhile(res => res !== null && page > 0)
    );
  }

  resetFolder(): void {
    this.folder = null;
    this.elements = [];
    this._documents = [];
    this._folders = [];
  }

  loadElements(folderId: string): void {
    this.resetFolder();
    
    const sources = {
      children: this.loadFolderChildren(folderId)
    };

    this.loadingService.showLoading();

    const loadElements = forkJoin(sources).pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe((res: any) => {
      this.elements = this._folders;
      this.elements = this.elements.concat(this._documents);
      this.elements.sort(this.elementsSorting());
    }, err => {
      if (folderId) {
        this.router.navigate(['/mes-documents']);
      }
    });

    this._observers.add(loadElements);
  }

  prepareSearchForm(): void {
    this.searchForm = this.formBuilder.group({
      search: ['', Validators.required]
    });
  }

  prepareCreateFolderForm(): void {
    this.createFolderForm = this.formBuilder.group({
      title: ['', Validators.required]
    });
  }

  cancelSearch(): void {
    this.searchInProgress = false;
    this.searchForm.reset();
    this._searchDocuments = [];
    this._searchFolders = [];
    this.searchElements = [];
  }

  searchFolders(params: {}): Observable<any> {
    let page = 1;
    this._searchFolders = [];
    return this.folderService.getAllFolders(page, params).pipe(
      expand(res => {
        this._searchFolders = this._searchFolders.concat(res.items);
        page = (res.totalItems > this._searchFolders.length) ? (page + 1) : 0;
        return (page > 0) ? this.folderService.getAllFolders(page, params) : of(null);
      }),
      takeWhile(res => res !== null && page > 0)
    );
  }

  searchDocuments(params: {}): Observable<any> {
    let page = 1;
    this._searchDocuments = [];
    return this.documentService.getAllDocuments(page, params).pipe(
      expand(res => {
        this._searchDocuments = this._searchDocuments.concat(res.items);
        page = (res.totalItems > this._searchDocuments.length) ? (page + 1) : 0;
        return (page > 0) ? this.documentService.getAllDocuments(page, params) : of(null);
      }),
      takeWhile(res => res !== null && page > 0)
    );
  }

  submitSearchForm(): void {
    if (this.searchForm.valid) {
      this.loadingService.showLoading();
      this.searchInProgress = true;
      this.searchElements = [];

      const params = {
        title: this.searchForm.value.search
      };

      const sources = {
        folders: this.searchFolders(params),
        documents: this.searchDocuments(params)
      }

      const search = forkJoin(sources).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe((res: any) => {
        this.searchElements = this._searchFolders;
        this.searchElements = this.searchElements.concat(this._searchDocuments);
        this.searchElements.sort(this.elementsSorting());
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(search);
    }
  }

  submitCreateFolderForm(): void {
    if (this.createFolderForm.valid) {
      this.loadingService.showLoading();

      let data = _.cloneDeep(this.createFolderForm.value);
      data.parent = this.folder.getIRI();

      const createFolder = this.folderService.createFolder(data).pipe(
        finalize(() => this.loadingService.dismissLoading())
      ).subscribe(res => {
        this.showNewFolder = false;
        this.elements.push(res);
        this.elements.sort(this.elementsSorting());
        this.createFolderForm.reset();
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(createFolder);
    }
  }

  cancelNewFolder(): void {
    this.showNewFolder = false;
    this.createFolderForm.reset();
  }

  addDocument(file: any): Observable<Document> {
    let input = new FormData();

    input.append('folder', this.folder.getIRI());
    input.append('file', file);

    return this.documentService.createDocument(input);
  }

  deleteElement(element: Folder | Document): void {
    const index = this.elements.findIndex(item => item.getId() == element.getId());
    this.elements.splice(index, 1);
  }

  moveHere(): void {
    let selection = this.documentService.getSelectionElements();

    let sources = Object.assign({}, selection.map(element => {
      if (element instanceof Folder) {
        return this.folderService.updateFolder(element.getId(), { parent: this.folder.getIRI() });
      } else if (element instanceof Document) {
        return this.documentService.updateDocument(element.getId(), { folder: this.folder.getIRI() });
      }
    }));

    this.loadingService.showLoading();

    const moveHere = forkJoin(sources).pipe(
      finalize(() => this.loadingService.dismissLoading())
    ).subscribe(res => {
      this.elements = this.elements.concat(Object.values(res));
      this.elements.sort(this.elementsSorting());
      this.documentService.emptySelection();
    }, err => {
      this.errorService.addError(err);
      this.errorService.show();
    });

    this._observers.add(moveHere);
  }

  shareDocuments(): void {
    this.modalService.show(SharingModalComponent);
  }

}
