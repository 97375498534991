<app-top-bar [title]="title" [rightAction]="topBarRightAction"
    (clickedRightAction)="navigateToProfileEdition()"></app-top-bar>
<div class="my-profile py-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h2>Identifiant</h2>
                <p class="mb-0 text-grey-35 font-italic font-weight-semi-bold">{{ me.username }}</p>
            </div>
        </div>
        <div class="row mt-6">
            <div class="col-12">
                <h2>Mes coordonnées personnelles</h2>
                <ul class="list-informations list-unstyled font-weight-medium">
                    <li>{{ me.getFullName() }}</li>
                    <li>
                        <ng-container *ngIf="me.gender == gender.MALE; then genderMale else genderFemale"></ng-container>                        
                        <ng-template #genderMale>
                            <i class="fas fa-mars fs-4"></i>
                        </ng-template>
                        <ng-template #genderFemale>
                            <i class="fas fa-venus fs-4"></i>
                        </ng-template>
                        {{ me.gender|i18nSelect:genderMapping }}
                    </li>
                    <li>
                        <i class="fas fa-birthday-cake"></i>
                        <span *ngIf="me.birthDate; else noBirthDate">
                            {{ me.birthDate|momentFormat:"DD MMMM YYYY" }}                             
                            <ng-container *ngIf="today|date|momentDiff:me.birthDate:'years' as age">
                                ({{ age }}
                                <ng-container [ngPlural]="age">
                                    <ng-template ngPluralCase="=0">an</ng-template>
                                    <ng-template ngPluralCase="=1">an</ng-template>
                                    <ng-template ngPluralCase="other">ans</ng-template>
                                </ng-container>)
                            </ng-container>
                        </span>
                        <ng-template #noBirthDate>
                            <span class="text-grey-65 font-italic">
                                Non communiquée
                            </span>
                        </ng-template>
                    </li>
                    <li>
                        <i class="fas fa-phone"></i>
                        {{ me.phone }}
                    </li>
                    <li>
                        <i class="far fa-envelope"></i>
                        <span *ngIf="me.email; else noEmail">{{ me.email }}</span>
                        <ng-template #noEmail>
                            <span class="text-grey-65 font-italic">
                                Non communiquée
                            </span>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row mt-6">
            <div class="col-12">
                <h2>Mes actions</h2>
                <ul class="list-informations list-unstyled">
                    <li>
                        <button type="button" (click)="retrievePersonalDataRequest()">
                            Je demande à récupérer mes données
                        </button>
                    </li>
                    <li>
                        <button type="button" (click)="deleteOwnAccountRequest()" class="text-danger font-weight-semi-bold">
                            Je demande à supprimer mon compte
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>