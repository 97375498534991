import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CancellationModalComponent } from 'src/app/appointments/cancellation-modal/cancellation-modal.component';
import { SharingModalComponent } from 'src/app/appointments/sharing-modal/sharing-modal.component';
import { AppointmentType } from 'src/app/core/enums/appointment-type.enum';
import { Gender } from 'src/app/core/enums/gender.enum';
import { Appointment } from 'src/app/core/models/appointment.model';

@Component({
  selector: 'app-appointment-thumbnail',
  templateUrl: './appointment-thumbnail.component.html',
  styleUrls: ['./appointment-thumbnail.component.scss']
})
export class AppointmentThumbnailComponent implements OnInit {

  @Input() public appointment: Appointment;
  @Input() public comingSoon: boolean = false;
  @Input() public config: any = {
    linkable: true,
    menu: true
  };
  public modalRef: BsModalRef;
  public gender = Gender;
  public appointmentObject = Appointment;
  public appointmentType = AppointmentType;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  openCancellationModal() {
    this.modalRef = this.modalService.show(CancellationModalComponent);
    this.modalRef.content.appointment = this.appointment;
  }

  openSharingModal() {
    this.modalRef = this.modalService.show(SharingModalComponent);
    this.modalRef.content.appointment = this.appointment;
    this.modalRef.content.setSharingAppointment();
  }

}
