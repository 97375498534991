import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutoUnsub } from 'src/app/core/decorators/auto-unsub.decorator';
import { Gender } from 'src/app/core/enums/gender.enum';
import { NotesSummary } from 'src/app/core/models/notes-summary.model';
import { WorkStation } from 'src/app/core/models/work-station.model';
import { ErrorService } from 'src/app/core/services/error.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { SharingService } from 'src/app/core/services/sharing.service';
import { WorkstationService } from 'src/app/core/services/workstation.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-sharing-modal',
  templateUrl: './sharing-modal.component.html',
  styleUrls: ['./sharing-modal.component.scss']
})
@AutoUnsub()
export class SharingModalComponent implements OnInit {

  private _observers: Subscription = new Subscription();
  private _workStationsPage: number = 1;
  private _formBuilder: FormBuilder = new FormBuilder();
  public notesSummary: NotesSummary;
  public gender = Gender;
  public workStations: WorkStation[] = [];  
  public workStationsLoading: boolean = false;
  public sharingForm: FormGroup;

  constructor(private loadingService: LoadingService, public bsModalRef: BsModalRef,
    private sharingService: SharingService, private workStationService: WorkstationService,
    private errorService: ErrorService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.prepareSharingForm();
    this.loadWorkStations();  
  }

  prepareSharingForm(): void {
    this.sharingForm = this._formBuilder.group({
      notesSummary: [null, [Validators.required]],
      workStations: [[], [Validators.minLength(1)]]
    })
  }

  setSharingNotesSummary(notesSummary: NotesSummary): void {
    this.notesSummary = notesSummary;
    this.sharingForm.get('notesSummary').patchValue(this.notesSummary);
  }

  loadWorkStations(): void {
    if (this._workStationsPage && !this.workStationsLoading) {
      this.workStationsLoading = true;

      const getWorkStations: Subscription = this.workStationService.getAllWorkStationsMet(this._workStationsPage).pipe(
        finalize(() => {
          this.workStationsLoading = false;
        })
      ).subscribe(res => {
        this.workStations = this.workStations.concat(res.items);
        this._workStationsPage = (res.totalItems > this.workStations.length) ? (this._workStationsPage + 1) : 0;
      });

      this._observers.add(getWorkStations);
    }
  }

  onSharingFormSubmit(): void {
    if (this.sharingForm.valid) {
      this.loadingService.showLoading();
      let data = _.cloneDeep(this.sharingForm.value);

      data.notesSummary = data.notesSummary.getIRI();
      data.workStations = data.workStations.map(item => item.getIRI());

      const shareNotesSummary = this.sharingService.createSharing(data).pipe(
        finalize(() => {
          this.loadingService.dismissLoading();
        })
      ).subscribe(res => {
        this.toastrService.success("Récap' des notes partagé.", "Félicitations !");
        this.bsModalRef.hide();        
      }, err => {
        this.errorService.addError(err);
        this.errorService.show();
      });

      this._observers.add(shareNotesSummary);
    }
  }

}
