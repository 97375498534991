import { Component, OnInit } from '@angular/core';

import { MISSION_LOCALE_SLUG } from '../constants/partner.constants';
import { Subscription } from 'rxjs';
import { PartnerService } from '../services/partner.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public showMissionLocalePartner: boolean = false;

  constructor(private partnerService: PartnerService) {}

  ngOnInit(): void {
    this.loadMissionLocalePartner();
  }

  loadMissionLocalePartner(): void {
    const getPartner: Subscription = this.partnerService
      .getPartner(MISSION_LOCALE_SLUG)
      .subscribe((res) => {
        this.showMissionLocalePartner = res.hasAvailabilities;
      });
  }

  getMissionLocaleQueryParams(): any {
    return {
      partner: MISSION_LOCALE_SLUG,
    };
  }
}
