import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class IRIUtil {

    getId(a: string): string {
        let fragments = a.split('/');
        
        return fragments.pop();
    }
}