import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserFormHandler } from '../../form-handlers/user.form-handler';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnInit {

  @Input() public userFormHandler: UserFormHandler;
  @Output() public submittedStepForm: EventEmitter<boolean> = new EventEmitter();
  @Output() public previousStep: EventEmitter<boolean> = new EventEmitter();
  public registrationStepForm: FormGroup;  
  public submittedRegistrationStepForm: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.registrationStepForm = this.userFormHandler.getFormGroup('step2');
  }

  submitRegistrationStepForm() {
    this.submittedRegistrationStepForm = true;
    this.submittedStepForm.emit(this.registrationStepForm.valid);
  }

}
