<div class="partner-thumbnail py-3 bg-white">
    <div class="container-fluid">
        <div class="row no-gutters">
            <div class="col-3">
                <ng-container *ngIf="configuration.showDescription; else partnerLogo">
                    <button type="button" (click)="openPartnerInformationsModal()">
                        <ng-container *ngTemplateOutlet="partnerLogo"></ng-container>
                    </button>
                </ng-container>
                <ng-template #partnerLogo>
                    <div class="p-2 bg-white rounded overflow-hidden border border-92">
                        <div class="img-wrapper" [style]="{ 'background-image': 'url(' + partner.getLogoPath() + ')' }">
                            <img src="assets/img/placeholders/square-thumbnail.png" class="img-fluid" alt="partner.name" />            
                        </div>
                    </div>
                </ng-template>                                
            </div>
            <div class="col-9 pl-3">
                <ng-container *ngIf="configuration.showDescription; else partnerTitle">
                    <button type="button" (click)="openPartnerInformationsModal()">
                        <ng-container *ngTemplateOutlet="partnerTitle"></ng-container>
                    </button>
                </ng-container>
                <ng-template #partnerTitle>
                    <h4 class="text-left">{{ partner.name }}</h4>
                </ng-template>                
                <p class="mb-0">{{ partner.shortDescription }} <button type="button" class="d-inline-block text-primary link fs-2" (click)="openPartnerInformationsModal()" *ngIf="configuration.showDescription">En savoir plus</button></p>
            </div>
        </div>
        <div class="row mt-3" *ngIf="(partner.hasAvailabilities && configuration.canMakingAppointment) || partner.phone || partner.website || partner.email">
            <div class="col-12">
                <button type="button" class="btn btn-primary w-100 text-normal" (click)="select()" *ngIf="partner.hasAvailabilities && configuration.canMakingAppointment; else additionalInformations">
                    Je prends rendez-vous avec {{ partner.name|uppercase }} 
                </button>
                <ng-template #additionalInformations>
                    <div class="bg-grey-90 p-3" *ngIf="partner.phone || partner.website || partner.email">
                        <ul class="list-unstyled list-inline font-italic additional-informations m-0 fs-2">
                            <li class="list-item mb-1" *ngIf="partner.phone">
                                <a href="tel:{{ partner.phone }}" class="link">
                                    <i class="fas fa-phone-alt"></i>{{ partner.phone }}
                                </a>
                            </li>
                            <li class="list-item mb-1" *ngIf="partner.email">
                                <a href="mailto:{{ partner.email }}" class="link">
                                    <i class="far fa-envelope"></i>{{ partner.email }}
                                </a>
                            </li>
                            <li class="list-item" *ngIf="partner.website">
                                <a href="{{ partner.getWebsiteUrl() }}" class="link" target="_blank">
                                    <i class="fas fa-desktop"></i>{{ partner.website }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </div>            
        </div>
    </div>
</div>